import apiClient from '../client/api-client';

export const addInterestItem = async (id, data) => {
    const url = `admin/interest-item/${id}/add`;
    return apiClient({
        url,
        data,
        method: 'POST',
    });
};

export const editInterestItem = async ({ id, data }) => {
    const url = `admin/interest-item/${id}/edit`;
    return apiClient({
        url,
        data,
        method: 'PATCH',
        headers: {
            'Content-Type': 'multipart/form-data',
            Accept: '*/*',
        },
    });
};

export const deleteInterestItem = async (id) => {
    const url = `admin/interest-item/${id}/delete`;
    return apiClient({
        url,
        method: 'DELETE',
    });
};

export const toggleInterestItem = async (id) => {
    const url = `admin/interest-item/${id}/status/update`;
    return apiClient({
        url,
        method: 'PATCH',
    });
};
