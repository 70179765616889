import loadable from '@loadable/component';
import pMinDelay from 'p-min-delay';
import React from 'react';

const TotalData = loadable(() => pMinDelay(import('./TotalData'), 500));

const DashboardCard = (props) => {
    const { title, count, chartData } = props;

    return (
        <div className='col-xl-4 col-sm-6'>
            <div className='card overflow-hidden'>
                <div
                    className='card-header border-0'
                    style={{ paddingBottom: 0 }}
                >
                    <div className='d-flex'>
                        <span className='mt-4'>{props?.icon}</span>
                        <div className='invoices'>
                            <h3 className='text-dark'>{title}</h3>
                            <h4>{count}</h4>
                        </div>
                    </div>
                </div>
                <div className='card-body p-0'>
                    <div id='totalInvoices'>
                        <TotalData chartData={chartData} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardCard;
