import React, { useState } from 'react';
import { Badge, Button, OverlayTrigger } from 'react-bootstrap';
import { DIALOGAE_COLUMN } from '../../../constants/datatable-column';
import moment from 'moment';
import Loader from '../../pages/Loader/Loader';
import { renderTooltip } from '../../../utils/helpers/common.helpers';
import Pagination from '../../pages/Datatable/Pagination.jsx';

const DialogaeDatatable = (props) => {
    const {
        categoryData,
        queryData,
        viewSpinList,
        pageSize,
        setQueryData,
        setShowSpinModal,
        mutateDelete,
        setShowEditSpinModal,
        setSpinData,
        isLoading,
    } = props;

    const [currentPage, setCurrentPage] = useState(
        queryData?.start / pageSize + 1
    );
    const totalPages = Math.ceil(viewSpinList?.recordsTotal / pageSize);
    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages && !isLoading) {
            setCurrentPage(newPage);
            setQueryData((prev) => ({
                ...prev,
                start: (newPage - 1) * pageSize,
            }));
        } else if (newPage === totalPages + 1) {
            // If the new page is the next page after the last, set currentPage to totalPages
            setCurrentPage(totalPages);
        }
    };

    const addModal = () => {
        setShowSpinModal(true);
    };

    const handleDelete = (id) => {
        mutateDelete(id);
    };

    const editData = (id, dDate, dStartTime, dEndTime) => {
        const formattedStartTime = moment(dStartTime, 'h:mm A').format('HH:mm');
        const formattedEndTime = moment(dEndTime, 'h:mm A').format('HH:mm');

        setSpinData({
            dDate: dDate,
            dStartTime: formattedStartTime,
            dEndTime: formattedEndTime,
            id: id,
        });
        setShowEditSpinModal(true);
    };

    return (
        <div className='col-12'>
            <div className='card'>
                <div className='card-header'>
                    <h4 className='card-title'>Dialogae Now Management</h4>
                    <div className='d-flex'>
                        <div className='mx-3'></div>
                        <Button variant='primary' onClick={addModal}>
                            Add Dialogae
                        </Button>
                    </div>
                </div>
                <div className='card-body'>
                    <div className='table-responsive'>
                        <div
                            id='example_wrapper'
                            className='dataTables_wrapper'
                        >
                            <table
                                id='example'
                                className='display w-100 dataTable'
                            >
                                <thead>
                                    <tr role='row'>
                                        {DIALOGAE_COLUMN.map((d, i) => (
                                            <th key={i}>{d}</th>
                                        ))}
                                    </tr>
                                </thead>
                                {isLoading ? (
                                    <div
                                        className='card-body d-flex align-items-center justify-content-center'
                                        style={{
                                            position: 'relative',
                                            top: '50%',
                                            left: '100%',
                                        }}
                                    >
                                        <Loader />
                                    </div>
                                ) : (
                                    <tbody>
                                        {categoryData?.map((item, i) => (
                                            <tr
                                                className='odd'
                                                role='row'
                                                key={i}
                                            >
                                                <td className='sorting_1'>
                                                    {moment(
                                                        item?.dStartTime
                                                    ).format('DD-MM-YYYY')}
                                                </td>
                                                <td>
                                                    {moment(
                                                        item?.dStartTime
                                                    ).format('HH.mm')}
                                                </td>
                                                <td>
                                                    {moment(
                                                        item?.dEndTime
                                                    ).format('HH.mm')}
                                                </td>
                                                <td>
                                                    {new Date(
                                                        item?.dStartTime
                                                    ) > new Date() ? (
                                                        <Badge
                                                            pill
                                                            bg='warning'
                                                        >
                                                            Upcoming
                                                        </Badge>
                                                    ) : new Date() >
                                                      new Date(
                                                          item?.dEndTime
                                                      ) ? (
                                                        <Badge
                                                            pill
                                                            bg='secondary'
                                                        >
                                                            Completed
                                                        </Badge>
                                                    ) : (
                                                        <Badge
                                                            pill
                                                            bg='success'
                                                        >
                                                            In Process
                                                        </Badge>
                                                    )}
                                                </td>
                                                <td>
                                                    <div className='d-flex justify-content-center gap-3 col-4'>
                                                        {new Date(
                                                            item?.dStartTime
                                                        ) > new Date() ? (
                                                            <button
                                                                className='rounded btn btn-light text-primary btn-xs'
                                                                onClick={() =>
                                                                    editData(
                                                                        item?._id,
                                                                        item?.dDate,
                                                                        moment(
                                                                            item?.dStartTime
                                                                        ).format(
                                                                            'LT'
                                                                        ),
                                                                        moment(
                                                                            item?.dEndTime
                                                                        ).format(
                                                                            'LT'
                                                                        )
                                                                    )
                                                                }
                                                            >
                                                                <i
                                                                    className='fa fa-edit'
                                                                    aria-hidden='true'
                                                                />
                                                            </button>
                                                        ) : null}
                                                        <OverlayTrigger
                                                            placement='bottom'
                                                            delay={{
                                                                show: 250,
                                                                hide: 400,
                                                            }}
                                                            overlay={renderTooltip(
                                                                'Delete'
                                                            )}
                                                        >
                                                            <button
                                                                className='rounded btn btn-light text-danger btn-xs'
                                                                onClick={() =>
                                                                    handleDelete(
                                                                        item?._id
                                                                    )
                                                                }
                                                            >
                                                                <i
                                                                    className='fa fa-trash'
                                                                    aria-hidden='true'
                                                                />
                                                            </button>
                                                        </OverlayTrigger>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                )}
                            </table>
                            <hr />

                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                handlePageChange={handlePageChange}
                                viewGiftCodeslist={viewSpinList}
                                queryData={queryData}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DialogaeDatatable;
