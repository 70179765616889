import React, { useEffect, useState } from 'react';
import { notify } from '../../../utils/helpers/notification.helpers';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
    residenceList,
    addResident,
    deleteResident,
    toggleResidentStatus,
    editResident,
} from '../../../api/adapters/residenceManagement';
import { getCountries } from '../../../api/adapters/cityManagement';
import { getCities } from '../../../api/adapters/universityManagement';
import { handleApiError } from '../../../utils/helpers/common.helpers';
import AddModal from '../../pages/AddModal/AddModal';
import EditModal from '../../pages/AddModal/EditModal';
import ResidenceDatatable from './ResidenceDatatable';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setConnectedUser } from '../../../actions/connected-user';

const Resident = () => {
    const [showLogin, setShowLogin] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [disable, setDisable] = useState(false);
    const [categoryName, setCategoryName] = useState({ sTitle: '', id: '' });
    const [addResidentName, setAddResidentName] = useState('');
    const [errorMessage, setErrorMessage] = useState({});
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');

    const [residenciesError, setResidenciesError] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const pageSize = 5;
    const [queryData, setQueryData] = useState({
        start: 0,
        length: pageSize,
        order: [{ dir: 'asc', column: 0 }],
        columns: [{ data: 'sTitle' }],
        search: '',
    });

    const {
        data: viewResidenceList,
        isLoading: isLoadingResidenceList,
        refetch,
        error: queryError,
    } = useQuery({
        queryKey: ['viewResidenceList', queryData],
        queryFn: () => residenceList(queryData).then((res) => res.data),
        retry: false,
    });

    useEffect(() => {
        setResidenciesError(queryError);
    }, [queryError]);

    useEffect(() => {
        if (residenciesError) {
            notify('error', residenciesError.message);

            if (residenciesError.status === 401) {
                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
        }
        setResidenciesError(null);
    }, [residenciesError]);

    const { data: viewCountryList } = useQuery({
        queryKey: ['viewCountryList'],
        queryFn: () => getCountries().then((res) => res.data),
    });

    const handleSelectChange = (e) => {
        let value = e.target.value;

        setSelectedCountry((preInquiryData) => ({
            ...preInquiryData,
            id: value,
        }));
        setDisable(false);
    };

    const handleSelectCity = (e) => {
        let value = e.target.value;

        setSelectedCity((preInquiryData) => ({
            ...preInquiryData,
            id: value,
        }));
    };

    const { data: cities, error: errorCity } = useQuery({
        queryKey: ['cities', selectedCountry?.id],
        queryFn: () => getCities(selectedCountry?.id).then((res) => res.data),
        enabled: !!selectedCountry.id,
        retry: false, // Disable retries on errors
        retryOnMount: false,
    });

    useEffect(() => {
        if (errorCity) {
            setDisable(true);
            notify('error', errorCity?.message || 'Something went wrong');
        }
    }, [errorCity]);

    const handleInput = (e) => {
        let value = e.target.value;

        setAddResidentName((preInquiryData) => ({
            ...preInquiryData,
            sTitle: value,
        }));
    };

    const handleSearch = (e) => {
        let value = e.target.value;

        setQueryData((prevData) => ({
            ...prevData,
            start: 0,
            search: value,
        }));
    };

    const { mutate: mutateAddResidence, isLoading: isMutating } = useMutation({
        mutationFn: (data) => addResident(data),
        onSuccess: (data) => {
            notify('success', data.message);
            refetch();
            setShowLogin(false);
            setSelectedCity('');
            setSelectedCountry('');
            setAddResidentName('');
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const { mutate: mutateDeleteResidence } = useMutation({
        mutationFn: (id) => deleteResident(id),
        onSuccess: (data) => {
            notify('success', data.message);
            refetch();
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const { mutate: mutateToggleButton } = useMutation({
        mutationFn: (id) => toggleResidentStatus(id),
        onSuccess: (data) => {
            notify('success', data?.message);
            refetch();
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const { mutate: mutateEditResidence, isLoading } = useMutation({
        mutationFn: (data) => editResident(data),
        onSuccess: (data) => {
            notify('success', data.message);

            refetch();
            setShowEditModal(false);
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const handleInputCategory = (e) => {
        let value = e.target.value;

        setCategoryName((prevData) => ({
            ...prevData,
            sTitle: value,
        }));
    };

    const handleClose = async (e) => {
        setDisable(false);
        setAddResidentName('');
        setSelectedCity('');
        setSelectedCountry('');
        setErrorMessage('');
        setShowLogin(false);
    };
    return (
        <div className='col-12'>
            <ResidenceDatatable
                categoryData={viewResidenceList?.data}
                handleInput={handleSearch}
                handleChange={handleInput}
                mutateDelete={mutateDeleteResidence}
                mutateToggle={mutateToggleButton}
                setQueryData={setQueryData}
                queryData={queryData}
                viewResidenceList={viewResidenceList}
                pageSize={pageSize}
                setShowEditModal={setShowEditModal}
                setCategoryName={setCategoryName}
                show={showLogin}
                close={() => setShowLogin(false)}
                setShowLogin={setShowLogin}
                isLoading={isLoadingResidenceList}
            />

            <EditModal
                showEdit={showEditModal}
                close={() => {
                    setShowEditModal(false);
                }}
                value={categoryName}
                title={'Edit Residence'}
                label='Enter Residence name'
                categoryName={categoryName}
                handleInputCategory={handleInputCategory}
                mutateEdit={mutateEditResidence}
                setErrorMessage={setErrorMessage}
                backendErrorMessage={errorMessage}
                isLoading={isLoading}
            />
            <AddModal
                title={'Add Residence'}
                label={'Residence Name'}
                mutate={mutateAddResidence}
                value={addResidentName}
                placeholder='Enter Residence'
                show={showLogin}
                handleChange={handleInput}
                countrylist={viewCountryList}
                handleSelectChange={handleSelectChange}
                selectedCountry={selectedCountry}
                cities={cities}
                handleSelectCity={handleSelectCity}
                selectedCity={selectedCity}
                disable={disable}
                setErrorMessage={setErrorMessage}
                backendErrorMessage={errorMessage}
                setDisable={setDisable}
                isMutating={isMutating}
                handleClose={handleClose}
            />
        </div>
    );
};

export default Resident;
