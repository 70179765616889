import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from '../jsx/components/Dashboard/Dashboard';
import UserManagement from '../jsx/components/User/UserManagement';
import University from '../jsx/components/University/University';
import Resident from '../jsx/components/Resident/Resident';
import Preference from '../jsx/components/Preference/Preference';
import PopUp from '../jsx/components/Pop-Up/PopUp';
import ChatKeyword from '../jsx/components/ChatKeyword/ChatKeyword';
import Country from '../jsx/components/Country/Country';
import Reward from '../jsx/components/Reward/Reward';
import Redemption from '../jsx/components/Reward/Redemption';
import Reports from '../jsx/components/Report/Reports';
import FAQs from '../jsx/components/FAQ/FAQs';
import Login from '../jsx/pages/Login/Login';
import ForgotPassword from '../jsx/pages/ForgotPassword/ForgotPassword';
import Profile from '../jsx/pages/Profile/Profile';
import ResetPassword from '../jsx/pages/Reset Password/ResetPassword';
import ViewPreference from '../jsx/components/Preference/ViewPreference';
import { getToken } from '../utils/helpers/cookies.helpers';
import City from '../jsx/components/City/City';
import UserDetails from '../jsx/components/User/UserDetails';
import EditUserDetails from '../jsx/components/User/EditUserDetails';
import NotFoundPage from '../jsx/pages/Error/Error404';
import Home from '../jsx/components/Dashboard/Home';
import ProfileEdit from '../jsx/pages/Profile/ProfileEdit';
import ChangePassword from '../jsx/pages/Change Password/ChangePassword';
import DialogaePage from '../jsx/components/Spinner/DialogaePage';
import AnimationText from '../jsx/components/Animation/AnimationText';
import Feedback from '../jsx/components/Feedback/Feedback';
import GiftCodeDatatable from '../jsx/components/Reward/GiftCodeDatatable';
import ReportedUser from '../jsx/components/Reported-User/ReportedUser';

const ProtectedComponentWrapper = ({ component }) => {
    return getToken() ? component : <Navigate to='/sign-in' replace />;
};

const AuthComponentWrapper = ({ component }) => {
    return getToken() ? <Navigate to='/' replace /> : component;
};

function DialogaeRoutes() {
    return (
        <Routes>
            {/* ****************************** */}
            {/* *** Open Routes - Non-Auth *** */}
            {/* ****************************** */}

            <Route
                path='/sign-in'
                element={<AuthComponentWrapper component={<Login />} />}
            />
            <Route
                path='/forgot-password'
                element={
                    <AuthComponentWrapper component={<ForgotPassword />} />
                }
            />
            <Route
                path='/reset-password/:token'
                element={<AuthComponentWrapper component={<ResetPassword />} />}
            />

            {/* ***************************************** */}
            {/* *** Restricted Routes - Auth Required *** */}
            {/* ***************************************** */}

            <Route
                path='/'
                element={
                    <ProtectedComponentWrapper
                        component={<Dashboard screen={<Home />} />}
                    />
                }
            />
            <Route
                path='/user-management/:status'
                element={
                    <ProtectedComponentWrapper
                        component={<Dashboard screen={<UserManagement />} />}
                    />
                }
            />

            <Route
                path='/user-management/:status/:id/view'
                element={
                    <ProtectedComponentWrapper
                        component={<Dashboard screen={<UserDetails />} />}
                    />
                }
            />
            <Route
                path='/user-management/:status/:id/edit'
                element={
                    <ProtectedComponentWrapper
                        component={<Dashboard screen={<EditUserDetails />} />}
                    />
                }
            />

            <Route
                path='/university'
                element={
                    <ProtectedComponentWrapper
                        component={<Dashboard screen={<University />} />}
                    />
                }
            />
            <Route
                path='/residence'
                element={
                    <ProtectedComponentWrapper
                        component={<Dashboard screen={<Resident />} />}
                    />
                }
            />
            <Route
                path='/preference/category'
                element={
                    <ProtectedComponentWrapper
                        component={<Dashboard screen={<Preference />} />}
                    />
                }
            />

            <Route
                path='/preference/category/:id/item'
                element={
                    <ProtectedComponentWrapper
                        component={<Dashboard screen={<ViewPreference />} />}
                    />
                }
            />

            <Route
                path='/video-call-booster'
                element={
                    <ProtectedComponentWrapper
                        component={<Dashboard screen={<PopUp />} />}
                    />
                }
            />
            <Route
                path='/chat-keyword'
                element={
                    <ProtectedComponentWrapper
                        component={<Dashboard screen={<ChatKeyword />} />}
                    />
                }
            />
            <Route
                path='/feedback'
                element={
                    <ProtectedComponentWrapper
                        component={<Dashboard screen={<Feedback />} />}
                    />
                }
            />
            <Route
                path='/country'
                element={
                    <ProtectedComponentWrapper
                        component={<Dashboard screen={<Country />} />}
                    />
                }
            />
            <Route
                path='/city'
                element={
                    <ProtectedComponentWrapper
                        component={<Dashboard screen={<City />} />}
                    />
                }
            />
            <Route
                path='/category/reward'
                element={
                    <ProtectedComponentWrapper
                        component={<Dashboard screen={<Reward />} />}
                    />
                }
            />
            <Route
                path='/category/reward/:id/gift-code'
                element={
                    <ProtectedComponentWrapper
                        component={<Dashboard screen={<GiftCodeDatatable />} />}
                    />
                }
            />
            <Route
                path='/reward/redemption'
                element={
                    <ProtectedComponentWrapper
                        component={<Dashboard screen={<Redemption />} />}
                    />
                }
            />

            <Route
                path='/dialogae'
                element={
                    <ProtectedComponentWrapper
                        component={<Dashboard screen={<DialogaePage />} />}
                    />
                }
            />
            <Route
                path='/report'
                element={
                    <ProtectedComponentWrapper
                        component={<Dashboard screen={<Reports />} />}
                    />
                }
            />
            <Route
                path='/faqs'
                element={
                    <ProtectedComponentWrapper
                        component={<Dashboard screen={<FAQs />} />}
                    />
                }
            />

            <Route
                path='/animation-text'
                element={
                    <ProtectedComponentWrapper
                        component={<Dashboard screen={<AnimationText />} />}
                    />
                }
            />

            <Route
                path='/reported-user'
                element={
                    <ProtectedComponentWrapper
                        component={<Dashboard screen={<ReportedUser />} />}
                    />
                }
            />

            <Route
                path='/admin-profile'
                element={
                    <ProtectedComponentWrapper
                        component={<Dashboard screen={<Profile />} />}
                    />
                }
            >
                <Route path='profile' element={<ProfileEdit />} />
                <Route path='change-password' element={<ChangePassword />} />
            </Route>

            {/* ************************************ */}
            {/* *** Public Routes - Error Routes *** */}
            {/* ************************************ */}
            <Route path='/404' element={<NotFoundPage />} />
            <Route path='*' element={<NotFoundPage />} />
        </Routes>
    );
}
export default DialogaeRoutes;

// export default RoutesFile;
