import React, { useState } from 'react';
import { Badge, Form, InputGroup } from 'react-bootstrap';
import Loader from '../../pages/Loader/Loader.js';
import { FEEDBACK_COLUMN } from '../../../constants/datatable-column.js';
import Pagination from '../../pages/Datatable/Pagination.jsx';
import moment from 'moment';

const FeedbackDatatable = (props) => {
    const {
        categoryData,
        handleSearch,
        setQueryData,
        queryData,
        viewFeedbackList,
        pageSize,
        isLoading,
        setShowFeedback,
        setFeedbackData,
    } = props;

    const [currentPage, setCurrentPage] = useState(
        queryData?.start / pageSize + 1
    );

    const totalPages = Math.ceil(viewFeedbackList?.recordsTotal / pageSize);

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages && !isLoading) {
            setCurrentPage(newPage);
            setQueryData((prev) => ({
                ...prev,
                start: (newPage - 1) * pageSize,
            }));
        } else if (newPage === totalPages + 1) {
            // If the new page is the next page after the last, set currentPage to totalPages
            setCurrentPage(totalPages);
        }
    };

    const handleClick = (
        id,
        email,
        name,
        message,
        remarks,
        status,
        number,
        date
    ) => {
        setFeedbackData({
            id: id,
            name: name,
            email: email,
            message: message,
            remarks: remarks,
            status: status,
            number: number,
            date: date,
        });
        setShowFeedback(true);
    };

    return (
        <div className='col-12'>
            <div className='card'>
                <div className='card-header'>
                    <h4 className='card-title'>Feedback Management</h4>
                    <div className='d-flex'>
                        <div className='mx-3'>
                            <InputGroup>
                                <>
                                    <InputGroup.Text>
                                        <i className='fa fa-search'></i>
                                    </InputGroup.Text>
                                    <Form.Control
                                        aria-label='With textarea'
                                        style={{
                                            position: 'relative',
                                            zIndex: 0,
                                        }}
                                        placeholder='Search by user name'
                                        value={queryData?.search}
                                        onChange={(e) => handleSearch(e)}
                                    />
                                </>
                            </InputGroup>
                        </div>
                    </div>
                </div>
                <div className='card-body'>
                    <div className='table-responsive'>
                        <div
                            id='example_wrapper'
                            className='dataTables_wrapper'
                        >
                            <table
                                id='example'
                                className='display w-100 dataTable'
                            >
                                <thead>
                                    <tr role='row'>
                                        {FEEDBACK_COLUMN.map((d, i) => (
                                            <th key={i}>{d}</th>
                                        ))}
                                    </tr>
                                </thead>
                                {isLoading ? (
                                    <div
                                        className='card-body d-flex align-items-center justify-content-center'
                                        style={{
                                            position: 'relative',
                                            top: '50%',
                                            left: '100%',
                                        }}
                                    >
                                        <Loader />
                                    </div>
                                ) : (
                                    <tbody>
                                        {categoryData?.map((item, i) => (
                                            <tr
                                                className='odd'
                                                role='row'
                                                key={i}
                                            >
                                                <td className='sorting_1'>
                                                    {`${
                                                        item?.iUser?.oName
                                                            ?.sFirstName || ''
                                                    } ${
                                                        item?.iUser?.oName
                                                            ?.sMiddleName || ''
                                                    } ${
                                                        item?.iUser?.oName
                                                            ?.sLastName || ''
                                                    }`}
                                                </td>
                                                <td>{item?.iUser?.sEmail}</td>
                                                <td>
                                                    {item?.sContactNumber ||
                                                        '-'}
                                                </td>
                                                <td>{item?.sFeedback}</td>
                                                <td>{item?.sRemarks || '-'}</td>
                                                <td>
                                                    {moment(
                                                        item?.dCreatedAt
                                                    ).format('DD MMM YYYY')}
                                                </td>
                                                <td>
                                                    {item?.eStatus === 1 ? (
                                                        <Badge
                                                            pill
                                                            bg='secondary'
                                                        >
                                                            Resolved
                                                        </Badge>
                                                    ) : (
                                                        <Badge pill bg='danger'>
                                                            Pending
                                                        </Badge>
                                                    )}
                                                </td>
                                                <td>
                                                    <button
                                                        className='btn btn-primary mx-2'
                                                        type='button'
                                                        onClick={() =>
                                                            handleClick(
                                                                item._id,
                                                                item?.iUser
                                                                    ?.sEmail,
                                                                item?.iUser
                                                                    ?.oName,
                                                                item?.sFeedback,
                                                                item?.sRemarks,
                                                                item?.eStatus,
                                                                item?.sContactNumber,
                                                                item?.dCreatedAt
                                                            )
                                                        }
                                                    >
                                                        Verify Status
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                )}
                            </table>
                            <hr />

                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                handlePageChange={handlePageChange}
                                viewGiftCodeslist={viewFeedbackList}
                                queryData={queryData}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeedbackDatatable;
