import apiClient from '../client/api-client';

export const rewardList = async (data) => {
    const url = 'admin/reward/list';
    return apiClient({
        url,
        data,
        method: 'POST',
    });
};

export const addReward = async (data) => {
    const url = 'admin/reward/add';
    return apiClient({
        url,
        data,
        method: 'POST',
    });
};

export const editReward = async (data) => {
    const url = `admin/reward/${data?.id}/edit`;
    return apiClient({
        url,
        data: data?.data,
        method: 'PATCH',
    });
};

export const deleteReward = async (id) => {
    const url = `admin/reward/${id}/delete`;
    return apiClient({
        url,
        method: 'DELETE',
    });
};

export const toggleReward = async (id) => {
    const url = `admin/reward/${id}/status/update`;
    return apiClient({
        url,
        method: 'PATCH',
    });
};

export const listGiftCode = async (id, data) => {
    const url = `admin/reward/${id}/gift-code/list`;
    return apiClient({
        url,
        data,
        method: 'POST',
    });
};

export const addGiftCode = async (id, data) => {
    const url = `admin/reward/${id}/gift-code/generate`;
    return apiClient({
        url,
        data,
        method: 'POST',
    });
};

export const deleteGiftCode = async (id) => {
    const url = `admin/reward/gift-code/${id}/delete`;
    return apiClient({
        url,
        method: 'DELETE',
    });
};

export const listRedemption = async (data) => {
    const url = 'admin/reward/gift-code/redemption/list';
    return apiClient({
        url,
        data,
        method: 'POST',
    });
};
