import apiClient from '../client/api-client';

export const universityList = async (data) => {
    const url = 'admin/university/list';
    return apiClient({
        url,
        data,
        method: 'POST',
    });
};

export const addUniversity = async (data) => {
    const url = 'admin/university/add';
    return apiClient({
        url,
        data,
        method: 'POST',
    });
};

export const deleteUniversity = async (id) => {
    const url = `admin/university/${id}/delete`;

    return apiClient({
        url,
        method: 'DELETE',
    });
};

export const toggleUniversityStatus = async (id) => {
    const url = `admin/university/${id}/status/update`;
    return apiClient({
        url,
        method: 'PATCH',
    });
};

export const editUniversity = async (data) => {
    const url = `admin/university/${data?.id}/edit`;
    return apiClient({
        url,
        data: { sTitle: data?.sTitle },
        method: 'PATCH',
    });
};

export const getCities = async (countryId) => {
    const url = `user/country/${countryId}/city/list`;

    return apiClient({
        url,
        method: 'GET',
    });
};
