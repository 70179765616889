import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
    getFeedbackList,
    updateFeedback,
} from '../../../api/adapters/feedback';
import { notify } from '../../../utils/helpers/notification.helpers';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setConnectedUser } from '../../../actions/connected-user';
import FeedbackDatatable from './FeedbackDatatable';
import FeedbackModal from './FeedbackModal';
import { handleApiError } from '../../../utils/helpers/common.helpers';

const Feedback = () => {
    const [showfeedback, setShowFeedback] = useState(false);
    const [feedbacksError, setFeedbacksError] = useState(null);
    const [errorMessage, setErrorMessage] = useState({});

    const [feedbackData, setFeedbackData] = useState({
        name: '',
        email: '',
        message: '',
        remarks: '',
        id: '',
        status: '',
        number: '',
        date: '',
    });

    const [selectedStatus, setSelectedStatus] = useState(
        feedbackData?.status || 0
    );

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const pageSize = 5;
    const [queryData, setQueryData] = useState({
        start: 0,
        length: pageSize,
        order: [{ dir: 'asc', column: 0 }],
        columns: [{ data: 'dCreatedAt' }],
        search: '',
    });
    const {
        data: viewFeedbackList,
        isLoading: isLoadingFeedbackList,
        refetch,
        error: queryError,
    } = useQuery({
        queryKey: ['view-feedback-list', queryData],
        queryFn: () => getFeedbackList(queryData).then((res) => res.data),
        retry: false,
    });

    useEffect(() => {
        setFeedbacksError(queryError);
    }, [queryError]);

    useEffect(() => {
        // Update the selectedStatus if feedbackData.status changes
        setSelectedStatus(feedbackData?.status || 0);
    }, [feedbackData?.status]);

    useEffect(() => {
        if (feedbacksError) {
            notify('error', feedbacksError.message);

            if (feedbacksError.status === 401) {
                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
        }
        setFeedbacksError(null);
    }, [feedbacksError]);

    const handleInput = (e) => {
        setFeedbackData((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }));
    };

    const handleSearch = (e) => {
        let value = e.target.value;

        setQueryData((prevData) => ({
            ...prevData,
            start: 0,
            search: value,
        }));
    };

    const handleSelectChange = (e) => {
        setSelectedStatus(parseInt(e.target.value));
    };

    const { mutate: mutateToggleStatusButton } = useMutation({
        mutationFn: (data) => updateFeedback(data),
        onSuccess: (data) => {
            notify('success', data?.message);
            refetch();
            setShowFeedback(false);
            setFeedbackData('');
            setErrorMessage('');
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const handleClose = () => {
        setShowFeedback(false);
    };
    return (
        <>
            <div className='col-12'>
                <FeedbackDatatable
                    categoryData={viewFeedbackList?.data}
                    setQueryData={setQueryData}
                    queryData={queryData}
                    viewFeedbackList={viewFeedbackList}
                    pageSize={pageSize}
                    isLoading={isLoadingFeedbackList}
                    handleSearch={handleSearch}
                    showfeedback={showfeedback}
                    setShowFeedback={setShowFeedback}
                    setFeedbackData={setFeedbackData}
                />
                <FeedbackModal
                    showfeedback={showfeedback}
                    setShowFeedback={setShowFeedback}
                    refetch={refetch}
                    feedbackData={feedbackData}
                    handleClose={handleClose}
                    handleInput={handleInput}
                    handleSelectChange={handleSelectChange}
                    selectedStatus={selectedStatus}
                    errorMessage={errorMessage}
                    mutateToggleStatusButton={mutateToggleStatusButton}
                    setErrorMessage={setErrorMessage}
                    setFeedbackData={setFeedbackData}
                />
            </div>
        </>
    );
};

export default Feedback;
