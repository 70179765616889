import React, { useState } from 'react';
import { Button, Modal, Form, InputGroup } from 'react-bootstrap';
import logo from '../../../assets/images/logo-symbol.png';
import { notify } from '../../../utils/helpers/notification.helpers';

const EditModal = (props) => {
    const {
        itemData,
        mutateEditInterestItem,
        iconLabel,
        handleChange,
        handleInputCategory,
        categoryName,
        mutateEdit,
        label2,
        editFaq,
        handleEditFaq,
        handleEditAnswerFaq,
        setErrorMessage,
        backendErrorMessage,
        title,
        isLoading,
    } = props;

    let errorsObj = { editTitle: '', editCategoryItem: '', editImage: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [image, setImage] = useState(undefined);

    const allowedFileTypes = ['image/png'];

    function singleFieldValidation() {
        const fieldsToValidate = [
            {
                field: 'sTitle',
                label: 'categoryName',
                validation: (value) => {
                    if (!categoryName || categoryName?.sTitle?.trim() === '') {
                        return title === 'Edit Keyword'
                            ? 'Keyword Required'
                            : title === 'Edit Country'
                            ? 'Country Required'
                            : title === 'Edit City'
                            ? 'City Required'
                            : title === 'Edit Preference Category'
                            ? 'Category Required'
                            : title === 'Edit University'
                            ? 'University name required'
                            : title === 'Edit Residence'
                            ? 'Residence name required'
                            : title === 'Edit Pop Up'
                            ? 'Pop Up text required'
                            : 'Title is required';
                    }
                },
            },
        ];

        let hasError = false;
        fieldsToValidate.forEach(({ field, validation }) => {
            const errorValue =
                field === 'sUniversityName'
                    ? categoryName?.sTitle || categoryName
                    : '';

            const errorMessage = validation(errorValue);

            errorsObj[field] = errorMessage;

            if (errorMessage) {
                hasError = true;
            }
        });

        return hasError;
    }

    function faqValidation() {
        const fieldsToValidate = [
            {
                field: 'Question',
                label: 'question',
                validation: (value) =>
                    !editFaq?.Question?.trim() ? 'Question is required' : '',
            },
            {
                field: 'Answer',
                label: 'answer',
                validation: (value) =>
                    !editFaq?.Answer?.trim() ? 'Answer is required' : '',
            },
        ];

        let hasError = false;
        fieldsToValidate.forEach(({ field, validation }) => {
            const errorValue =
                field === 'Question'
                    ? editFaq?.Question || editFaq
                    : field === 'Answer'
                    ? editFaq?.Answer || editFaq
                    : '';

            const errorMessage = validation(errorValue);

            errorsObj[field] = errorMessage;

            if (errorMessage) hasError = true;
        });

        return hasError;
    }

    function interestValidation() {
        const fieldsToValidate = [
            // {
            //     field: 'image',
            //     label: 'image',
            //     validation: (image) => {
            //         if (!itemData?.sIconUrl) {
            //             return 'Image is required';
            //         }
            //     },
            // },
            {
                field: 'interestItem',
                label: 'interestItem',
                validation: (value) =>
                    !itemData || !itemData?.sTitle?.trim()
                        ? 'Interest item required'
                        : '',
            },
        ];

        let hasError = false;

        fieldsToValidate.forEach(({ field, validation }) => {
            const errorValue =
                field === 'image'
                    ? itemData?.sIconUrl || itemData
                    : field === 'interestItem'
                    ? itemData?.sTitle || itemData
                    : '';

            const errorMessage = validation(errorValue);

            errorsObj[field] = errorMessage;

            if (errorMessage) hasError = true;
        });

        return hasError;
    }

    const submitHandler = async (e) => {
        e.preventDefault();

        let isError =
            title === 'Edit Keyword' ||
            title === 'Edit Country' ||
            title === 'Edit Preference Category' ||
            title === 'Edit City' ||
            title === 'Edit Residence' ||
            title === 'Edit University' ||
            title === 'Edit Pop Up'
                ? singleFieldValidation()
                : title === 'Edit FAQ'
                ? faqValidation()
                : title === 'Edit Preference Item'
                ? interestValidation()
                : '';

        if (isError) {
            return setErrorMessage(errorsObj);
        } else {
            setErrorMessage(errorsObj);
        }

        const formData = new FormData();
        formData.append('uploadfile', image);
        formData.append('sTitle', itemData?.sTitle);

        !itemData
            ? mutateEdit(
                  props?.title === 'Edit FAQ'
                      ? props?.editFaq
                      : props.value || categoryName
              )
            : mutateEditInterestItem({ id: itemData?.id, data: formData });
    };

    const onImageUpload = async (e) => {
        const file = e.target.files[0];

        if (allowedFileTypes.includes(file.type)) {
            setImage(file);
        } else {
            notify('error', 'Invalid file type. Only PNG files are allowed.');
        }
    };

    const handleClose = async (e) => {
        setErrorMessage(errorsObj);

        props?.close();
    };
    return (
        <>
            <Modal className='fade' show={props.showEdit}>
                <Modal.Header>
                    <Modal.Title>{props.title}</Modal.Title>
                    <Button
                        variant=''
                        className='btn-close'
                        onClick={handleClose}
                    ></Button>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group
                            className='mb-3'
                            controlId='exampleForm.ControlInput1'
                        >
                            {itemData ? (
                                <>
                                    <Form.Label>
                                        <h4 style={{ fontWeight: 'bold' }}>
                                            {iconLabel}
                                        </h4>
                                    </Form.Label>

                                    <div
                                        className='profile-dp'
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {image ? (
                                            <img
                                                src={URL.createObjectURL(image)}
                                                alt=''
                                            />
                                        ) : (
                                            <img
                                                src={
                                                    itemData?.sIconUrl ||
                                                    itemData?.sPopUpImage ||
                                                    logo
                                                }
                                                alt=''
                                            />
                                        )}
                                    </div>
                                    <input
                                        className='upload-input'
                                        type='file'
                                        onChange={(e) => onImageUpload(e)}
                                        style={{
                                            height: '50%',
                                        }}
                                    />
                                </>
                            ) : null}
                        </Form.Group>
                        {backendErrorMessage?.image && (
                            <div className='text-danger fs-12'>
                                {backendErrorMessage?.image}
                            </div>
                        )}
                        <Form.Group
                            className='mb-3'
                            controlId='exampleForm.ControlInput1'
                        >
                            <Form.Label>
                                <h4 style={{ fontWeight: 'bold' }}>
                                    {props.label}
                                </h4>
                            </Form.Label>
                            <>
                                <Form.Control
                                    type='text'
                                    value={
                                        itemData
                                            ? props.itemData.sTitle
                                            : props?.title === 'Edit FAQ'
                                            ? editFaq.Question
                                            : categoryName?.sTitle
                                    }
                                    placeholder={props.placeholder}
                                    autoFocus
                                    onChange={
                                        itemData
                                            ? handleChange
                                            : props?.title === 'Edit FAQ'
                                            ? handleEditFaq
                                            : handleInputCategory
                                    }
                                    autoComplete='off'
                                />
                            </>
                            {backendErrorMessage?.sTitle && (
                                <div className='text-danger fs-12'>
                                    {backendErrorMessage?.sTitle}
                                </div>
                            )}
                            {backendErrorMessage?.Question && (
                                <div className='text-danger fs-12'>
                                    {backendErrorMessage?.Question}
                                </div>
                            )}
                            {backendErrorMessage?.interestItem && (
                                <div className='text-danger fs-12'>
                                    {backendErrorMessage?.interestItem}
                                </div>
                            )}
                            {errors.editCategoryItem && (
                                <div className='text-danger fs-12'>
                                    {errors.editCategoryItem}
                                </div>
                            )}
                            {label2 === 'Answer' ? (
                                <>
                                    <br />

                                    <Form.Label>
                                        <h4
                                            style={{
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {label2}
                                        </h4>
                                    </Form.Label>

                                    <InputGroup>
                                        <Form.Control
                                            as='textarea'
                                            aria-label='With textarea'
                                            value={editFaq.Answer}
                                            style={{
                                                width: '100%', // Adjust the width as needed
                                                minHeight: '100px', // Adjust the minimum height as needed
                                                resize: 'vertical', // Allow vertical resizing
                                            }}
                                            onChange={handleEditAnswerFaq}
                                            autoComplete='off'
                                        />
                                    </InputGroup>
                                    {backendErrorMessage?.Answer && (
                                        <div className='text-danger fs-12'>
                                            {backendErrorMessage?.Answer}
                                        </div>
                                    )}
                                </>
                            ) : null}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='danger light' onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        variant='primary'
                        onClick={submitHandler}
                        disabled={isLoading}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default EditModal;
