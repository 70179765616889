import React, { useState } from 'react';
import { Button, Form, InputGroup, OverlayTrigger } from 'react-bootstrap';
import Loader from '../../pages/Loader/Loader.js';
import { FAQ_COLUMN } from '../../../constants/datatable-column.js';
import { renderTooltip } from '../../../utils/helpers/common.helpers.js';
import Pagination from '../../pages/Datatable/Pagination.jsx';

const FaqDatatable = (props) => {
    const {
        categoryData,
        handleInput,
        mutateDelete,
        setQueryData,
        queryData,
        viewFaqList,
        pageSize,
        setShowEditModal,
        setShowLogin,
        isLoading,
        setEditFaq,
    } = props;

    const [currentPage, setCurrentPage] = useState(
        queryData?.start / pageSize + 1
    );

    const totalPages = Math.ceil(viewFaqList?.recordsTotal / pageSize);

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages && !isLoading) {
            setCurrentPage(newPage);
            setQueryData((prev) => ({
                ...prev,
                start: (newPage - 1) * pageSize,
            }));
        } else if (newPage === totalPages + 1) {
            // If the new page is the next page after the last, set currentPage to totalPages
            setCurrentPage(totalPages);
        }
    };

    const editCategories = async (id, Question, Answer) => {
        setShowEditModal(true);
        setEditFaq({ Question: Question, Answer: Answer, id: id });
    };
    const addModal = () => {
        setShowLogin(true);
    };
    const handleDelete = (id) => {
        mutateDelete(id);
    };

    return (
        <div className='col-12'>
            <div className='card'>
                <div className='card-header'>
                    <h4 className='card-title'>FAQ Management</h4>
                    <div className='d-flex'>
                        <div className='mx-3'>
                            <InputGroup>
                                <>
                                    <InputGroup.Text>
                                        <i className='fa fa-search'></i>
                                    </InputGroup.Text>
                                    <Form.Control
                                        aria-label='With textarea'
                                        style={{
                                            position: 'relative',
                                            zIndex: 0,
                                        }}
                                        placeholder='Search by question'
                                        value={queryData?.search}
                                        onChange={(e) => handleInput(e)}
                                    />
                                </>
                            </InputGroup>
                        </div>
                        <Button variant='primary' onClick={addModal}>
                            Add FAQ
                        </Button>
                    </div>
                </div>
                <div className='card-body'>
                    <div className='table-responsive'>
                        <div
                            id='example_wrapper'
                            className='dataTables_wrapper'
                        >
                            <table
                                id='example'
                                className='display w-100 dataTable '
                            >
                                <thead>
                                    <tr>
                                        {FAQ_COLUMN.map((d, i) => (
                                            <th key={i} scope='col'>
                                                {d}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                {isLoading ? (
                                    <div
                                        className='card-body d-flex align-items-center justify-content-center'
                                        style={{
                                            position: 'relative',
                                            top: '50%',
                                            left: '100%',
                                        }}
                                    >
                                        <Loader />
                                    </div>
                                ) : (
                                    <tbody>
                                        {categoryData?.map((item, i) => (
                                            <tr
                                                className='odd'
                                                role='row'
                                                key={item._id}
                                            >
                                                <td className='sorting_1 col-2'>
                                                    {item?.sQuestion}
                                                </td>
                                                <td>
                                                    <div
                                                        style={{
                                                            maxHeight: '100px',
                                                            maxWidth: '95%',
                                                            overflowY: 'auto',
                                                        }}
                                                    >
                                                        <td>{item?.sAnswer}</td>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className='d-flex justify-content-center gap-3 col-4'>
                                                        <OverlayTrigger
                                                            placement='bottom'
                                                            delay={{
                                                                show: 250,
                                                                hide: 400,
                                                            }}
                                                            overlay={renderTooltip(
                                                                'Edit'
                                                            )}
                                                        >
                                                            <button
                                                                className='rounded btn btn-light text-primary btn-xs'
                                                                onClick={() =>
                                                                    editCategories(
                                                                        item?._id,
                                                                        item?.sQuestion,
                                                                        item?.sAnswer
                                                                    )
                                                                }
                                                            >
                                                                <i
                                                                    className='fa fa-edit'
                                                                    aria-hidden='true'
                                                                />
                                                            </button>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                            placement='bottom'
                                                            delay={{
                                                                show: 250,
                                                                hide: 400,
                                                            }}
                                                            overlay={renderTooltip(
                                                                'Delete'
                                                            )}
                                                        >
                                                            <button
                                                                className='rounded btn btn-light text-danger btn-xs'
                                                                onClick={() =>
                                                                    handleDelete(
                                                                        item?._id
                                                                    )
                                                                }
                                                            >
                                                                <i
                                                                    className='fa fa-trash'
                                                                    aria-hidden='true'
                                                                />
                                                            </button>
                                                        </OverlayTrigger>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                )}
                            </table>
                            <hr />

                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                handlePageChange={handlePageChange}
                                viewGiftCodeslist={viewFaqList}
                                queryData={queryData}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FaqDatatable;
