import apiClient from '../client/api-client';

export const interestCategory = async (data) => {
    const url = 'admin/interest-category/list';
    return apiClient({
        url,
        data,
        method: 'POST',
    });
};

export const addInterestCategory = async (data) => {
    const url = 'admin/interest-category/add';
    return apiClient({
        url,
        data,
        method: 'POST',
    });
};

export const deleteInterestCategory = async (id) => {
    const url = `admin/interest-category/${id}/delete`;
    return apiClient({
        url,
        method: 'DELETE',
    });
};

export const editInterestCategory = async (data) => {
    // console.log(data?.id);
    const url = `admin/interest-category/${data?.id}/edit`;
    return apiClient({
        url,
        data,
        method: 'PATCH',
    });
};

export const viewInterestItems = async (id, data) => {
    const url = `admin/interest-item/${id}/view`;
    return apiClient({
        url,
        data,
        method: 'POST',
    });
};

export const toogleCategory = async (id) => {
    const url = `admin/interest-category/${id}/status/update`;
    return apiClient({
        url,
        method: 'PATCH',
    });
};
