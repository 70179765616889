import React from 'react';
import { Button, Modal, Form } from 'react-bootstrap';

const AnimationModal = (props) => {
    const {
        modalTitle,
        mutateAddAnimation,
        errorMessage,
        categoryName,
        handleChange,
        handleInputCategory,
        mutateEditAnimationText,
    } = props;

    const addRejection = async () => {
        modalTitle === 'Edit Animation Text'
            ? mutateEditAnimationText(categoryName)
            : mutateAddAnimation(props?.value);
    };

    return (
        <>
            <Modal className='fade' show={props?.show}>
                <Modal.Header>
                    <Modal.Title>{modalTitle}</Modal.Title>
                    <Button
                        onClick={props?.close}
                        variant=''
                        className='btn-close'
                    ></Button>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group
                            className='mb-3'
                            controlId='exampleForm.ControlTextarea1'
                        >
                            <Form.Control
                                value={
                                    modalTitle === 'Edit Animation Text'
                                        ? categoryName?.sTitle
                                        : null
                                }
                                placeholder='Enter Animation Text'
                                onChange={
                                    modalTitle === 'Edit Animation Text'
                                        ? handleInputCategory
                                        : handleChange
                                }
                                autoComplete='off'
                            />
                        </Form.Group>
                    </Form>
                    {errorMessage?.sTitle && (
                        <div className='text-danger fs-12'>
                            {errorMessage?.sTitle}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props?.close} variant='danger light'>
                        Close
                    </Button>
                    <Button variant='primary' onClick={addRejection}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AnimationModal;
