import apiClient from '../client/api-client';

export const popupList = async (data) => {
    const url = 'admin/popup/list';
    return apiClient({
        url,
        data,
        method: 'POST',
    });
};

export const addPopUp = async (data) => {
    const url = 'admin/popup/add';
    return apiClient({
        url,
        data: { sPopUpText: data?.sTitle, iItems: data?.id },
        method: 'POST',
    });
};

export const deletePopUp = async (id) => {
    const url = `admin/popup/${id}/delete`;

    return apiClient({
        url,
        method: 'DELETE',
    });
};

export const togglePopUpStatus = async (id) => {
    const url = `admin/popup/${id}/status/update`;
    return apiClient({
        url,
        method: 'PATCH',
    });
};

export const editPopUp = async (data) => {
    const url = `admin/popup/${data?.id}/edit`;
    return apiClient({
        url,
        data: { sPopUpText: data?.sTitle },
        method: 'PATCH',
    });
};

export const getItemList = async () => {
    const url = 'admin/interest-item/view';
    return apiClient({
        url,
        method: 'GET',
    });
};
