import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
    addKeyword,
    deleteKeyword,
    editKeyword,
    keywordList,
    toggleKeywordStatus,
} from '../../../api/adapters/chatKeyword';
import { notify } from '../../../utils/helpers/notification.helpers';
import { handleApiError } from '../../../utils/helpers/common.helpers';
import KeywordDatatable from './KeywordDatatable';
import EditModal from '../../pages/AddModal/EditModal';
import AddModal from '../../pages/AddModal/AddModal';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setConnectedUser } from '../../../actions/connected-user';

const ChatKeyword = () => {
    const [showLogin, setShowLogin] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [categoryName, setCategoryName] = useState({ sTitle: '', id: '' });
    const [errorMessage, setErrorMessage] = useState({});
    const [addChatKeyword, setAddChatKeyword] = useState('');

    const [keywordsError, setKeywordsError] = useState(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const pageSize = 5;
    const [queryData, setQueryData] = useState({
        start: 0,
        length: pageSize,
        order: [{ dir: 'asc', column: 0 }],
        columns: [{ data: 'sTitle' }],
        search: '',
    });
    const {
        data: viewKeywordList,
        isLoading: isLoadingKeywordList,
        refetch,
        error: queryError,
    } = useQuery({
        queryKey: ['viewKeywordList', queryData],
        queryFn: () => keywordList(queryData).then((res) => res.data),
        retry: false,
    });

    useEffect(() => {
        setKeywordsError(queryError);
    }, [queryError]);

    useEffect(() => {
        if (keywordsError) {
            notify('error', keywordsError.message);

            if (keywordsError.status === 401) {
                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
        }
        setKeywordsError(null);
    }, [keywordsError]);

    const handleInput = (e) => {
        let value = e.target.value;

        setAddChatKeyword((preInquiryData) => ({
            ...preInquiryData,
            sTitle: value,
        }));
    };

    const handleSearch = (e) => {
        let value = e.target.value;

        setQueryData((prevData) => ({
            ...prevData,
            start: 0,
            search: value,
        }));
    };

    const { mutate: mutateAddKeyword, isLoading: isMutating } = useMutation({
        mutationFn: (data) => addKeyword(data),
        onSuccess: (data) => {
            notify('success', data.message);
            setAddChatKeyword('');
            refetch();
            setShowLogin(false);
        },

        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const { mutate: mutateDeleteKeyword } = useMutation({
        mutationFn: (id) => deleteKeyword(id),
        onSuccess: (data) => {
            notify('success', data.message);
            refetch();
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const { mutate: mutateToggleButton } = useMutation({
        mutationFn: (id) => toggleKeywordStatus(id),
        onSuccess: (data) => {
            notify('success', data?.message);
            refetch();
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const { mutate: mutateEditKeyword, isLoading } = useMutation({
        mutationFn: (data) => editKeyword(data),
        onSuccess: (data) => {
            notify('success', data.message);

            refetch();
            setShowEditModal(false);
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const handleInputCategory = (e) => {
        let value = e.target.value;

        setCategoryName((prevData) => ({
            ...prevData,
            sTitle: value,
        }));
    };

    const handleClose = async (e) => {
        setErrorMessage('');
        setAddChatKeyword('');
        setShowLogin(false);
    };

    return (
        <>
            <div className='col-12'>
                <KeywordDatatable
                    categoryData={viewKeywordList?.data}
                    handleInput={handleSearch}
                    handleChange={handleInput}
                    mutateDelete={mutateDeleteKeyword}
                    mutateToggle={mutateToggleButton}
                    setQueryData={setQueryData}
                    queryData={queryData}
                    viewKeywordList={viewKeywordList}
                    pageSize={pageSize}
                    setShowEditModal={setShowEditModal}
                    setCategoryName={setCategoryName}
                    show={showLogin}
                    close={() => setShowLogin(false)}
                    setShowLogin={setShowLogin}
                    isLoading={isLoadingKeywordList}
                />
                <EditModal
                    showEdit={showEditModal}
                    close={() => {
                        setShowEditModal(false);
                    }}
                    value={categoryName}
                    title={'Edit Keyword'}
                    label='Enter keyword'
                    categoryName={categoryName}
                    handleInputCategory={handleInputCategory}
                    mutateEdit={mutateEditKeyword}
                    setErrorMessage={setErrorMessage}
                    backendErrorMessage={errorMessage}
                    isLoading={isLoading}
                />
                <AddModal
                    title={'Add Keyword'}
                    label={'Chat Keyword'}
                    mutate={mutateAddKeyword}
                    value={addChatKeyword}
                    placeholder='Enter chat keyword'
                    show={showLogin}
                    handleChange={handleInput}
                    setErrorMessage={setErrorMessage}
                    backendErrorMessage={errorMessage}
                    isMutating={isMutating}
                    handleClose={handleClose}
                />
            </div>
        </>
    );
};

export default ChatKeyword;
