import React, { useEffect, useState } from 'react';
import ReportedUserDatatable from './ReportedUserDatatable';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
    getReportedUserList,
    updateReportedUser,
} from '../../../api/adapters/reportedUser';
import { notify } from '../../../utils/helpers/notification.helpers';
import { setConnectedUser } from '../../../actions/connected-user';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ReportedUserModal from './ReportedUserModal';
import { handleApiError } from '../../../utils/helpers/common.helpers';

const ReportedUser = () => {
    const [showReportedUserModal, setShowReportedUserModal] = useState(false);
    const [reportedUserError, setReportedUserError] = useState(null);
    const [errorMessage, setErrorMessage] = useState({});

    const [reportedUserData, setReportedUserData] = useState({
        id: '',
        reportedUser: '',
        reportedBy: '',
        message: '',
        status: '',
        contactNumber: '',
        remarks: '',
        date: '',
    });

    const [selectedStatus, setSelectedStatus] = useState(
        reportedUserData?.status || 0
    );

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const pageSize = 5;
    const [queryData, setQueryData] = useState({
        start: 0,
        length: pageSize,
        order: [{ dir: 'desc', column: 0 }],
        columns: [{ data: 'dCreatedAt' }],
        search: '',
    });

    const {
        data: viewReportedUserList,
        isLoading: isLoadingReportedUserList,
        refetch,
        error: queryError,
    } = useQuery({
        queryKey: ['viewReportedUserList', queryData],
        queryFn: () => getReportedUserList(queryData).then((res) => res.data),
        retry: false,
    });

    useEffect(() => {
        setReportedUserError(queryError);
    }, [queryError]);

    useEffect(() => {
        // Update the selectedStatus if feedbackData.status changes
        setSelectedStatus(reportedUserData?.status || 0);
    }, [reportedUserData?.status]);

    useEffect(() => {
        if (reportedUserError) {
            notify('error', reportedUserError.message);

            if (reportedUserError.status === 401) {
                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
        }
        setReportedUserError(null);
    }, [reportedUserError]);

    const {
        mutate: mutateReportedUser,
        isLoading: isLoadingReportedUserUpdate,
    } = useMutation({
        mutationFn: (data) => updateReportedUser(data),
        onSuccess: (data) => {
            notify('success', data.message);
            refetch();
            setShowReportedUserModal(false);
            setErrorMessage('');
            setReportedUserData('');
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const handleInput = (e) => {
        setReportedUserData((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }));
    };

    const handleSearch = (e) => {
        let value = e.target.value;

        setQueryData((prevData) => ({
            ...prevData,
            start: 0,
            search: value,
        }));
    };

    const handleSelectChange = (e) => {
        setSelectedStatus(parseInt(e.target.value));
    };

    const handleClose = () => {
        setShowReportedUserModal(false);
    };

    return (
        <>
            <ReportedUserDatatable
                viewReportedUserList={viewReportedUserList}
                queryData={queryData}
                pageSize={pageSize}
                isLoadingReportedUserList={isLoadingReportedUserList}
                setQueryData={setQueryData}
                showReportedUser={setShowReportedUserModal}
                handleClose={handleClose}
                setReportedUserData={setReportedUserData}
                handleSearch={handleSearch}
            />
            <ReportedUserModal
                showReportedUser={showReportedUserModal}
                handleClose={handleClose}
                reportedUserData={reportedUserData}
                handleInput={handleInput}
                handleSelectChange={handleSelectChange}
                selectedStatus={selectedStatus}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                mutateReportedUser={mutateReportedUser}
                isLoading={isLoadingReportedUserUpdate}
            />
        </>
    );
};

export default ReportedUser;
