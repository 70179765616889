import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { listRedemption } from '../../../api/adapters/rewards';
import { Form, InputGroup } from 'react-bootstrap';
import Loader from '../../pages/Loader/Loader';
import { REWARD_REDEMPTION_COLUMN } from '../../../constants/datatable-column';
import { formatTime } from '../../../utils/helpers/common.helpers';
import moment from 'moment';
import Pagination from '../../pages/Datatable/Pagination';
import { notify } from '../../../utils/helpers/notification.helpers';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setConnectedUser } from '../../../actions/connected-user';

const Redemption = () => {
    const pageSize = 5;
    const [queryData, setQueryData] = useState({
        start: 0,
        length: pageSize,
        order: [{ dir: 'desc', column: 0 }],
        columns: [{ data: 'dRedeemedAt' }],
        search: '',
    });
    const [rewardsError, setRewardsError] = useState(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {
        data: listRewardRedemptionList,
        isLoading: isLoadingRewardRedemptionList,
        error: queryError,
    } = useQuery({
        queryKey: ['list-Reward-Redemption-List', queryData],
        queryFn: () => listRedemption(queryData).then((res) => res.data),
        retry: false,
    });

    useEffect(() => {
        setRewardsError(queryError);
    }, [queryError]);

    useEffect(() => {
        if (rewardsError) {
            notify('error', rewardsError.message);

            if (rewardsError.status === 401) {
                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
        }
        setRewardsError(null);
    }, [rewardsError]);

    const [currentPage, setCurrentPage] = useState(
        queryData?.start / pageSize + 1
    );

    const totalPages = Math.ceil(
        listRewardRedemptionList?.recordsTotal / pageSize
    );

    const handlePageChange = (newPage) => {
        if (
            newPage >= 1 &&
            newPage <= totalPages &&
            !isLoadingRewardRedemptionList
        ) {
            setCurrentPage(newPage);
            setQueryData((prev) => ({
                ...prev,
                start: (newPage - 1) * pageSize,
            }));
        } else if (newPage === totalPages + 1) {
            // If the new page is the next page after the last, set currentPage to totalPages
            setCurrentPage(totalPages);
        }
    };

    const handleSearch = (e) => {
        let value = e.target.value;

        setQueryData((prevData) => ({
            ...prevData,
            start: 0,
            search: value,
        }));
    };

    return (
        <>
            <div className='col-12'>
                <div className='card'>
                    <div className='card-header'>
                        <h4 className='card-title'>Reward History</h4>
                        <div className='d-flex'>
                            <div className='mx-3'>
                                <InputGroup>
                                    <>
                                        <InputGroup.Text>
                                            <i className='fa fa-search'></i>
                                        </InputGroup.Text>
                                        <Form.Control
                                            aria-label='With textarea'
                                            style={{
                                                position: 'relative',
                                                zIndex: 0,
                                            }}
                                            placeholder='Search by user name'
                                            value={queryData?.search}
                                            onChange={(e) => handleSearch(e)}
                                        />
                                    </>
                                </InputGroup>
                            </div>
                        </div>
                    </div>
                    <div className='card-body'>
                        <div className='table-responsive'>
                            <div
                                id='example_wrapper'
                                className='dataTables_wrapper'
                            >
                                <table
                                    id='example'
                                    className='display w-100 dataTable'
                                >
                                    <thead>
                                        <tr role='row'>
                                            {REWARD_REDEMPTION_COLUMN.map(
                                                (d, i) => (
                                                    <th key={i}>{d}</th>
                                                )
                                            )}
                                        </tr>
                                    </thead>
                                    {isLoadingRewardRedemptionList ? (
                                        <div
                                            className='card-body d-flex align-items-center justify-content-center'
                                            style={{
                                                position: 'relative',
                                                top: '50%',
                                                left: '100%',
                                            }}
                                        >
                                            <Loader />
                                        </div>
                                    ) : (
                                        <tbody>
                                            {listRewardRedemptionList?.data?.map(
                                                (item, i) => (
                                                    <tr
                                                        className='odd'
                                                        role='row'
                                                        key={i}
                                                    >
                                                        <td className='sorting_1'>
                                                            {`${
                                                                item?.iUser
                                                                    ?.oName
                                                                    ?.sFirstName ||
                                                                ''
                                                            } ${
                                                                item?.iUser
                                                                    ?.oName
                                                                    ?.sMiddleName ||
                                                                ''
                                                            } ${
                                                                item?.iUser
                                                                    ?.oName
                                                                    ?.sLastName ||
                                                                ''
                                                            }`}
                                                        </td>
                                                        <td>
                                                            {moment(
                                                                item?.dRedeemedAt
                                                            ).format(
                                                                'DD-MM-YYYY'
                                                            )}
                                                        </td>
                                                        <td>
                                                            {formatTime(
                                                                item?.iReward
                                                                    ?.nMinutes *
                                                                    60
                                                            )}
                                                        </td>
                                                        <td>
                                                            {item?.sDescription}
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    )}
                                </table>
                                <hr />

                                <Pagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    handlePageChange={handlePageChange}
                                    viewGiftCodeslist={listRewardRedemptionList}
                                    queryData={queryData}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Redemption;
