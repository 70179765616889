import React, { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader/Loader';
import { useMutation } from '@tanstack/react-query';
import { changePassword } from '../../../api/adapters/profile';
import { notify } from '../../../utils/helpers/notification.helpers';
import { clearCookies } from '../../../utils/helpers/cookies.helpers';
import { Button } from 'react-bootstrap';
import { isPassword } from '../../../utils/validators/common.validators';
import { useDispatch } from 'react-redux';
import { setConnectedUser } from '../../../actions/connected-user';

const ChangePassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [OldPassword, setsOldPassword] = useState('');
    const [NewPassword, setsNewPassword] = useState('');
    const [ConfirmPassword, setsConfirmPassword] = useState('');

    const [formErrors, setFormErrors] = useState({});

    async function changePass(type, e) {
        e.preventDefault();

        const errors = formErrors;

        if (!OldPassword.trim()) {
            errors.OldPassword = 'Enter Old Password';
        } else if (!isPassword(OldPassword.trim())) {
            errors.OldPassword = 'Invalid Password';
        } else {
            errors.OldPassword = '';
        }

        if (!NewPassword.trim()) {
            errors.NewPassword = 'Enter New Password';
        } else if (!isPassword(NewPassword.trim())) {
            errors.NewPassword = 'Invalid Password';
        } else {
            errors.NewPassword = '';
        }
        if (!ConfirmPassword.trim()) {
            errors.ConfirmPassword = 'Enter Confirm Password';
        } else if (!isPassword(ConfirmPassword.trim())) {
            errors.ConfirmPassword = 'Invalid Password';
        } else if (ConfirmPassword.trim() !== NewPassword.trim()) {
            errors.ConfirmPassword = 'Password does not match';
        } else {
            errors.ConfirmPassword = '';
        }

        setFormErrors({ ...errors });

        if (new Set(Object.values(errors)).size > 1) return;

        let data = {
            sOldPassword: OldPassword,
            sNewPassword: NewPassword,
            sConfirmPassword: ConfirmPassword,
        };

        mutateChangePassword(data);
    }

    const { mutate: mutateChangePassword, isLoading: isMutating } = useMutation(
        {
            mutationFn: (data) => changePassword(data),
            onSuccess: (data) => {
                notify('success', data?.message);
                clearCookies();
                navigate('/sign-in', { replace: true });
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            },
            onError: (error) => {
                if (error.status === 422) {
                    const errObj = formErrors;
                    error.message?.response?.data?.data?.errors?.forEach(
                        (err) => {
                            errObj[err.path] = err.msg;
                        }
                    );

                    setFormErrors({ ...errObj });
                } else if (error.status === 401) {
                    notify('error', error.message);
                    clearCookies();
                    navigate('/sign-in');
                    dispatch(
                        setConnectedUser({
                            sEmail: '',
                            sProfilePicUrl: '',
                            sUserName: '',
                        })
                    );
                } else {
                    notify('error', error.message || 'Something went wrong');
                }
            },
        }
    );

    return (
        <>
            <div className='col-12'>
                <div className='card'>
                    <div className='card-body'>
                        <div className='card-header d-flex justify-content-between'>
                            <label className='label'>
                                <h2>
                                    <span>Change Password</span>
                                </h2>
                            </label>
                            <div className='d-flex'>
                                <Button
                                    onClick={(e) => changePass('submit', e)}
                                    disabled={isMutating}
                                >
                                    {!isMutating ? 'Submit' : <Loader />}
                                </Button>
                            </div>
                        </div>
                        <div className='card-body '>
                            <Fragment>
                                <form>
                                    <div className='form-group'>
                                        <label className=''>
                                            <strong>Old Password</strong>
                                        </label>
                                        <input
                                            type='password'
                                            className='form-control'
                                            value={OldPassword}
                                            onChange={(e) =>
                                                setsOldPassword(e.target.value)
                                            }
                                            placeholder='Enter old password'
                                        />
                                        {formErrors.OldPassword && (
                                            <label
                                                style={{
                                                    color: 'red',
                                                }}
                                            >
                                                {formErrors.OldPassword}
                                            </label>
                                        )}
                                    </div>
                                    <br />
                                    <div className='form-group'>
                                        <label className=''>
                                            <strong>New Password</strong>
                                        </label>
                                        <input
                                            type='password'
                                            className='form-control'
                                            value={NewPassword}
                                            onChange={(e) =>
                                                setsNewPassword(e.target.value)
                                            }
                                            placeholder='Enter new password'
                                        />
                                        {formErrors.NewPassword && (
                                            <label
                                                style={{
                                                    color: 'red',
                                                }}
                                            >
                                                {formErrors.NewPassword}
                                            </label>
                                        )}
                                    </div>
                                    <br />
                                    <div className='form-group'>
                                        <label className=''>
                                            <strong>Confirm Password</strong>
                                        </label>
                                        <input
                                            type='password'
                                            className='form-control'
                                            value={ConfirmPassword}
                                            onChange={(e) =>
                                                setsConfirmPassword(
                                                    e.target.value
                                                )
                                            }
                                            placeholder='Enter confirm password'
                                        />
                                        {formErrors.ConfirmPassword && (
                                            <label
                                                style={{
                                                    color: 'red',
                                                }}
                                            >
                                                {formErrors.ConfirmPassword}
                                            </label>
                                        )}
                                    </div>
                                    <br />
                                </form>
                            </Fragment>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ChangePassword;
