import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeContext } from '../../../context/ThemeContext';
import NavHader from '../../pages/Nav/NavHeader';
import Sidebar from '../../pages/Sidebar/Sidebar';
import Headers from '../../pages/Nav/Headers';

const Dashboard = (props) => {
    const { menuToggle } = useContext(ThemeContext);
    const location = useLocation();

    let path = location.pathname;
    path = path.split('/');
    path = path[path.length - 1];

    let pagePath = path.split('-').includes('page');

    return (
        <div
            id={`${!pagePath ? 'main-wrapper' : ''}`}
            className={`${!pagePath ? 'show' : 'mh100vh'} ${
                menuToggle ? 'menu-toggle' : ''
            }`}
        >
            <NavHader />
            <Headers />
            <Sidebar />
            <div className={`${!pagePath ? 'content-body' : ''}`}>
                <div
                    className={`${!pagePath ? 'container-fluid' : ''}`}
                    style={{ minHeight: '85vh' }}
                >
                    {props?.screen}
                </div>
            </div>
        </div>
    );
};
export default Dashboard;
