import React, { Fragment, useContext, useState } from 'react';
/// React router dom
import { Link } from 'react-router-dom';
import { ThemeContext } from '../../../context/ThemeContext';
import logo from '../../../assets/images/logo-symbol.png';

const NavHader = () => {
    const [toggle, setToggle] = useState(false);
    const { openMenuToggle } = useContext(ThemeContext);

    return (
        <div className='nav-header'>
            <Link to='/' className='brand-logo'>
                <Fragment>
                    <img src={logo} alt='' width='50px' />

                    <div className='brand-title'>
                        <h2 className=''>
                            Dia
                            <span>logae</span>
                        </h2>
                    </div>
                </Fragment>
                {/* )} */}
            </Link>

            <div
                className='nav-control'
                onClick={() => {
                    setToggle(!toggle);
                    openMenuToggle(!toggle);
                }}
            >
                <div className={`hamburger ${toggle ? 'is-active' : ''}`}>
                    <span className='line'></span>
                    <span className='line'></span>
                    <span className='line'></span>
                </div>
            </div>
        </div>
    );
};

export default NavHader;
