import apiClient from '../client/api-client';

export const residenceList = async (data) => {
    const url = 'admin/residence/list';
    return apiClient({
        url,
        data,
        method: 'POST',
    });
};

export const addResident = async (data) => {
    const url = 'admin/residence/add';
    return apiClient({
        url,
        data,
        method: 'POST',
    });
};

export const deleteResident = async (id) => {
    const url = `admin/residence/${id}/delete`;

    return apiClient({
        url,
        method: 'DELETE',
    });
};

export const toggleResidentStatus = async (id) => {
    const url = `admin/residence/${id}/status/update`;
    return apiClient({
        url,
        method: 'PATCH',
    });
};

export const editResident = async (data) => {
    const url = `admin/residence/${data?.id}/edit`;
    return apiClient({
        url,
        data: { sTitle: data?.sTitle },
        method: 'PATCH',
    });
};
