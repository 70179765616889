import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
    addCountry,
    countryList,
    deleteCountry,
    editCountry,
    toggleCountryStatus,
} from '../../../api/adapters/countryManagement';
import { notify } from '../../../utils/helpers/notification.helpers';
import { handleApiError } from '../../../utils/helpers/common.helpers';
import CountryDatatable from './CountryDatatable';
import EditModal from '../../pages/AddModal/EditModal';
import AddModal from '../../pages/AddModal/AddModal';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setConnectedUser } from '../../../actions/connected-user';

const Country = () => {
    const [showLogin, setShowLogin] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [categoryName, setCategoryName] = useState({ sTitle: '', id: '' });
    const [addCountryName, setAddCountry] = useState('');
    const [errorMessage, setErrorMessage] = useState({});

    const [countriesError, setCountriesError] = useState(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const pageSize = 5;
    const [queryData, setQueryData] = useState({
        start: 0,
        length: pageSize,
        order: [{ dir: 'asc', column: 0 }],
        columns: [{ data: 'sTitle' }],
        search: '',
    });

    const {
        data: viewCountryList,
        isLoading: isLoadingCountryList,
        refetch,
        error: queryError,
    } = useQuery({
        queryKey: ['viewCountryList', queryData],
        queryFn: () => countryList(queryData).then((res) => res.data),
        retry: false,
    });

    useEffect(() => {
        setCountriesError(queryError);
    }, [queryError]);

    useEffect(() => {
        if (countriesError) {
            notify('error', countriesError.message);

            if (countriesError.status === 401) {
                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
        }
        setCountriesError(null);
    }, [countriesError]);

    const handleInput = (e) => {
        let value = e.target.value;

        setAddCountry((preInquiryData) => ({
            ...preInquiryData,
            sTitle: value,
        }));
    };

    const handleSearch = (e) => {
        let value = e.target.value;

        setQueryData((prevData) => ({
            ...prevData,
            start: 0,
            search: value,
        }));
    };

    const { mutate: mutateAddCountry, isLoading: isMutating } = useMutation({
        mutationFn: (data) => addCountry(data),
        onSuccess: (data) => {
            notify('success', data.message);
            setAddCountry('');
            refetch();
            setShowLogin(false);
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const { mutate: mutateDeleteCountry } = useMutation({
        mutationFn: (id) => deleteCountry(id),
        onSuccess: (data) => {
            notify('success', data.message);
            refetch();
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const { mutate: mutateToggleButton } = useMutation({
        mutationFn: (id) => toggleCountryStatus(id),
        onSuccess: (data) => {
            notify('success', data?.message);
            refetch();
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const { mutate: mutateEditCountry, isLoading } = useMutation({
        mutationFn: (data) => editCountry(data),
        onSuccess: (data) => {
            notify('success', data.message);

            refetch();
            setShowEditModal(false);
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const handleInputCategory = (e) => {
        let value = e.target.value;

        setCategoryName((prevData) => ({
            ...prevData,
            sTitle: value,
        }));
    };

    const handleClose = async (e) => {
        setErrorMessage('');
        setAddCountry('');
        setShowLogin(false);
    };
    return (
        <div className='col-12'>
            <CountryDatatable
                categoryData={viewCountryList?.data}
                handleInput={handleSearch}
                handleChange={handleInput}
                mutateDelete={mutateDeleteCountry}
                mutateToggle={mutateToggleButton}
                setQueryData={setQueryData}
                queryData={queryData}
                viewCountryList={viewCountryList}
                pageSize={pageSize}
                setShowEditModal={setShowEditModal}
                setCategoryName={setCategoryName}
                show={showLogin}
                close={() => setShowLogin(false)}
                setShowLogin={setShowLogin}
                isLoading={isLoadingCountryList}
            />

            <EditModal
                showEdit={showEditModal}
                close={() => {
                    setShowEditModal(false);
                }}
                value={categoryName}
                title={'Edit Country'}
                label='Enter Country name'
                categoryName={categoryName}
                handleInputCategory={handleInputCategory}
                mutateEdit={mutateEditCountry}
                setErrorMessage={setErrorMessage}
                backendErrorMessage={errorMessage}
                isLoading={isLoading}
            />
            <AddModal
                title={'Add Country'}
                label={'Country Name'}
                mutate={mutateAddCountry}
                value={addCountryName}
                placeholder='Enter Country'
                show={showLogin}
                handleChange={handleInput}
                countrylist={viewCountryList}
                setErrorMessage={setErrorMessage}
                backendErrorMessage={errorMessage}
                isMutating={isMutating}
                handleClose={handleClose}
            />
        </div>
    );
};

export default Country;
