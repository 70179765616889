import React, { useState } from 'react';
import Pagination from '../../pages/Datatable/Pagination';
import { Badge, Form, InputGroup } from 'react-bootstrap';
import Loader from '../../pages/Loader/Loader';
import { REPORTED_USER_COLUMN } from '../../../constants/datatable-column';
import moment from 'moment';

const ReportedUserDatatable = (props) => {
    const {
        viewReportedUserList,
        queryData,
        pageSize,
        isLoadingReportedUserList,
        setQueryData,
        setReportedUserData,
        showReportedUser,
        handleSearch,
    } = props;

    const [currentPage, setCurrentPage] = useState(
        queryData?.start / pageSize + 1
    );

    const totalPages = Math.ceil(viewReportedUserList?.recordsTotal / pageSize);

    const handlePageChange = (newPage) => {
        if (
            newPage >= 1 &&
            newPage <= totalPages &&
            !isLoadingReportedUserList
        ) {
            setCurrentPage(newPage);
            setQueryData((prev) => ({
                ...prev,
                start: (newPage - 1) * pageSize,
            }));
        } else if (newPage === totalPages + 1) {
            // If the new page is the next page after the last, set currentPage to totalPages
            setCurrentPage(totalPages);
        }
    };

    const handleClick = (
        id,
        reportedUser,
        reportedBy,
        message,
        contactNumber,
        status,
        remarks,
        date
    ) => {
        setReportedUserData({
            id: id,
            reportedUser: reportedUser,
            reportedBy: reportedBy,
            message: message,
            contactNumber: contactNumber,
            status: status,
            remarks: remarks,
            date: date,
        });
        showReportedUser(true);
    };

    return (
        <div className='col-12'>
            <div className='card'>
                <div className='card-header'>
                    <h4 className='card-title'>Reported User Management</h4>
                    <div className='d-flex'>
                        <div className='mx-3'>
                            <InputGroup>
                                <>
                                    <InputGroup.Text>
                                        <i className='fa fa-search'></i>
                                    </InputGroup.Text>
                                    <Form.Control
                                        aria-label='With textarea'
                                        style={{
                                            position: 'relative',
                                            zIndex: 0,
                                        }}
                                        placeholder='Search by reported user'
                                        value={queryData?.search}
                                        onChange={(e) => handleSearch(e)}
                                    />
                                </>
                            </InputGroup>
                        </div>
                    </div>
                </div>
                <div className='card-body'>
                    <div className='table-responsive'>
                        <div
                            id='example_wrapper'
                            className='dataTables_wrapper'
                        >
                            <table
                                id='example'
                                className='display w-100 dataTable'
                            >
                                <thead>
                                    <tr role='row'>
                                        {REPORTED_USER_COLUMN.map((d, i) => (
                                            <th key={i}>{d}</th>
                                        ))}
                                    </tr>
                                </thead>
                                {isLoadingReportedUserList ? (
                                    <div
                                        className='card-body d-flex align-items-center justify-content-center'
                                        style={{
                                            position: 'relative',
                                            top: '50%',
                                            left: '100%',
                                        }}
                                    >
                                        <Loader />
                                    </div>
                                ) : viewReportedUserList?.data.length ? (
                                    <tbody>
                                        {viewReportedUserList?.data?.map(
                                            (item, i) => (
                                                <tr
                                                    className='odd'
                                                    role='row'
                                                    key={i}
                                                >
                                                    <td className='sorting_1'>
                                                        {item?.iReportedUser
                                                            ?.oName
                                                            ? (item
                                                                  ?.iReportedUser
                                                                  ?.oName
                                                                  ?.sFirstName ||
                                                                  '') +
                                                              (item
                                                                  ?.iReportedUser
                                                                  ?.oName
                                                                  ?.sMiddleName
                                                                  ? ' ' +
                                                                    item
                                                                        .iReportedUser
                                                                        .oName
                                                                        .sMiddleName
                                                                  : '') +
                                                              (item
                                                                  ?.iReportedUser
                                                                  ?.oName
                                                                  ?.sLastName
                                                                  ? ' ' +
                                                                    item
                                                                        .iReportedUser
                                                                        .oName
                                                                        .sLastName
                                                                  : '')
                                                            : ''}
                                                    </td>
                                                    <td>
                                                        {item?.iReportedBy
                                                            ?.oName
                                                            ? (item?.iReportedBy
                                                                  ?.oName
                                                                  ?.sFirstName ||
                                                                  '') +
                                                              (item?.iReportedBy
                                                                  ?.oName
                                                                  ?.sMiddleName
                                                                  ? ' ' +
                                                                    item
                                                                        .iReportedBy
                                                                        .oName
                                                                        .sMiddleName
                                                                  : '') +
                                                              (item?.iReportedBy
                                                                  ?.oName
                                                                  ?.sLastName
                                                                  ? ' ' +
                                                                    item
                                                                        .iReportedBy
                                                                        .oName
                                                                        .sLastName
                                                                  : '')
                                                            : ''}
                                                    </td>
                                                    <td>{item?.sMessage}</td>
                                                    <td>
                                                        {item?.eStatus === 1 ? (
                                                            <Badge
                                                                pill
                                                                bg='secondary'
                                                            >
                                                                Resolved
                                                            </Badge>
                                                        ) : (
                                                            <Badge
                                                                pill
                                                                bg='danger'
                                                            >
                                                                Pending
                                                            </Badge>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {item?.sRemarks || '-'}
                                                    </td>
                                                    <td>
                                                        {moment(
                                                            item?.dCreatedAt
                                                        ).format('DD MMM YYYY')}
                                                    </td>
                                                    <td>
                                                        {item?.sContactNumber ||
                                                            '-'}
                                                    </td>
                                                    <td>
                                                        <button
                                                            className='btn btn-primary mx-2'
                                                            type='button'
                                                            onClick={() =>
                                                                handleClick(
                                                                    item._id,
                                                                    item?.iReportedUser,
                                                                    item?.iReportedBy,
                                                                    item?.sMessage,
                                                                    item?.sContactNumber,
                                                                    item?.eStatus,
                                                                    item?.sRemarks,
                                                                    item?.dCreatedAt
                                                                )
                                                            }
                                                        >
                                                            Verify Status
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                ) : (
                                    <div
                                        className='card-body d-flex align-items-center justify-content-center'
                                        style={{
                                            position: 'relative',
                                            top: '50%',
                                            left: '100%',
                                        }}
                                    >
                                        <h1>No Data Found</h1>
                                    </div>
                                )}
                            </table>
                            <hr />

                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                handlePageChange={handlePageChange}
                                viewGiftCodeslist={viewReportedUserList}
                                queryData={queryData}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReportedUserDatatable;
