import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import {
    addAnimationText,
    deleteAnimationText,
    editAnimationText,
    getAnimationTextList,
    toggleAnimationTextStatus,
} from '../../../api/adapters/animationText';
import AnimationTextDatatable from './AnimationTextDatatable';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { notify } from '../../../utils/helpers/notification.helpers';
import { setConnectedUser } from '../../../actions/connected-user';
import { handleApiError } from '../../../utils/helpers/common.helpers';
import AnimationModal from './AnimationModal';

const AnimationText = () => {
    const [showAnimationModal, setShowAnimationModal] = useState(false);
    const pageSize = 5;
    const [animationTextError, setAnimationTextError] = useState(null);
    const [animationText, setAnimationText] = useState('');
    const [categoryName, setCategoryName] = useState({ sTitle: '', id: '' });

    const [modalTitle, setModalTitle] = useState('');

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState({});

    const [queryData, setQueryData] = useState({
        start: 0,
        length: pageSize,
        order: [{ dir: 'asc', column: 0 }],
        columns: [{ data: 'sTitle' }],
        search: '',
    });

    const {
        data: viewAnimationTextList,
        isLoading: isLoadingAnimationTextList,
        refetch,
        error: queryError,
    } = useQuery({
        queryKey: ['view-animation-text-list', queryData],
        queryFn: () => getAnimationTextList(queryData).then((res) => res.data),
        retry: false,
    });

    useEffect(() => {
        setAnimationTextError(queryError);
    }, [queryError]);

    useEffect(() => {
        if (animationTextError) {
            notify('error', animationTextError.message);

            if (animationTextError.status === 401) {
                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
        }
        setAnimationTextError(null);
    }, [animationTextError]);

    const handleInput = (e) => {
        let value = e.target.value;

        setAnimationText((preInquiryData) => ({
            ...preInquiryData,
            sTitle: value,
        }));
    };

    const handleSearch = (e) => {
        let value = e.target.value;

        setQueryData((prevData) => ({
            ...prevData,
            start: 0,
            search: value,
        }));
    };

    const { mutate: mutateAddAnimation, isLoading: isMutating } = useMutation({
        mutationFn: (data) => addAnimationText(data),
        onSuccess: (data) => {
            notify('success', data.message);
            setAnimationText('');
            refetch();
            setShowAnimationModal(false);
            setErrorMessage('');
        },

        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const { mutate: mutateDeleteAnimationText } = useMutation({
        mutationFn: (id) => deleteAnimationText(id),
        onSuccess: (data) => {
            notify('success', data.message);
            refetch();
            setShowAnimationModal(false);
            setErrorMessage('');
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const { mutate: mutateToggleButton } = useMutation({
        mutationFn: (id) => toggleAnimationTextStatus(id),
        onSuccess: (data) => {
            notify('success', data?.message);
            refetch();
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const { mutate: mutateEditAnimationText, isLoading } = useMutation({
        mutationFn: (data) => editAnimationText(data),
        onSuccess: (data) => {
            notify('success', data.message);

            refetch();
            setShowAnimationModal(false);
            setErrorMessage('');
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const handleInputCategory = (e) => {
        let value = e.target.value;
        setCategoryName((prevData) => ({
            ...prevData,
            sTitle: value,
        }));
    };

    const handleClose = () => {
        setErrorMessage({});
        setAnimationText('');
        setShowAnimationModal(false);
    };

    return (
        <>
            <AnimationTextDatatable
                categoryData={viewAnimationTextList?.data}
                handleSearch={handleSearch}
                mutateDelete={mutateDeleteAnimationText}
                mutateToggle={mutateToggleButton}
                setQueryData={setQueryData}
                queryData={queryData}
                viewAnimationTextList={viewAnimationTextList}
                pageSize={pageSize}
                setModalTitle={setModalTitle}
                setCategoryName={setCategoryName}
                showAnimationModal={showAnimationModal}
                close={() => setShowAnimationModal(false)}
                setShowAnimationModal={setShowAnimationModal}
                isLoading={isLoadingAnimationTextList}
            />
            <AnimationModal
                show={showAnimationModal}
                close={handleClose}
                handleChange={handleInput}
                mutateAddAnimation={mutateAddAnimation}
                value={animationText}
                setShowAnimationModal={setShowAnimationModal}
                errorMessage={errorMessage}
                handleInputCategory={handleInputCategory}
                categoryName={categoryName}
                modalTitle={modalTitle}
                mutateEditAnimationText={mutateEditAnimationText}
            />
        </>
    );
};

export default AnimationText;
