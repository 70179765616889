import React, { useEffect, useState } from 'react';
import { Form, InputGroup, Nav, Tab } from 'react-bootstrap';
import { useMutation, useQuery } from '@tanstack/react-query';
import PageTitle from '../../layouts/PageTitle';
import {
    deleteUser,
    toggleUserStatus,
    userList,
    userStatus,
} from '../../../api/adapters/userManagement';
import Loader from '../../pages/Loader/Loader';
import { notify } from '../../../utils/helpers/notification.helpers';
import UserDatatable from './UserDatatable';
import RejectModal from '../../pages/AddModal/RejectModal';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setConnectedUser } from '../../../actions/connected-user';
import { handleApiError } from '../../../utils/helpers/common.helpers';

const UserManagement = () => {
    const params = useParams();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const pageParam = searchParams.get('page');
    const start = pageParam?.toString();
    const [errorMessage, setErrorMessage] = useState({});

    const navigate = useNavigate();

    const [activeKey, setActiveKey] = useState('Pending');

    const [usersError, setUsersError] = useState(null);

    const dispatch = useDispatch();

    const pageSize = 5;
    const [showRejectModal, setShowRejectModal] = useState(false);

    const [queryData, setQueryData] = useState({
        start: start ? (start - 1) * pageSize : 0,
        length: pageSize,
        order: [{ dir: 'asc', column: 0 }],
        columns: [{ data: 'eProfileStatus' }],
        search: '',
        eProfileStatus:
            params?.status === 'pending'
                ? 0
                : params?.status === 'accepted'
                ? 1
                : -1,
    });

    const [rejectModalTitle, setRejectModalTitle] = useState('');

    const [addRejectedReason, setAddRejectReason] = useState({
        value: -1,
        reason: '',
    });

    const [addDisabledReason, setAddDisabledReason] = useState({
        id: '',
        reason: '',
    });

    useEffect(() => {
        if (
            params?.status !== 'pending' &&
            params?.status !== 'accepted' &&
            params?.status !== 'rejected'
        )
            navigate('/404');
        setActiveKey(
            params?.status === 'pending'
                ? 'Pending'
                : params?.status === 'accepted'
                ? 'Accepted'
                : params?.status === 'rejected'
                ? 'Rejected'
                : ''
        );
    }, [params]);

    useEffect(() => {
        setQueryData((prevData) => ({
            ...prevData,
            start: start ? (start - 1) * pageSize : 0,
            eProfileStatus:
                activeKey === 'Pending' ? 0 : activeKey === 'Accepted' ? 1 : -1,
        }));
    }, [activeKey]);

    const handleNavItemClick = (eventKey) => {
        setActiveKey(eventKey);
    };

    const {
        data: viewUserList,
        isLoading,
        refetch,
        error: queryError,
    } = useQuery({
        queryKey: ['viewUserList', queryData],
        queryFn: () => userList(queryData).then((res) => res.data),
        retry: false,
    });

    useEffect(() => {
        setUsersError(queryError);
    }, [queryError]);

    useEffect(() => {
        if (usersError) {
            notify('error', usersError.message);

            if (usersError.status === 401) {
                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
        }
        setUsersError(null);
    }, [usersError]);

    const handleSearch = (e) => {
        let value = e.target.value;

        setQueryData((prevData) => ({
            ...prevData,
            start: 0,
            search: value,
        }));
    };

    const handleRejectedReason = (e) => {
        let value = e.target.value;

        setAddRejectReason((preInquiryData) => ({
            ...preInquiryData,
            reason: value,
        }));
    };

    const handleDisabledReason = (e) => {
        let value = e.target.value;

        setAddDisabledReason((preInquiryData) => ({
            ...preInquiryData,
            reason: value,
        }));
    };

    const { mutate: mutateUserStatus, isLoading: loadingUserStatus } =
        useMutation({
            mutationFn: (data) => userStatus(data),
            onSuccess: (data) => {
                notify('success', data.message);
                refetch();
                setShowRejectModal(false);
                setErrorMessage('');
            },
            onError: (error) => {
                if (error?.status === 401) {
                    notify('error', error.message);

                    navigate('/sign-in');
                    dispatch(
                        setConnectedUser({
                            sEmail: '',
                            sProfilePicUrl: '',
                            sUserName: '',
                        })
                    );
                }
                handleApiError(error, setErrorMessage);
            },
        });

    const { mutate: mutateDeleteUser } = useMutation({
        mutationFn: (id) => deleteUser(id),
        onSuccess: (data) => {
            notify('success', data.message);
            refetch();
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            } else {
                notify('error', error.message);
            }
        },
    });

    const { mutate: mutateToggleButton } = useMutation({
        mutationFn: (id) => toggleUserStatus(id),
        onSuccess: (data) => {
            notify('success', data?.message);
            refetch();
            setShowRejectModal(false);
            setErrorMessage('');
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    return (
        <>
            <PageTitle
                activeMenu={`${activeKey} Request`}
                motherMenu='User Management'
            />
            <div className='col-12'>
                <Tab.Container
                    defaultActiveKey='Pending'
                    activeKey={activeKey}
                    onSelect={handleNavItemClick}
                >
                    <div className='card'>
                        <div className='card-header d-block d-sm-flex border-0 flex-wrap transactions-tab'>
                            <div className='card-tabs mt-3 mt-sm-0 mb-3 '>
                                <Nav as='ul' className='nav nav-tabs'>
                                    <Nav.Item as='li' className='nav-item'>
                                        <Nav.Link
                                            eventKey='Pending'
                                            onClick={() => {
                                                navigate(
                                                    '/user-management/pending'
                                                );
                                            }}
                                        >
                                            Pending Request
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item as='li' className='nav-item'>
                                        <Nav.Link
                                            eventKey='Accepted'
                                            onClick={() => {
                                                navigate(
                                                    '/user-management/accepted'
                                                );
                                            }}
                                        >
                                            Accepted Request
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item as='li' className='nav-item'>
                                        <Nav.Link
                                            eventKey='Rejected'
                                            onClick={() => {
                                                navigate(
                                                    '/user-management/rejected'
                                                );
                                            }}
                                        >
                                            Rejected Request
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <div className='d-flex '>
                                <InputGroup>
                                    <InputGroup.Text>
                                        <i className='fa fa-search'></i>
                                    </InputGroup.Text>
                                    <Form.Control
                                        aria-label='With textarea'
                                        placeholder='Search by name'
                                        value={queryData?.search}
                                        onChange={handleSearch}
                                    />
                                </InputGroup>
                            </div>
                        </div>

                        <Tab.Content className='card-body p-0'>
                            <Tab.Pane className='tab-pane' eventKey={activeKey}>
                                {isLoading ? (
                                    <div className='col-12'>
                                        <div className='card'>
                                            <div
                                                className='card-body d-flex align-items-center justify-content-center'
                                                style={{
                                                    position: 'fixed',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform:
                                                        'translate(-50%, -50%)',
                                                }}
                                            >
                                                <Loader />
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <UserDatatable
                                            categoryData={viewUserList?.data}
                                            handleInput={handleSearch}
                                            mutateDelete={mutateDeleteUser}
                                            mutateToggle={mutateToggleButton}
                                            setQueryData={setQueryData}
                                            queryData={queryData}
                                            viewUserList={viewUserList}
                                            pageSize={pageSize}
                                            isLoading={isLoading}
                                            value={addRejectedReason}
                                            activeKey={activeKey}
                                            mutateUserStatus={mutateUserStatus}
                                            setShowRejectModal={
                                                setShowRejectModal
                                            }
                                            setAddRejectReason={
                                                setAddRejectReason
                                            }
                                            setAddDisabledReason={
                                                setAddDisabledReason
                                            }
                                            setRejectModalTitle={
                                                setRejectModalTitle
                                            }
                                            setErrorMessage={setErrorMessage}
                                            loadingUserStatus={
                                                loadingUserStatus
                                            }
                                        />

                                        <RejectModal
                                            showRejectModal={showRejectModal}
                                            close={() =>
                                                setShowRejectModal(false)
                                            }
                                            handleRejectedReason={
                                                handleRejectedReason
                                            }
                                            mutateUserStatus={mutateUserStatus}
                                            value={addRejectedReason}
                                            disabledReason={addDisabledReason}
                                            setShowRejectModal={
                                                setShowRejectModal
                                            }
                                            handleDisabledReason={
                                                handleDisabledReason
                                            }
                                            title={rejectModalTitle}
                                            mutateToggle={mutateToggleButton}
                                            errorMessage={errorMessage}
                                            setErrorMessage={setErrorMessage}
                                        />
                                    </>
                                )}
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                </Tab.Container>
            </div>
        </>
    );
};

export default UserManagement;
