import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const PageTitle = ({ motherMenu, activeMenu, pageContent }) => {
    const location = useLocation();
    const segments = location?.pathname.split('/');
    const basePath = `/${segments[1]}/${segments[2]}`;

    return (
        <div className='row page-titles mx-0'>
            <ol className='breadcrumb'>
                <li className='breadcrumb-item active'>{motherMenu}</li>
                <li className='breadcrumb-item  '>
                    <Link
                        to={
                            pageContent
                                ? `${basePath}`
                                : `${location?.pathname}`
                        }
                    >
                        {activeMenu}
                    </Link>
                </li>
                {pageContent && (
                    <li className='breadcrumb-item  '>
                        <Link to={`${location?.pathname}`}>{pageContent}</Link>
                    </li>
                )}
            </ol>
        </div>
    );
};

export default PageTitle;
