import Skeleton from 'react-loading-skeleton';

function Loader(props) {
    if (props?.skeleton) {
        return (
            <Skeleton
                count={1}
                height={30}
                width={50}
                style={{
                    backgroundColor: '#3E3116',
                }}
            />
        );
    } else {
        return (
            <>
                <div className='spinner-border  mx-0 my-0' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                </div>
            </>
        );
    }
}

export default Loader;
