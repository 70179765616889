import moment from 'moment';
import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

const FeedbackModal = (props) => {
    const {
        showfeedback,
        handleInput,
        feedbackData,
        isMutating,
        handleSelectChange,
        selectedStatus,
        handleClose,
        errorMessage,
        mutateToggleStatusButton,
        setErrorMessage,
    } = props;

    const handleSubmit = (e) => {
        e.preventDefault();
        const errors = { ...errorMessage };

        if (selectedStatus === 1 && !feedbackData.remarks) {
            errors.remarks = 'Remark is required';
        } else errors.remarks = true;

        setErrorMessage({ ...errors });
        mutateToggleStatusButton({
            sRemarks: selectedStatus === 1 ? feedbackData.remarks : '',
            id: feedbackData.id,
            eStatus: selectedStatus,
        });
    };

    return (
        <>
            <Modal className='fade' show={showfeedback}>
                <Modal.Header>
                    <Modal.Title>Verify Feedback</Modal.Title>
                    <Button
                        variant=''
                        className='btn-close'
                        onClick={handleClose}
                    ></Button>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group
                            className='mb-3'
                            controlId='exampleForm.ControlInput1'
                        >
                            <>
                                <div>
                                    <Form.Label>
                                        <h4
                                            style={{
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Name
                                        </h4>
                                    </Form.Label>

                                    <Form.Control
                                        type='text'
                                        value={`${
                                            feedbackData?.name?.sFirstName || ''
                                        } ${
                                            feedbackData?.name?.sMiddleName ||
                                            ''
                                        } ${
                                            feedbackData?.name?.sLastName || ''
                                        }`}
                                        disabled={true}
                                    />
                                </div>

                                <div>
                                    <br />
                                    <Form.Label>
                                        <h4
                                            style={{
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Email
                                        </h4>
                                    </Form.Label>

                                    <Form.Control
                                        type='text'
                                        value={feedbackData?.email}
                                        disabled={true}
                                    />
                                </div>
                                <div>
                                    <br />
                                    <Form.Label>
                                        <h4
                                            style={{
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Contact Number
                                        </h4>
                                    </Form.Label>

                                    <Form.Control
                                        type='text'
                                        value={feedbackData?.number || '-'}
                                        disabled={true}
                                    />
                                </div>

                                <div>
                                    <br />
                                    <Form.Label>
                                        <h4
                                            style={{
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Feedback Date
                                        </h4>
                                    </Form.Label>

                                    <Form.Control
                                        type='text'
                                        value={
                                            moment(feedbackData?.date).format(
                                                'DD-MM-YYYY'
                                            ) || '-'
                                        }
                                        disabled={true}
                                    />
                                </div>

                                <div>
                                    <br />
                                    <Form.Label>
                                        <h4
                                            style={{
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Feedback Message
                                        </h4>
                                    </Form.Label>

                                    <Form.Control
                                        as='textarea'
                                        aria-label='With textarea'
                                        style={{
                                            width: '100%', // Adjust the width as needed
                                            minHeight: '100px', // Adjust the minimum height as needed
                                            resize: 'vertical', // Allow vertical resizing
                                        }}
                                        value={feedbackData?.message}
                                        disabled={true}
                                    />
                                </div>

                                <div>
                                    <br />
                                    <Form.Label>
                                        <h4
                                            style={{
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Status
                                        </h4>
                                    </Form.Label>
                                    <Form.Select
                                        aria-label='Default select example'
                                        className='c-pointer'
                                        onChange={handleSelectChange}
                                        value={selectedStatus}
                                    >
                                        <option value={0}>Pending</option>
                                        <option value={1}>Resolved</option>
                                    </Form.Select>
                                    {selectedStatus === 1 && (
                                        <div>
                                            <br />
                                            <Form.Label>
                                                <h4
                                                    style={{
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    Remark
                                                </h4>
                                            </Form.Label>

                                            <Form.Control
                                                as='textarea'
                                                aria-label='With textarea'
                                                style={{
                                                    width: '100%', // Adjust the width as needed
                                                    minHeight: '100px', // Adjust the minimum height as needed
                                                    resize: 'vertical', // Allow vertical resizing
                                                }}
                                                value={feedbackData?.remarks}
                                                onChange={handleInput}
                                                name='remarks'
                                                autoComplete='off'
                                            />
                                            {errorMessage.sRemarks && (
                                                <div className='text-danger fs-12'>
                                                    {errorMessage.sRemarks}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='danger light' onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        variant='primary'
                        onClick={handleSubmit}
                        disabled={isMutating}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default FeedbackModal;
