import React, { useState } from 'react';
import { Form, Image, OverlayTrigger } from 'react-bootstrap';
import Loader from '../Loader/Loader';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { renderTooltip } from '../../../utils/helpers/common.helpers';
import Pagination from './Pagination';

const Datatable = (props) => {
    const {
        categoryData,
        pageSize,
        interestItems,
        setQueryData,
        editItems,
        deleteItems,
        queryData,
        isLoading,
    } = props;

    const [searchParams, setSearchParams] = useSearchParams();

    const [currentPage, setCurrentPage] = useState(
        queryData?.start / pageSize + 1
    );

    useEffect(() => {
        setSearchParams((prevParams) => {
            const updatedParams = new URLSearchParams(prevParams);
            updatedParams.set('page', currentPage.toString());
            return updatedParams;
        });
    }, []);

    // Calculate the total number of pages
    const totalPages = Math.ceil(interestItems?.recordsTotal / pageSize);

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages && !isLoading) {
            setCurrentPage(newPage);
            setQueryData((prev) => ({
                ...prev,
                start: (newPage - 1) * pageSize,
            }));
        } else if (newPage === totalPages + 1) {
            // If the new page is the next page after the last, set currentPage to totalPages
            setCurrentPage(totalPages);
        }
    };
    const handleToggle = (toggle) => {
        props?.mutateToggle(toggle);
    };

    return (
        <>
            <div className='col-12'>
                <div className='card'>
                    <div className='card-body'>
                        <div className='table-responsive'>
                            <div id='job_data' className='dataTables_wrapper'>
                                <table
                                    className='display w-100 dataTable '
                                    id='example5'
                                    role='grid'
                                    aria-describedby='example5_info'
                                >
                                    <thead>
                                        <tr role='row'>
                                            <th className='sorting'>
                                                {props.col2}
                                            </th>

                                            <th className='sorting '>
                                                {props.col3}
                                            </th>

                                            <th className='sorting'>
                                                {props.col4}
                                            </th>

                                            <th className='sorting text-center'>
                                                {props.col5}
                                            </th>
                                        </tr>
                                    </thead>
                                    {props.isLoading ? (
                                        <div
                                            className='container '
                                            style={{
                                                position: 'relative',
                                                top: '50%',
                                                left: '50%',
                                                width: '100%',
                                                height: 'inherit',
                                            }}
                                        >
                                            <Loader />
                                        </div>
                                    ) : (
                                        <tbody>
                                            {categoryData?.map((item, i) => (
                                                <tr
                                                    className='odd'
                                                    role='row'
                                                    key={item._id}
                                                >
                                                    <td>
                                                        {/* <img
                                                            src={item?.sIconUrl}
                                                            style={{
                                                                width: '50px',
                                                                height: '50px',
                                                                objectFit:
                                                                    'cover',
                                                                marginRight:
                                                                    '10px', // Add margin for spacing
                                                            }}
                                                            alt={
                                                                item?.sIconUrl
                                                                    ? ''
                                                                    : item
                                                                          ?.sTitle?.[0]
                                                            }
                                                        /> */}

                                                        {item?.sIconUrl ? (
                                                            <img
                                                                src={
                                                                    item?.sIconUrl
                                                                }
                                                                style={{
                                                                    width: '50px',
                                                                    height: '50px',
                                                                    objectFit:
                                                                        'cover',
                                                                    marginRight:
                                                                        '10px',
                                                                }}
                                                                alt=''
                                                            />
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    justifyContent:
                                                                        'center',
                                                                    alignItems:
                                                                        'center',
                                                                    width: '50px',
                                                                    height: '50px',
                                                                    marginRight:
                                                                        '10px',
                                                                    // backgroundColor:
                                                                    //     '#f5f5f5', // replace with your desired color
                                                                    // borderRadius:
                                                                    //     '50%', // makes the div circular
                                                                }}
                                                            >
                                                                {item?.sTitle?.match(
                                                                    /^(?:\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]|\uD83E[\uDD00-\uDDFF]|[\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF]|[\uFE0E-\uFE0F])+/
                                                                )?.[0] || ''}
                                                            </div>
                                                        )}
                                                    </td>
                                                    <td>{item?.sTitle}</td>

                                                    <td>
                                                        <Form.Check
                                                            type='switch'
                                                            id='toggle-switch'
                                                            label=''
                                                            checked={
                                                                item.bIsActive ===
                                                                true
                                                            }
                                                            onClick={() =>
                                                                handleToggle(
                                                                    item?._id
                                                                )
                                                            }
                                                        />
                                                    </td>

                                                    <td>
                                                        <div className='d-flex justify-content-center gap-3'>
                                                            <OverlayTrigger
                                                                placement='bottom'
                                                                delay={{
                                                                    show: 250,
                                                                    hide: 400,
                                                                }}
                                                                overlay={renderTooltip(
                                                                    'Edit'
                                                                )}
                                                            >
                                                                <button
                                                                    className='rounded btn btn-light text-primary btn-xs'
                                                                    onClick={() =>
                                                                        editItems(
                                                                            item?.sTitle,
                                                                            item?._id,
                                                                            item?.sIconUrl
                                                                        )
                                                                    }
                                                                >
                                                                    <i
                                                                        className='fa fa-edit'
                                                                        aria-hidden='true'
                                                                    />
                                                                </button>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger
                                                                placement='bottom'
                                                                delay={{
                                                                    show: 250,
                                                                    hide: 400,
                                                                }}
                                                                overlay={renderTooltip(
                                                                    'Delete'
                                                                )}
                                                            >
                                                                <button
                                                                    className='rounded btn btn-light text-danger btn-xs'
                                                                    onClick={() =>
                                                                        deleteItems(
                                                                            item?._id
                                                                        )
                                                                    }
                                                                >
                                                                    <i
                                                                        className='fa fa-trash'
                                                                        aria-hidden='true'
                                                                    />
                                                                </button>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    )}
                                </table>
                                <hr />

                                <Pagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    handlePageChange={handlePageChange}
                                    viewGiftCodeslist={interestItems}
                                    queryData={queryData}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Datatable;
