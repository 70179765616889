import React from 'react';
import { Button, Modal, Form } from 'react-bootstrap';

const RejectModal = (props) => {
    const {
        mutateUserStatus,
        value,
        handleDisabledReason,
        handleRejectedReason,
        disabledReason,
        mutateToggle,
        errorMessage,
    } = props;

    const addRejection = async () => {
        props?.title === 'Rejected Reason'
            ? mutateUserStatus(value)
            : mutateToggle(disabledReason);
    };
    return (
        <>
            <Modal className='fade' show={props?.showRejectModal}>
                <Modal.Header>
                    <Modal.Title>{props?.title}</Modal.Title>
                    <Button
                        onClick={props?.close}
                        variant=''
                        className='btn-close'
                    ></Button>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group
                            className='mb-3'
                            controlId='exampleForm.ControlTextarea1'
                        >
                            <Form.Control
                                as='textarea'
                                placeholder='Enter  Reason'
                                style={{
                                    width: '100%', // Adjust the width as needed
                                    minHeight: '100px', // Adjust the minimum height as needed
                                    resize: 'vertical', // Allow vertical resizing
                                }}
                                onChange={
                                    props?.title === 'Rejected Reason'
                                        ? handleRejectedReason
                                        : handleDisabledReason
                                }
                                autoComplete='off'
                            />
                        </Form.Group>
                        {errorMessage.sDisabledReason && (
                            <div className='text-danger fs-12'>
                                {errorMessage.sDisabledReason}
                            </div>
                        )}
                        {errorMessage.sRejectedReason && (
                            <div className='text-danger fs-12'>
                                {errorMessage.sRejectedReason}
                            </div>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props?.close} variant='danger light'>
                        Close
                    </Button>
                    <Button variant='primary' onClick={addRejection}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default RejectModal;
