import { SET_PROFILE_DETAILS } from '../constants/redux-events';

const oInitialState = {
    sEmail: '',
    sProfilePicUrl: '',
    sUserName: '',
};

const connectedUser = (state = oInitialState, action) => {
    switch (action.type) {
        case SET_PROFILE_DETAILS:
            return {
                ...state,
                ...action.oConnectedUser,
            };

        default:
            return state;
    }
};

export default connectedUser;
