import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { login } from '../../../api/adapters/auth';
import logo from '../../../assets/images/logo-symbol.png';
import logoWhite from '../../../assets/images/name-logo.png';
import { handleApiError } from '../../../utils/helpers/common.helpers';
import { setToken } from '../../../utils/helpers/cookies.helpers';
import { notify } from '../../../utils/helpers/notification.helpers';
import { isValidEmail } from '../../../utils/validators/common.validators';
import Loader from '../Loader/Loader';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [errorMessage, setErrorMessage] = useState({
        sEmail: '',
        sPassword: '',
    });

    const navigate = useNavigate();

    const errorsObj = {};

    function checkValidation() {
        const fieldsToValidate = [
            {
                field: 'sEmail',
                label: 'Email',
                validation: (value) =>
                    value?.trim() === ''
                        ? 'Email is required'
                        : !isValidEmail(value)
                        ? 'Please enter a valid email'
                        : '',
            },
            {
                field: 'sPassword',
                label: 'Password',
                validation: (value) =>
                    value?.trim() === '' ? 'Password is required' : '',
            },
        ];

        let hasError = false;

        fieldsToValidate.forEach(({ field, validation }) => {
            const value =
                field === 'sEmail'
                    ? email
                    : field === 'sPassword'
                    ? password
                    : '';

            const errorMessage = validation(value);

            errorsObj[field] = errorMessage;

            if (errorMessage !== '') hasError = true;
        });

        return hasError;
    }

    async function onLogin(e) {
        e.preventDefault();

        let isError = checkValidation();

        if (isError) {
            return setErrorMessage(errorsObj);
        } else {
            setErrorMessage(errorsObj);
        }

        let data = { sEmail: email, sPassword: password };
        mutateLogin(data);
    }

    const { mutate: mutateLogin, isLoading: isMutating } = useMutation({
        mutationFn: (data) => login(data),
        onSuccess: (data) => {
            notify('success', data.message);

            setToken(data?.data?.sToken);

            navigate('/', { replace: true });
        },
        onError: (error) => handleApiError(error, setErrorMessage),
    });

    return (
        <div className='login-main-page'>
            <div className='login-wrapper'>
                <div className='login-aside-left'>
                    <span className='login-logo'>
                        <img src={logo} alt='' width='100px' />
                        <img src={logoWhite} alt='' className='ms-3' />
                    </span>
                </div>
                <div className='login-aside-right'>
                    <div className='row m-0 justify-content-center h-100 align-items-center'>
                        <div className='p-5'>
                            <div className='authincation-content'>
                                <div className='row no-gutters'>
                                    <div className='col-xl-12'>
                                        <div className='auth-form-1'>
                                            <div className='mb-4'>
                                                <h3 className='dz-title mb-1'>
                                                    Sign in
                                                </h3>
                                            </div>

                                            <form onSubmit={onLogin}>
                                                <div className='form-group'>
                                                    <label>
                                                        <strong>Email</strong>
                                                    </label>
                                                    <input
                                                        type='email'
                                                        className='form-control'
                                                        value={email}
                                                        onChange={(e) =>
                                                            setEmail(
                                                                e.target.value
                                                            )
                                                        }
                                                        placeholder='Type Your Email Address'
                                                    />
                                                    {errorMessage.sEmail && (
                                                        <div className='text-danger'>
                                                            {
                                                                errorMessage.sEmail
                                                            }
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='form-group'>
                                                    <label>
                                                        <strong>
                                                            Password
                                                        </strong>
                                                    </label>
                                                    <input
                                                        type='password'
                                                        className='form-control'
                                                        value={password}
                                                        placeholder='Type Your Password'
                                                        onChange={(e) =>
                                                            setPassword(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    {errorMessage.sPassword && (
                                                        <div className='text-danger '>
                                                            {
                                                                errorMessage.sPassword
                                                            }
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='form-row d-flex justify-content-end mt-4 mb-2'>
                                                    <div>
                                                        <label>
                                                            <Link to='/forgot-password'>
                                                                Forgot Password?
                                                            </Link>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className='text-center'>
                                                    <button
                                                        type='submit'
                                                        className='btn btn-primary btn-block fs-18'
                                                        disabled={isMutating}
                                                    >
                                                        {isMutating ? (
                                                            <Loader />
                                                        ) : (
                                                            'Sign In'
                                                        )}
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
