import apiClient from '../client/api-client';

export const getAnimationTextList = async (data) => {
    const url = 'admin/animation/list';
    return apiClient({
        url,
        data,
        method: 'POST',
    });
};

export const addAnimationText = async (data) => {
    const url = 'admin/animation/add';
    return apiClient({
        url,
        data,
        method: 'POST',
    });
};

export const deleteAnimationText = async (id) => {
    const url = `admin/animation/${id}/delete`;

    return apiClient({
        url,
        method: 'DELETE',
    });
};

export const toggleAnimationTextStatus = async (id) => {
    const url = `admin/animation/${id}/status/update`;
    return apiClient({
        url,
        method: 'PATCH',
    });
};

export const editAnimationText = async (data) => {
    const url = `admin/animation/${data?.id}/edit`;
    return apiClient({
        url,
        data: { sTitle: data?.sTitle },
        method: 'PATCH',
    });
};
