import React, { useEffect, useState } from 'react';
import { Badge, Form, OverlayTrigger } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Loader from '../../pages/Loader/Loader.js';
import {
    APPROVE_USER_COLUMN,
    PENDING_USER_COLUMN,
    REJECT_USER_COLUMN,
} from '../../../constants/datatable-column.js';
import {
    formatTime,
    renderTooltip,
} from '../../../utils/helpers/common.helpers.js';
import Pagination from '../../pages/Datatable/Pagination.jsx';

const UserDatatable = (props) => {
    const {
        categoryData,
        mutateDelete,
        mutateToggle,
        setQueryData,
        queryData,
        viewUserList,
        pageSize,
        isLoading,
        activeKey,
        setAddRejectReason,
        mutateUserStatus,
        setShowRejectModal,
        setAddDisabledReason,
        setRejectModalTitle,
        setErrorMessage,
        loadingUserStatus,
    } = props;

    const [currentPage, setCurrentPage] = useState(
        queryData?.start / pageSize + 1
    );
    const [loadingItemId, setLoadingItemId] = useState(null);

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        setSearchParams((prevParams) => {
            const updatedParams = new URLSearchParams(prevParams);
            updatedParams.set('page', currentPage.toString());
            return updatedParams;
        });
    }, []);

    const totalPages = Math.ceil(viewUserList?.recordsTotal / pageSize);
    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages && !isLoading) {
            setCurrentPage(newPage);
            setQueryData((prev) => ({
                ...prev,
                start: (newPage - 1) * pageSize,
            }));
        } else if (newPage === totalPages + 1) {
            // If the new page is the next page after the last, set currentPage to totalPages
            setCurrentPage(totalPages);
        }
    };

    const navigate = useNavigate();

    const handleToggle = (id, status) => {
        if (status === true) {
            setShowRejectModal(true);
            setErrorMessage('');
            setAddDisabledReason((prevData) => ({
                ...prevData,
                id: id,
            }));
            setRejectModalTitle('Disable Reason');
        } else {
            mutateToggle({ id: id, sDisabledReason: '' });
        }
    };

    const handleDelete = (id) => {
        mutateDelete(id);
    };

    const handleEditUser = (id) => {
        navigate(`${id}/edit`);
    };
    const handleUserDetails = (id) => {
        navigate(`${id}/view`);
    };

    const handleRejectUserStatus = (id) => {
        setShowRejectModal(true);
        setErrorMessage('');
        setRejectModalTitle('Rejected Reason');
        setAddRejectReason((prevData) => ({
            ...prevData,
            id: id,
        }));
    };

    const handleAcceptUserStatus = (id) => {
        setLoadingItemId(id);
        let value = 1;
        mutateUserStatus({ value, id });
    };

    return (
        <div className='col-12'>
            <div className='card'>
                <div className='card-body'>
                    <div className='table-responsive'>
                        <div
                            id='example_wrapper'
                            className='dataTables_wrapper'
                        >
                            <table
                                id='example'
                                className='display w-100 dataTable text-center'
                            >
                                <thead>
                                    <tr role='row'>
                                        {activeKey === 'Pending'
                                            ? PENDING_USER_COLUMN.map(
                                                  (d, i) => <th key={i}>{d}</th>
                                              )
                                            : activeKey === 'Accepted'
                                            ? APPROVE_USER_COLUMN.map(
                                                  (d, i) => <th key={i}>{d}</th>
                                              )
                                            : REJECT_USER_COLUMN.map((d, i) => (
                                                  <th key={i}>{d}</th>
                                              ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {isLoading ? (
                                        <div
                                            className='card-body d-flex align-items-center justify-content-center'
                                            style={{
                                                position: 'relative',
                                                top: '50%',
                                                left: '100%',
                                            }}
                                        >
                                            <Loader />
                                        </div>
                                    ) : (
                                        categoryData?.map((item, i) => (
                                            <tr
                                                className='odd'
                                                role='row'
                                                key={i}
                                            >
                                                <td className='sorting_1'>
                                                    {item?.oName?.sFirstName}
                                                </td>
                                                <td>
                                                    {item?.oName?.sMiddleName ||
                                                        '-'}
                                                </td>
                                                <td>
                                                    {item?.oName?.sLastName ||
                                                        '-'}
                                                </td>
                                                <td>{item?.sEmail}</td>
                                                <td>
                                                    {item?.eAccountType === 'R'
                                                        ? 'Residence'
                                                        : 'Student'}
                                                </td>
                                                {activeKey === 'Accepted' && (
                                                    <td>
                                                        {formatTime(
                                                            item?.nTotalCallSeconds
                                                        )}
                                                    </td>
                                                )}
                                                {activeKey === 'Accepted' && (
                                                    <td>
                                                        {item?.averageRating
                                                            ? item?.averageRating?.toFixed(
                                                                  2
                                                              )
                                                            : '-'}
                                                    </td>
                                                )}
                                                {activeKey === 'Rejected' && (
                                                    <td>
                                                        {item?.sRejectedReason}
                                                    </td>
                                                )}

                                                {activeKey ===
                                                'Pending' ? null : activeKey ===
                                                  'Rejected' ? (
                                                    <td>
                                                        <Badge pill bg='danger'>
                                                            Rejected
                                                        </Badge>
                                                    </td>
                                                ) : (
                                                    <td>
                                                        <Form.Check
                                                            className='d-flex justify-content-center'
                                                            type='switch'
                                                            id='toggle-switch'
                                                            label=''
                                                            checked={
                                                                item.bIsActive ===
                                                                true
                                                            }
                                                            onClick={() =>
                                                                handleToggle(
                                                                    item?._id,
                                                                    item.bIsActive
                                                                )
                                                            }
                                                        />
                                                    </td>
                                                )}

                                                {activeKey === 'Accepted' ? (
                                                    <td>
                                                        {item?.sDisabledReason
                                                            ? item?.sDisabledReason
                                                            : '-'}
                                                    </td>
                                                ) : null}

                                                <td>
                                                    <div>
                                                        {loadingUserStatus &&
                                                        loadingItemId ===
                                                            item?._id ? (
                                                            <Loader />
                                                        ) : (
                                                            <>
                                                                {activeKey ===
                                                                'Pending' ? (
                                                                    <>
                                                                        <OverlayTrigger
                                                                            placement='bottom'
                                                                            delay={{
                                                                                show: 250,
                                                                                hide: 400,
                                                                            }}
                                                                            overlay={renderTooltip(
                                                                                'Reject'
                                                                            )}
                                                                        >
                                                                            <button
                                                                                className='rounded btn btn-light text-danger btn-xs'
                                                                                style={{
                                                                                    marginRight:
                                                                                        '5px',
                                                                                }}
                                                                                onClick={() =>
                                                                                    handleRejectUserStatus(
                                                                                        item?._id
                                                                                    )
                                                                                }
                                                                            >
                                                                                <i className='far fa-times-circle fa-lg'></i>
                                                                            </button>
                                                                        </OverlayTrigger>
                                                                        <OverlayTrigger
                                                                            placement='bottom'
                                                                            delay={{
                                                                                show: 250,
                                                                                hide: 400,
                                                                            }}
                                                                            overlay={renderTooltip(
                                                                                'Accept'
                                                                            )}
                                                                        >
                                                                            <button
                                                                                className='rounded btn btn-light text-primary btn-xs'
                                                                                style={{
                                                                                    marginRight:
                                                                                        '5px',
                                                                                }}
                                                                                onClick={() =>
                                                                                    handleAcceptUserStatus(
                                                                                        item?._id
                                                                                    )
                                                                                }
                                                                            >
                                                                                <i className='far fa-check-circle fa-lg'></i>
                                                                            </button>
                                                                        </OverlayTrigger>
                                                                    </>
                                                                ) : (
                                                                    ''
                                                                )}

                                                                {activeKey ===
                                                                'Rejected' ? (
                                                                    ''
                                                                ) : (
                                                                    <>
                                                                        <OverlayTrigger
                                                                            placement='bottom'
                                                                            delay={{
                                                                                show: 250,
                                                                                hide: 400,
                                                                            }}
                                                                            overlay={renderTooltip(
                                                                                'View'
                                                                            )}
                                                                        >
                                                                            <button
                                                                                className='rounded btn btn-light text-primary btn-xs'
                                                                                style={{
                                                                                    marginRight:
                                                                                        '5px',
                                                                                }}
                                                                                onClick={() =>
                                                                                    handleUserDetails(
                                                                                        item?._id
                                                                                    )
                                                                                }
                                                                            >
                                                                                <i
                                                                                    className='fa fa-eye'
                                                                                    aria-hidden='true'
                                                                                />
                                                                            </button>
                                                                        </OverlayTrigger>
                                                                        <OverlayTrigger
                                                                            placement='bottom'
                                                                            delay={{
                                                                                show: 250,
                                                                                hide: 400,
                                                                            }}
                                                                            overlay={renderTooltip(
                                                                                'Edit'
                                                                            )}
                                                                        >
                                                                            <button
                                                                                className='rounded btn btn-light text-primary btn-xs'
                                                                                style={{
                                                                                    marginRight:
                                                                                        '5px',
                                                                                }}
                                                                                onClick={() =>
                                                                                    handleEditUser(
                                                                                        item?._id
                                                                                    )
                                                                                }
                                                                            >
                                                                                <i
                                                                                    className='fa fa-edit'
                                                                                    aria-hidden='true'
                                                                                />
                                                                            </button>
                                                                        </OverlayTrigger>
                                                                    </>
                                                                )}
                                                                <OverlayTrigger
                                                                    placement='bottom'
                                                                    delay={{
                                                                        show: 250,
                                                                        hide: 400,
                                                                    }}
                                                                    overlay={renderTooltip(
                                                                        'Delete'
                                                                    )}
                                                                >
                                                                    <button
                                                                        className='rounded btn btn-light text-danger btn-xs'
                                                                        onClick={() =>
                                                                            handleDelete(
                                                                                item?._id
                                                                            )
                                                                        }
                                                                    >
                                                                        <i
                                                                            className='fa fa-trash'
                                                                            aria-hidden='true'
                                                                        />
                                                                    </button>
                                                                </OverlayTrigger>
                                                            </>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                                {/* )} */}
                            </table>
                            <hr />

                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                handlePageChange={handlePageChange}
                                viewGiftCodeslist={viewUserList}
                                queryData={queryData}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserDatatable;
