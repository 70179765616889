import apiClient from '../client/api-client';

export const faqs = async (data) => {
    const url = 'admin/faq/list';
    return apiClient({
        url,
        data,
        method: 'POST',
    });
};

export const addFAQ = async (data) => {
    const url = 'admin/faq/add';
    return apiClient({
        url,
        data: { sQuestion: data?.question, sAnswer: data?.answer },
        method: 'POST',
    });
};

export const deleteFAQ = async (id) => {
    const url = `admin/faq/${id}/delete`;
    return apiClient({
        url,
        method: 'DELETE',
    });
};

export const editFAQ = async (data) => {
    const url = `admin/faq/${data?.id}/edit`;
    return apiClient({
        url,
        data: { sQuestion: data?.Question, sAnswer: data?.Answer },
        method: 'PATCH',
    });
};
