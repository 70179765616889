import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loader from '../Loader/Loader';

import { useMutation, useQuery } from '@tanstack/react-query';
import {
    resetPassword,
    verifyResetPasswordToken,
} from '../../../api/adapters/auth';
import { isPassword } from '../../../utils/validators/common.validators';
import { notify } from '../../../utils/helpers/notification.helpers';
import logo from '../../../assets/images/logo-symbol.png';

import logoWhite from '../../../assets/images/name-logo.png';
const ResetPassword = () => {
    const navigate = useNavigate();

    const { token } = useParams();

    const [UserData, setUserData] = useState({
        Password: '',
        ConfirmPassword: '',
    });

    const [errorMessage, setErrorMessage] = useState({
        Password: '',
        ConfirmPassword: '',
    });

    const { isLoading: isVerifyingToken, error: tokenVerificationError } =
        useQuery({
            queryKey: ['token-verification', token],
            queryFn: () =>
                verifyResetPasswordToken(token).then((res) => res.data),
            retry: false,
        });

    const handleInput = (e) => {
        setUserData((preUserData) => ({
            ...preUserData,
            [e.target.name]: e.target.value,
        }));
    };

    let error = false;
    const errorObj = {};

    function checkValidation() {
        const fieldsToValidate = [
            {
                field: 'Password',
                validation: (value) =>
                    value.trim() === ''
                        ? 'Password is required'
                        : !isPassword(value)
                        ? 'Password must Contain 8-15 characters, 1 uppercase, 1 lowercase, 1 digit, 1 special character'
                        : '',
            },
            {
                field: 'ConfirmPassword',
                validation: (value) =>
                    value.trim() === ''
                        ? 'Confirm password is required'
                        : value !== UserData.Password
                        ? 'Password and confirm password should be same'
                        : '',
            },
        ];

        fieldsToValidate.forEach(({ field, validation }) => {
            const errorMessage = validation(UserData[field]);
            errorObj[field] = errorMessage;
            if (errorMessage !== '') {
                error = true;
            }
        });
        return error;
    }

    async function resetPass(e) {
        e.preventDefault();
        let isError = checkValidation();
        if (isError) {
            setErrorMessage(errorObj);
            return;
        } else {
            setErrorMessage(errorObj);

            let data = {
                sPassword: UserData.Password,
                sConfirmPassword: UserData.ConfirmPassword,
            };
            mutateResetPassword(data);
        }
    }

    const { mutate: mutateResetPassword, isLoading: isMutating } = useMutation({
        mutationFn: (data) => resetPassword(token, data),
        onSuccess: (data) => {
            notify('success', data.message);

            navigate('/sign-in', { replace: true });
        },
        onError: (error) => {
            if (error.status === 422) {
                setErrorMessage(
                    error.message?.response?.data?.data?.errors?.[0]?.msg
                );
            } else if (error.status === 417) {
                return notify('error', error.message);
            } else {
                notify('error', error.message || 'Something went wrong');
            }
        },
    });

    return (
        <div className='login-main-page'>
            <div className='login-wrapper'>
                <div className='login-aside-left'>
                    <span>
                        <img src={logo} alt='' width='100px' />
                        <img src={logoWhite} alt='' className='ms-3' />
                    </span>
                </div>
                <div className='row justify-content-center h-100 align-items-center'>
                    {tokenVerificationError ? (
                        <>
                            <form>
                                <h1 className='text-center'>Link expired</h1>

                                <p className='text-center'>
                                    This link has expired, please request again
                                    to reset your password.
                                </p>

                                <div className='text-center '>
                                    <Link
                                        className='text-primary'
                                        to='/forgot-password'
                                        replace
                                    >
                                        Reset Password
                                    </Link>
                                </div>
                            </form>
                        </>
                    ) : (
                        <div className='authincation-content'>
                            <div className='row no-gutters'>
                                <div className='col-xl-12'>
                                    <div className='auth-form'>
                                        <div className='mb-4'>
                                            <h3 className='dz-title mb-1'>
                                                Reset Password
                                            </h3>
                                        </div>

                                        <form>
                                            <div className='form-group'>
                                                <label className='mb-2 '>
                                                    <strong>Password</strong>
                                                </label>
                                                <input
                                                    type='password'
                                                    className='form-control'
                                                    placeholder='Enter password'
                                                    name='Password'
                                                    onChange={handleInput}
                                                />
                                                {errorMessage['Password'] && (
                                                    <label
                                                        style={{ color: 'red' }}
                                                    >
                                                        {
                                                            errorMessage[
                                                                'Password'
                                                            ]
                                                        }
                                                    </label>
                                                )}
                                            </div>
                                            <div className='form-group'>
                                                <label className='mb-2 '>
                                                    <strong>
                                                        Confirm Password
                                                    </strong>
                                                </label>
                                                <input
                                                    type='password'
                                                    className='form-control'
                                                    placeholder='Enter confirm password'
                                                    name='ConfirmPassword'
                                                    onChange={handleInput}
                                                />
                                                {errorMessage[
                                                    'ConfirmPassword'
                                                ] && (
                                                    <label
                                                        style={{ color: 'red' }}
                                                    >
                                                        {
                                                            errorMessage[
                                                                'ConfirmPassword'
                                                            ]
                                                        }
                                                    </label>
                                                )}
                                            </div>
                                            <div className='text-center mt-4'>
                                                <button
                                                    type='submit'
                                                    className='btn btn-primary btn-block'
                                                    onClick={resetPass}
                                                    disabled={isMutating}
                                                >
                                                    {isMutating ? (
                                                        <Loader />
                                                    ) : (
                                                        'Submit'
                                                    )}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
