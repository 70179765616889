import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import logo from '../../../assets/images/logo-symbol.png';
import { notify } from '../../../utils/helpers/notification.helpers';

const EditRewardModal = (props) => {
    const {
        itemData,
        mutateEdit,
        handleEditTitle,
        handleEditDescription,
        setErrorMessage,
        errorMessage,
        isLoading,
        setImage,
        image,
    } = props;

    const allowedPopUpFileTypes = ['image/png', 'image/jpeg'];

    let errorsObj = {};

    function rewardValidation() {
        const fieldsToValidate = [
            {
                field: 'nMinutes',
                label: 'nMinutes',
                validation: (value) =>
                    value === undefined ||
                    (typeof value === 'string' && !value.trim())
                        ? 'Reward minutes required'
                        : '',
            },
            {
                field: 'sDescription',
                label: 'description',
                validation: (value) =>
                    !value || !value?.trim()
                        ? 'Reward description required'
                        : '',
            },
        ];

        let hasError = false;

        fieldsToValidate.forEach(({ field, validation }) => {
            const errorValue =
                field === 'nMinutes'
                    ? itemData?.nMinutes
                    : field === 'sDescription'
                    ? itemData?.sDescription
                    : '';

            // const errorMessage = validation(errorValue);
            // errorsObj[field] = errorMessage;
            if (field === 'sTitle' && itemData?.uploadfile) {
                errorsObj[field] = '';
            } else {
                const errorMessage = validation(errorValue);
                errorsObj[field] = errorMessage;
                if (errorMessage) hasError = true;
            }
        });

        return hasError;
    }

    const submitHandler = async (e) => {
        e.preventDefault();

        const formData = new FormData();

        let isError = rewardValidation();

        if (isError) {
            return setErrorMessage(errorsObj);
        } else {
            setErrorMessage(errorsObj);
        }

        formData.append('uploadfile', image);
        formData.append('nMinutes', itemData?.nMinutes);
        formData.append('sDescription', itemData?.sDescription);

        mutateEdit({ id: itemData?.id, data: formData });
    };

    const onImageUpload = async (e) => {
        const file = e.target.files[0];

        if (allowedPopUpFileTypes.includes(file.type)) {
            setImage(file);
        } else {
            notify('error', 'Invalid file type. Only PNG files are allowed.');
        }
    };

    const handleClose = async (e) => {
        setErrorMessage(errorsObj);
        props?.close();
    };
    return (
        <>
            <Modal className='fade' show={props.show}>
                <Modal.Header>
                    <Modal.Title>Edit Reward</Modal.Title>
                    <Button
                        variant=''
                        className='btn-close'
                        onClick={handleClose}
                    ></Button>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group
                            className='mb-3'
                            controlId='exampleForm.ControlInput1'
                        >
                            <>
                                <div>
                                    <Form.Label>
                                        <h4
                                            style={{
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Edit Reward Image
                                        </h4>
                                    </Form.Label>

                                    <div
                                        className='profile-dp'
                                        style={{ textAlign: 'center' }}
                                    >
                                        {image &&
                                        image !== itemData?.sRewardPic ? (
                                            <img
                                                src={URL.createObjectURL(image)}
                                                alt=''
                                            />
                                        ) : (
                                            <div className='dp'>
                                                <img
                                                    src={
                                                        itemData?.sRewardPic ||
                                                        logo
                                                    }
                                                    alt=''
                                                />
                                            </div>
                                        )}
                                        <input
                                            className='upload-input '
                                            type='file'
                                            onChange={(e) => onImageUpload(e)}
                                            style={{
                                                height: '50%',
                                            }}
                                        />
                                    </div>
                                </div>

                                <br />
                                <div>
                                    <br />

                                    <>
                                        <Form.Label>
                                            <h4
                                                style={{
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Edit Reward Title
                                            </h4>
                                        </Form.Label>
                                        <Form.Control
                                            type='text'
                                            value={itemData?.nMinutes}
                                            placeholder={'Add Reward title'}
                                            onChange={handleEditTitle}
                                        />
                                        {errorMessage.nMinutes && (
                                            <div className='text-danger fs-12'>
                                                {errorMessage.nMinutes}
                                            </div>
                                        )}
                                        <br />
                                        <Form.Label>
                                            <h4
                                                style={{
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Edit Reward Description
                                            </h4>
                                        </Form.Label>
                                        <Form.Control
                                            type='text'
                                            value={itemData?.sDescription}
                                            placeholder={
                                                'Add Reward description'
                                            }
                                            onChange={handleEditDescription}
                                        />
                                        {errorMessage.sDescription && (
                                            <div className='text-danger fs-12'>
                                                {errorMessage.sDescription}
                                            </div>
                                        )}
                                    </>
                                </div>
                            </>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='danger light' onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        variant='primary'
                        onClick={submitHandler}
                        disabled={isLoading}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default EditRewardModal;
