import apiClient from '../client/api-client';

export const getReportedUserList = async (data) => {
    const url = 'admin/reported-user/list';
    return apiClient({
        url,
        data,
        method: 'POST',
    });
};

export const updateReportedUser = async (data) => {
    const url = `admin/reported-user/${data?.id}/status/update`;
    return apiClient({
        url,
        data,
        method: 'PATCH',
    });
};
