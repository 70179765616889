export const isValidEmail = function (sEmail) {
    const re = /^[\w.%+-]+@[\w.-]+\.[A-Za-z]{2,}$/i;
    return re.test(String(sEmail).toLowerCase());
};

export const isPassword = function (password) {
    const regeX =
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,15}$/;
    return regeX.test(password);
};

export const isValidName = function (name) {
    // const re = /^[A-Z \u00C0-\u017F]+$/i;
    const re = /^[A-Z\u00E0-\u00FC]+([-|–|\.| |']*[A-Z\u00E0-\u00FC]*)*$/i;
    return re.test(name);
};

export const isValidPhone = function (phone) {
    const re = /^[0-9]{10}$/;
    return re.test(phone);
};

export const isValidCity = function (city) {
    const re = /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/;
    return re.test(city);
};

export const isValidUniversity = function (university) {
    const re = /^[A-Za-z\u00E0-\u00FC]+([-–. ']?[A-Za-z\u00E0-\u00FC]*)*$/i;
    return re.test(university);
};
