import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

const AddGiftCodeModal = (props) => {
    const {
        show,
        mutate,
        errorMessage,
        handleInput,
        data,
        handleClose,
        addGiftCodeLoader,
    } = props;

    const handleSubmit = (e) => {
        e.preventDefault();
        mutate(data);
    };

    return (
        <>
            <Modal className='fade' show={show}>
                <Modal.Header>
                    <Modal.Title>Generate Gift Code</Modal.Title>
                    <button
                        type='button'
                        className='btn-close'
                        onClick={handleClose}
                    ></button>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <div>
                                <Form.Label>
                                    <h4
                                        style={{
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Number of Gift Codes
                                    </h4>
                                </Form.Label>
                                <div>
                                    <input
                                        type='number'
                                        name='num'
                                        className='form-control'
                                        onChange={handleInput}
                                        autoComplete='off'
                                    />
                                    {errorMessage.num && (
                                        <div className='text-danger fs-12'>
                                            {errorMessage.num}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <br />
                            <div>
                                <Form.Label>
                                    <h4
                                        style={{
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Length of Gift Code
                                    </h4>
                                </Form.Label>
                                <div>
                                    <input
                                        type='number'
                                        name='len'
                                        className='form-control'
                                        onChange={handleInput}
                                        autoComplete='off'
                                    />
                                    {errorMessage.len && (
                                        <div className='text-danger fs-12'>
                                            {errorMessage.len}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <br />
                            <div>
                                <Form.Label>
                                    <h4
                                        style={{
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Prefix of Gift Code
                                    </h4>
                                </Form.Label>
                                <div>
                                    <input
                                        type='text'
                                        name='sPrefix'
                                        className='form-control'
                                        onChange={handleInput}
                                        autoComplete='off'
                                    />
                                    {errorMessage.sPrefix && (
                                        <div className='text-danger fs-12'>
                                            {errorMessage.sPrefix}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <br />
                            <div>
                                <Form.Label>
                                    <h4
                                        style={{
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Expiry Date
                                    </h4>
                                </Form.Label>

                                <div>
                                    <input
                                        type='date'
                                        name='dExpiryDate'
                                        className='form-control'
                                        onChange={handleInput}
                                        autoComplete='off'
                                    />
                                </div>
                                {errorMessage.dExpiryDate && (
                                    <div className='text-danger fs-12'>
                                        {errorMessage.dExpiryDate}
                                    </div>
                                )}
                            </div>

                            <br />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='danger light' onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        variant='primary'
                        onClick={handleSubmit}
                        disabled={addGiftCodeLoader}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AddGiftCodeModal;
