import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { TimePicker } from 'antd';
import moment from 'moment';

const AddDialogaeModal = (props) => {
    const {
        mutate,
        isMutating,
        errorMessage,
        setErrorMessage,
        setShowDialogaeModal,
    } = props;

    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();
    const [startDate, setStartDate] = useState();

    const format = 'HH:mm';

    const handleInput = (e) => {
        const { value } = e.target;
        let formattedValue = moment(value).format('YYYY-MM-DD');

        setStartDate(formattedValue);
    };

    const addSubmit = async (e) => {
        e.preventDefault();
        const spinData = {
            dDate: startDate,
            dStartTime: startTime,
            dEndTime: endTime,
        };

        mutate(spinData);
    };

    const handClose = () => {
        setErrorMessage({});
        setStartDate();
        setStartTime();
        setEndTime();
        setShowDialogaeModal(false);
    };

    return (
        <Modal className='fade' show={props.show}>
            <Modal.Header>
                <Modal.Title>Add Dialogae</Modal.Title>
                <Button
                    variant=''
                    className='btn-close'
                    onClick={handClose}
                ></Button>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group
                        className='mb-3'
                        controlId='exampleForm.ControlInput1'
                    >
                        <>
                            <div>
                                <Form.Label>
                                    <h4
                                        style={{
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Start Date
                                    </h4>
                                </Form.Label>

                                <div>
                                    <input
                                        type='date'
                                        name='dDate'
                                        className='form-control'
                                        onChange={handleInput}
                                    />
                                </div>
                                {errorMessage.dDate && (
                                    <div className='text-danger fs-12'>
                                        {errorMessage.dDate}
                                    </div>
                                )}
                            </div>

                            <br />
                            <div>
                                <br />

                                <>
                                    <Form.Label>
                                        <h4
                                            style={{
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Start Time
                                        </h4>
                                    </Form.Label>

                                    <br />
                                    <TimePicker
                                        style={{ width: '100%' }}
                                        format={format}
                                        size='large'
                                        showNow={false}
                                        allowClear={false}
                                        onChange={(time) => {
                                            const timeString = moment(
                                                time?.$d
                                            ).format('HH:mm');

                                            setStartTime(timeString);
                                        }}
                                    />
                                    {errorMessage.dStartTime && (
                                        <div className='text-danger fs-12'>
                                            {errorMessage.dStartTime}
                                        </div>
                                    )}
                                    <br />
                                    <br />
                                    <Form.Label>
                                        <h4
                                            style={{
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            End Time
                                        </h4>
                                    </Form.Label>
                                    <br />
                                    <TimePicker
                                        style={{ width: '100%' }}
                                        format={format}
                                        size='large'
                                        showNow={false}
                                        onChange={(time) => {
                                            const timeString = moment(
                                                time?.$d
                                            ).format('HH:mm');

                                            setEndTime(timeString);
                                        }}
                                        allowClear={false}
                                    />
                                    {errorMessage.dEndTime && (
                                        <div className='text-danger fs-12'>
                                            {errorMessage.dEndTime}
                                        </div>
                                    )}
                                </>
                            </div>
                        </>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='danger light' onClick={handClose}>
                    Close
                </Button>
                <Button
                    variant='primary'
                    onClick={addSubmit}
                    disabled={isMutating}
                >
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddDialogaeModal;
