import React from 'react';

const Reports = () => {
    return (
        <>
            <div className='col-12'>
                <div className='card'>
                    <div className='card-header'></div>
                    <div className='card-body'>
                        <h2 className='text-center text-dark'>
                            <b>Static Page</b>
                        </h2>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Reports;
