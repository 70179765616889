import { Tooltip } from 'react-bootstrap';
import { notify } from './notification.helpers';

/**
 * @function
 * Handles errors returned by an API call.
 *
 * This function differentiates between validation and unexpected errors,
 * performing different actions for each. For 422 HTTP errors (Unprocessable Entity),
 * it creates an object with all the errors returned from the response and passes it to a form error setter.
 * For all other errors, it triggers a notification with the error message or a default error message.
 *
 * @param {object} error - The error object caught from an API request, usually from a catch block.
 * @param {function} t - The translation function, used for localizing the default error message.
 * @param {function} formErrorSetter - Optional function that sets form errors. It gets an object where keys are the fields that failed validation, and values are the corresponding error messages.
 *
 * @example
 *  handleApiError(apiError, translate, setFormErrors);
 */
export const handleApiError = (error, formErrorSetter = () => {}) => {
    if (error.status === 422) {
        const errObj = {};
        // console.log(error.message.response.data?.data?.errors);

        error.message?.response?.data?.data?.errors?.forEach((err) => {
            errObj[err.path] = err.msg;
        });

        formErrorSetter(errObj);
    } else {
        notify(
            'error',
            typeof error.message === 'string'
                ? error.message
                : 'Something went wrong!'
        );
    }
};

export const renderTooltip = (props) => (
    <Tooltip id='button-tooltip' {...props}>
        {props}
    </Tooltip>
);

export const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    if (hours > 0 && minutes > 0) {
        return `${hours} hr ${minutes} min`;
    } else if (hours > 0) {
        return `${hours} hr`;
    } else if (minutes > 0) {
        return `${minutes} min`;
    }

    return '0 min'; // Default case
};
