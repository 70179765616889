import moment from 'moment';
import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

const ReportedUserModal = (props) => {
    const {
        showReportedUser,
        handleClose,
        reportedUserData,
        isLoading,
        handleSelectChange,
        selectedStatus,
        errorMessage,
        mutateReportedUser,
        setErrorMessage,
        handleInput,
    } = props;

    const handleSubmit = (e) => {
        e.preventDefault();
        const errors = { ...errorMessage };

        if (selectedStatus === 1 && !reportedUserData.remarks) {
            errors.remarks = 'Remark is required';
        } else errors.remarks = true;

        setErrorMessage({ ...errors });
        mutateReportedUser({
            sRemarks: selectedStatus === 1 ? reportedUserData.remarks : '',
            eStatus: selectedStatus,
            id: reportedUserData.id,
        });
    };
    return (
        <>
            <Modal className='fade' show={showReportedUser}>
                <Modal.Header>
                    <Modal.Title>Verify Reported User</Modal.Title>
                    <Button
                        variant=''
                        className='btn-close'
                        onClick={handleClose}
                    ></Button>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group
                            className='mb-3'
                            controlId='exampleForm.ControlInput1'
                        >
                            <>
                                <div>
                                    <Form.Label>
                                        <h4
                                            style={{
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Reported User
                                        </h4>
                                    </Form.Label>

                                    <Form.Control
                                        type='text'
                                        value={
                                            reportedUserData?.reportedUser
                                                ?.oName
                                                ? (reportedUserData
                                                      ?.reportedUser?.oName
                                                      ?.sFirstName || '') +
                                                  (reportedUserData
                                                      ?.reportedUser?.oName
                                                      ?.sMiddleName
                                                      ? ' ' +
                                                        reportedUserData
                                                            ?.reportedUser.oName
                                                            .sMiddleName
                                                      : '') +
                                                  (reportedUserData
                                                      ?.reportedUser?.oName
                                                      ?.sLastName
                                                      ? ' ' +
                                                        reportedUserData
                                                            ?.reportedUser.oName
                                                            .sLastName
                                                      : '')
                                                : ''
                                        }
                                        disabled={true}
                                    />
                                </div>
                                <br />
                                <div>
                                    <Form.Label>
                                        <h4
                                            style={{
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Reported By
                                        </h4>
                                    </Form.Label>

                                    <Form.Control
                                        type='text'
                                        value={
                                            reportedUserData?.reportedBy?.oName
                                                ? (reportedUserData?.reportedBy
                                                      ?.oName?.sFirstName ||
                                                      '') +
                                                  (reportedUserData?.reportedBy
                                                      ?.oName?.sMiddleName
                                                      ? ' ' +
                                                        reportedUserData
                                                            ?.reportedBy.oName
                                                            .sMiddleName
                                                      : '') +
                                                  (reportedUserData?.reportedBy
                                                      ?.oName?.sLastName
                                                      ? ' ' +
                                                        reportedUserData
                                                            ?.reportedBy.oName
                                                            .sLastName
                                                      : '')
                                                : ''
                                        }
                                        disabled={true}
                                    />
                                </div>

                                <div>
                                    <br />
                                    <Form.Label>
                                        <h4
                                            style={{
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Contact Number
                                        </h4>
                                    </Form.Label>

                                    <Form.Control
                                        type='text'
                                        value={
                                            reportedUserData?.contactNumber ||
                                            '-'
                                        }
                                        disabled={true}
                                    />
                                </div>

                                <div>
                                    <br />
                                    <Form.Label>
                                        <h4
                                            style={{
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Date
                                        </h4>
                                    </Form.Label>

                                    <Form.Control
                                        type='text'
                                        value={
                                            moment(
                                                reportedUserData?.date
                                            ).format('DD-MM-YYYY') || '-'
                                        }
                                        disabled={true}
                                    />
                                </div>

                                <div>
                                    <br />
                                    <Form.Label>
                                        <h4
                                            style={{
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Message
                                        </h4>
                                    </Form.Label>

                                    <Form.Control
                                        as='textarea'
                                        aria-label='With textarea'
                                        style={{
                                            width: '100%', // Adjust the width as needed
                                            minHeight: '100px', // Adjust the minimum height as needed
                                            resize: 'vertical', // Allow vertical resizing
                                        }}
                                        value={reportedUserData?.message}
                                        disabled={true}
                                    />
                                </div>

                                <div>
                                    <br />
                                    <Form.Label>
                                        <h4
                                            style={{
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Status
                                        </h4>
                                    </Form.Label>
                                    <Form.Select
                                        aria-label='Default select example'
                                        className='c-pointer'
                                        onChange={handleSelectChange}
                                        value={selectedStatus}
                                    >
                                        <option value={0}>Pending</option>
                                        <option value={1}>Resolved</option>
                                    </Form.Select>
                                    {selectedStatus === 1 && (
                                        <div>
                                            <br />
                                            <Form.Label>
                                                <h4
                                                    style={{
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    Remark
                                                </h4>
                                            </Form.Label>

                                            <Form.Control
                                                as='textarea'
                                                aria-label='With textarea'
                                                style={{
                                                    width: '100%', // Adjust the width as needed
                                                    minHeight: '100px', // Adjust the minimum height as needed
                                                    resize: 'vertical', // Allow vertical resizing
                                                }}
                                                value={
                                                    reportedUserData?.remarks
                                                }
                                                onChange={handleInput}
                                                name='remarks'
                                                autoComplete='off'
                                            />
                                            {errorMessage.sRemarks && (
                                                <div className='text-danger fs-12'>
                                                    {errorMessage.sRemarks}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='danger light' onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        variant='primary'
                        onClick={handleSubmit}
                        disabled={isLoading}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ReportedUserModal;
