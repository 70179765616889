import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import {
    editInterestCategory,
    viewInterestItems,
} from '../../../api/adapters/interestCategory';
import { useNavigate, useParams } from 'react-router-dom';
import AddModal from '../../pages/AddModal/AddModal';
import { notify } from '../../../utils/helpers/notification.helpers';
import {
    addInterestItem,
    deleteInterestItem,
    editInterestItem,
    toggleInterestItem,
} from '../../../api/adapters/interestItem';
import EditModal from '../../pages/AddModal/EditModal';
import Datatable from '../../pages/Datatable/Datatable';
import Loader from '../../pages/Loader/Loader';
import { handleApiError } from '../../../utils/helpers/common.helpers';
import { useDispatch } from 'react-redux';
import { setConnectedUser } from '../../../actions/connected-user';
import PageTitle from '../../layouts/PageTitle';

const ViewPreference = (props) => {
    const { id } = useParams();

    const [showLogin, setShowLogin] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState({});

    const [itemData, setItemData] = useState('');
    const [categoryData, setCategoryData] = useState('');
    const [item, setAddItem] = useState({ sTitle: '', id: '' });

    let errorsObj = { title: '' };

    const [itemsError, setItemsError] = useState(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const pageSize = 5;
    const [queryData, setQueryData] = useState({
        start: 0,
        length: pageSize,
        order: [{ dir: 'asc', column: 0 }],
        columns: [{ data: 'sTitle' }],
    });

    const {
        data: interestItems,
        isFetching,
        isLoading,
        refetch,
        error: queryError,
    } = useQuery({
        queryKey: ['interestItems', queryData],
        queryFn: () => viewInterestItems(id, queryData).then((res) => res.data),
        retry: false,
    });

    useEffect(() => {
        setItemsError(queryError);
    }, [queryError]);

    useEffect(() => {
        if (itemsError) {
            notify('error', itemsError.message);

            if (itemsError.status === 401) {
                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
        }
        setItemsError(null);
    }, [itemsError]);

    const editItems = async (title, id, sIconUrl) => {
        setShowEditModal(true);
        setItemData({ sTitle: title, id: id, sIconUrl: sIconUrl });
    };

    const handleAddInput = (e) => {
        let value = e.target.value;

        const errorObj = { ...errorsObj };
        if (value === '') {
            errorObj.email = 'Email is Required';
        }
        setAddItem((preInquiryData) => ({
            ...preInquiryData,
            sTitle: value,
            id: id,
        }));
    };

    const addItem = async () => {
        setShowLogin(true);
        setCategoryData('');
    };

    const handleAddImage = async (sTitle, event) => {
        const file = event.target.files[0];

        if (file) {
            if (allowedFileTypes.includes(file.type)) {
                const formData = new FormData();

                formData.append('sTitle', sTitle);
                formData.append('uploadfile', file);

                mutateAddInterestItem(formData);
            } else {
                notify(
                    'error',
                    'Invalid file type. Only PNG files are allowed.'
                );
            }
        }
    };

    const { mutate: mutateAddInterestItem } = useMutation({
        mutationFn: (data) => addInterestItem(id, data),
        onSuccess: (data) => {
            notify('success', data.message);
            refetch();
            setShowLogin(false);
            setAddItem('');
        },

        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const handleInput = (e) => {
        let value = e.target.value;

        setItemData((prevData) => ({
            ...prevData,
            sTitle: value,
        }));
    };

    const handleInputCategory = (e) => {
        let value = e.target.value;
        setCategoryData((prevData) => ({
            ...prevData,
            sTitle: value,
            id: id,
        }));
    };

    const { mutate: mutateEditInterestCategory } = useMutation({
        mutationFn: (id, data) => editInterestCategory(id, data),
        onSuccess: (data) => {
            notify('success', data.message);

            refetch();
            setShowEditModal(false);
            setItemData('');
            setItemData('');
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const { mutate: mutateEditInterestItem } = useMutation({
        mutationFn: (id, data) => editInterestItem(id, data),
        onSuccess: (data) => {
            refetch();
            notify('success', data.message);
            setShowEditModal(false);
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const allowedFileTypes = ['image/png'];

    const { mutate: mutateDeleteInterestItem } = useMutation({
        mutationFn: (id) => deleteInterestItem(id),
        onSuccess: (data) => {
            notify('success', data.message);
            refetch();
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const { mutate: mutateToggleButton } = useMutation({
        mutationFn: (id) => toggleInterestItem(id),
        onSuccess: (data) => {
            notify('success', data?.message);
            refetch();
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const deleteItems = async (id) => {
        mutateDeleteInterestItem(id);
    };

    return (
        <>
            <PageTitle
                activeMenu='Category'
                motherMenu='Preference'
                pageContent={interestItems?.data?.categoryName?.sTitle}
            />
            <div className='col-12'>
                <div className='card'>
                    <div className='card-body'>
                        <div className='card-header d-flex justify-content-between'>
                            <label className='label'>
                                <h2>
                                    <span>
                                        {
                                            interestItems?.data?.categoryName
                                                ?.sTitle
                                        }
                                    </span>
                                </h2>
                            </label>
                            <Button variant='primary' onClick={addItem}>
                                Add item
                            </Button>
                        </div>

                        <div className='card-body '>
                            {isFetching ? (
                                <Loader />
                            ) : (
                                <Datatable
                                    col2={'Icon Pic'}
                                    col3={'Item'}
                                    col4={'Status'}
                                    col5={'Actions'}
                                    categoryData={interestItems?.data?.aRecords}
                                    interestItems={interestItems}
                                    pageSize={pageSize}
                                    setQueryData={setQueryData}
                                    queryData={queryData}
                                    refetch={refetch}
                                    editItems={editItems}
                                    deleteItems={deleteItems}
                                    mutateToggle={mutateToggleButton}
                                    isLoading={isLoading}
                                />
                            )}

                            <EditModal
                                title={
                                    itemData
                                        ? 'Edit Preference Item'
                                        : 'Edit Preference Category'
                                }
                                label={
                                    itemData
                                        ? 'Preference Item'
                                        : 'Preference Category'
                                }
                                iconLabel={itemData ? 'Edit Icon Image' : null}
                                placeholder={
                                    itemData
                                        ? 'Please add preference item'
                                        : 'Please add preference category'
                                }
                                showEdit={showEditModal}
                                close={() => {
                                    setShowEditModal(false);
                                    setItemData('');
                                }}
                                itemData={itemData}
                                categoryData={categoryData}
                                handleChange={handleInput}
                                handleInputCategory={handleInputCategory}
                                mutate={mutateEditInterestCategory}
                                mutateEditInterestItem={mutateEditInterestItem}
                                value={categoryData}
                                setErrorMessage={setErrorMessage}
                                backendErrorMessage={errorMessage}
                            />

                            <AddModal
                                title='Add Interest Item'
                                addLabel='Add Interest Title'
                                addTitle='Add Interest Item'
                                addPlaceholder='Please add interest item title'
                                show={showLogin}
                                close={() => {
                                    setShowLogin(false);
                                }}
                                handleAddInput={handleAddInput}
                                mutateAddInterestItem={mutateAddInterestItem}
                                item={item}
                                setItemData={setAddItem}
                                handleAddImage={handleAddImage}
                                setErrorMessage={setErrorMessage}
                                backendErrorMessage={errorMessage}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ViewPreference;
