import React, { Fragment, useState } from 'react';
import logo from '../../../assets/images/logo-symbol.png';

import { useMutation } from '@tanstack/react-query';
import { profileUpdate } from '../../../api/adapters/profile';
import { notify } from '../../../utils/helpers/notification.helpers';
import { useDispatch } from 'react-redux';
import { setConnectedUser } from '../../../actions/connected-user';
import { handleApiError } from '../../../utils/helpers/common.helpers';
import { clearCookies } from '../../../utils/helpers/cookies.helpers';
import { useNavigate } from 'react-router-dom';

const ProfileEdit = ({ profile }) => {
    const [profileImage, setProfileImage] = useState(null);
    const [isInputVisible, setInputVisible] = useState(false);
    const [userName, setUserName] = useState('');
    const errorsObj = {};
    const [errorMessage, setErrorMessage] = useState({
        sUserName: '',
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    const handleImageUpload = async (event) => {
        const file = event.target.files[0];

        if (file) {
            if (allowedFileTypes.includes(file.type)) {
                setProfileImage(file);
                const formData = new FormData();
                formData.append(
                    'sUserName',
                    userName ? userName : profile?.sUserName
                );
                formData.append('uploadfile', file);
                mutateProfileUserName(formData);
            } else {
                notify(
                    'error',
                    'Invalid file type. Only PNG, JPG, and JPEG files are allowed.'
                );
            }
        }
    };

    const handleButtonClick = () => {
        setUserName(profile?.sUserName);
        setInputVisible(true);
    };

    function checkValidation() {
        const fieldsToValidate = [
            {
                field: 'sUserName',
                label: 'UserName',
                validation: (value) =>
                    value?.trim() === '' ? 'User Name is required' : '',
            },
        ];

        let hasError = false;

        fieldsToValidate.forEach(({ field, validation }) => {
            const value = field === 'sUserName' ? userName : '';

            const errorMessage = validation(value);
            errorsObj[field] = errorMessage;

            if (errorMessage !== '') hasError = true;
        });

        return hasError;
    }

    const handleSaveClick = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('sUserName', userName);

        let isError = checkValidation();
        if (isError) {
            return setErrorMessage(errorsObj);
        } else {
            setErrorMessage(errorsObj);
        }

        mutateProfileUserName(formData);

        dispatch(setConnectedUser({ ...profile, sUserName: userName }));
        setInputVisible(false);
    };

    const handleCancelClick = async (e) => {
        e.preventDefault();
        setInputVisible(false);
    };

    const { mutate: mutateProfileUserName } = useMutation({
        mutationFn: (data) => profileUpdate(data),
        onSuccess: (data) => {
            notify('success', data.message);
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                if (error.status === 401) {
                    clearCookies();
                    navigate('/sign-in');
                    dispatch(
                        setConnectedUser({
                            sEmail: '',
                            sProfilePicUrl: '',
                            sUserName: '',
                        })
                    );
                }
            }
            handleApiError(error, setErrorMessage);
        },
    });

    return (
        <>
            <div className='col-12'>
                <div className='card'>
                    <div className='card-body'>
                        <div className='card-header d-flex justify-content-between'>
                            <label className='label'>
                                <h2>
                                    <span>My Profile</span>
                                </h2>
                            </label>
                            <div className='d-flex'>
                                {isInputVisible ? (
                                    <>
                                        <button
                                            type='button'
                                            className='btn btn-primary mx-2'
                                            style={{ width: '100px' }}
                                            onClick={handleCancelClick}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type='button'
                                            className='btn btn-primary mx-2'
                                            style={{ width: '100px' }}
                                            onClick={handleSaveClick}
                                        >
                                            Save
                                        </button>
                                    </>
                                ) : (
                                    <button
                                        type='button'
                                        className='btn btn-primary mx-2'
                                        id='editProfileModal'
                                        style={{ width: '100px' }}
                                        onClick={handleButtonClick}
                                    >
                                        Edit
                                    </button>
                                )}
                            </div>
                        </div>

                        <div className='card-body '>
                            <Fragment>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <div className='px-3 pt-2 pb-0'>
                                            <div className='profile-info  d-flex'>
                                                <div className='profile-info '>
                                                    <label
                                                        className='-label'
                                                        htmlFor='uploadBtn'
                                                    ></label>
                                                    {profileImage ? (
                                                        <div className='profile-dp'>
                                                            <img
                                                                src={URL.createObjectURL(
                                                                    profileImage
                                                                )}
                                                                alt=''
                                                                width={'40%'}
                                                            />
                                                        </div>
                                                    ) : profile?.sProfilePicUrl ? (
                                                        <div className='profile-dp'>
                                                            <img
                                                                src={
                                                                    profile?.sProfilePicUrl
                                                                }
                                                                alt=''
                                                                width={'40%'}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <img
                                                            src={logo}
                                                            alt=''
                                                        />
                                                    )}

                                                    <input
                                                        className='upload-input'
                                                        type='file'
                                                        onChange={
                                                            handleImageUpload
                                                        }
                                                        accept='.png, .jpg, .jpeg, .gif'
                                                    />
                                                </div>
                                                <div className='profile-details'>
                                                    <div className='profile-name px-3 pt-2'>
                                                        <p>Name</p>
                                                        {isInputVisible ? (
                                                            <>
                                                                <input
                                                                    className='form-control'
                                                                    type='text'
                                                                    value={
                                                                        userName
                                                                    }
                                                                    autoFocus
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setUserName(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                />
                                                                {errorMessage.sUserName && (
                                                                    <div className='text-danger fs-12'>
                                                                        {
                                                                            errorMessage.sUserName
                                                                        }
                                                                    </div>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <h4 className='text-dark mb-0'>
                                                                    {
                                                                        profile?.sUserName
                                                                    }
                                                                </h4>
                                                            </>
                                                        )}
                                                    </div>
                                                    <div className='profile-email px-2 pt-2'>
                                                        <p>Email</p>
                                                        <h4 className='text-dark mb-0'>
                                                            {profile?.sEmail}
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProfileEdit;
