import React, { useState } from 'react';
import { Button, Modal, Form, InputGroup } from 'react-bootstrap';
import logo from '../../../assets/images/logo-symbol.png';
import { notify } from '../../../utils/helpers/notification.helpers';
import {
    isValidCity,
    isValidName,
    isValidUniversity,
} from '../../../utils/validators/common.validators';

const AddModal = (props) => {
    const {
        handleAddInput,
        addLabel,
        addTitle,
        addPlaceholder,
        mutateAddInterestItem,
        item,
        title,
        label,
        handleChange,
        value,
        mutate,
        placeholder,
        handleSelectChange,
        selectedValue,
        cities,
        handleSelectCity,
        selectedCity,
        handleAnswerInput,
        addDropdownlabel,
        addDropdownPlaceholder,
        selectedCountry,
        disable,
        setErrorMessage,
        backendErrorMessage,
        question,
        answer,
        isMutating,
        setItemData,
    } = props;

    const countrylist = props.countrylist?.data || props.countrylist;

    let errorsObj = {
        addtitle: '',
        addimage: '',
        selectItem: '',
    };

    function checkValidation() {
        const fieldsToValidate = [
            {
                field: 'sUniversityName',
                label: 'title',
                validation: (value) => {
                    if (
                        !value ||
                        value?.sTitle?.trim() === '' ||
                        !value?.trim()
                    ) {
                        return title === 'Add Residence'
                            ? 'Residence Required'
                            : title === 'Add University'
                            ? 'University Required'
                            : 'Title is required';
                    } else if (!isValidUniversity(value)) {
                        return title === 'Add Residence'
                            ? 'Invalid Residence Name'
                            : title === 'Add University'
                            ? 'Invalid University Name'
                            : 'Invalid Name';
                    }
                },
            },
            {
                field: 'selectedCountry',
                label: 'country',
                validation: (value) =>
                    !value || value === 'Select Country Name'
                        ? 'Country is required'
                        : '',
            },
            {
                field: 'selectedCity',
                label: 'city',
                validation: (value) =>
                    !value || value === 'Select City Name'
                        ? 'City is required'
                        : '',
            },
        ];

        let hasError = false;

        fieldsToValidate.forEach(({ field, validation }) => {
            const errorValue =
                field === 'sUniversityName'
                    ? value?.sTitle || value
                    : field === 'selectedCountry'
                    ? selectedCountry?.id || selectedCountry
                    : field === 'selectedCity'
                    ? selectedCity?.id || selectedCity
                    : '';

            const errorMessage = validation(errorValue);

            errorsObj[field] = errorMessage;

            if (errorMessage) {
                hasError = true;
            }
        });

        return hasError;
    }

    function singleFieldValidation() {
        const fieldsToValidate = [
            {
                field: 'sTitle',
                label: 'title',
                validation: (value) => {
                    if (
                        !value ||
                        value?.sTitle?.trim() === '' ||
                        !value?.trim()
                    ) {
                        return title === 'Add Keyword'
                            ? 'Keyword Required'
                            : title === 'Add Country'
                            ? 'Country Required'
                            : title === 'Add City'
                            ? 'City Required'
                            : title === 'Add Preference Category'
                            ? 'Category Required'
                            : 'Title is required';
                    } else if (!isValidName(value) && title !== 'Add Keyword') {
                        return title === 'Add Country'
                            ? 'Invalid Country Name'
                            : title === 'Add City'
                            ? 'Invalid City Name'
                            : title === 'Add Preference Category'
                            ? 'Invalid Category Name'
                            : 'Invalid Title';
                    }
                },
            },
        ];

        let hasError = false;
        fieldsToValidate.forEach(({ field, validation }) => {
            const errorValue = field === 'sTitle' ? value?.sTitle || value : '';

            const errorMessage = validation(errorValue);
            errorsObj[field] = errorMessage;

            if (errorMessage) {
                hasError = true;
            }
        });

        return hasError;
    }

    function faqValidation() {
        const fieldsToValidate = [
            {
                field: 'Question',
                label: 'question',
                validation: (value) =>
                    !question?.trim() ? 'Question is required' : '',
            },
            {
                field: 'Answer',
                label: 'answer',
                validation: (value) =>
                    !answer?.trim() ? 'Answer is required' : '',
            },
        ];

        let hasError = false;
        fieldsToValidate.forEach(({ field, validation }) => {
            const errorValue =
                field === 'Question'
                    ? value?.Question || value
                    : field === 'Answer'
                    ? value?.Answer || value
                    : '';

            const errorMessage = validation(errorValue);
            errorsObj[field] = errorMessage;

            if (errorMessage) {
                hasError = true;
            }
        });

        return hasError;
    }

    function cityValidation() {
        const fieldsToValidate = [
            {
                field: 'sCity',
                label: 'title',
                validation: (value) => {
                    if (
                        !value ||
                        value?.sTitle?.trim() === '' ||
                        !value?.trim()
                    ) {
                        return title === 'Add City'
                            ? 'City Required'
                            : 'Title is required';
                    } else if (!isValidCity(value)) {
                        return 'Invalid City Name';
                    }
                },
            },
            {
                field: 'selectedCountry',
                label: 'country',
                validation: (value) =>
                    !selectedValue ||
                    !selectedValue?.id ||
                    selectedValue?.id === 'Select Country Name'
                        ? 'Country required'
                        : '',
            },
        ];

        let hasError = false;

        fieldsToValidate.forEach(({ field, validation }) => {
            const errorValue =
                field === 'sCity'
                    ? value?.sTitle || value
                    : field === 'selectedCountry'
                    ? selectedCountry?.id || selectedCountry
                    : '';

            const errorMessage = validation(errorValue);

            errorsObj[field] = errorMessage;

            if (errorMessage) {
                hasError = true;
            }
        });

        return hasError;
    }

    function popUpValidation() {
        const fieldsToValidate = [
            {
                field: 'sPopUpText',
                label: 'popup',
                validation: (value) => {
                    if (
                        !value ||
                        value?.sTitle?.trim() === '' ||
                        !value?.trim()
                    ) {
                        return title === 'Add Pop Up'
                            ? 'Pop-up Text Required'
                            : '';
                    }
                },
            },
            {
                field: 'selectedValue',
                label: 'items',
                validation: (value) =>
                    !selectedValue || !selectedValue?.id
                        ? 'Interest Item required'
                        : '',
            },
        ];

        let hasError = false;

        fieldsToValidate.forEach(({ field, validation }) => {
            const errorValue =
                field === 'sPopUpText'
                    ? value?.sTitle || value
                    : field === 'selectedValue'
                    ? selectedValue?.id || selectedValue
                    : '';

            const errorMessage = validation(errorValue);
            errorsObj[field] = errorMessage;

            if (errorMessage) {
                hasError = true;
            }
        });

        return hasError;
    }

    function interestValidation() {
        const fieldsToValidate = [
            // {
            //     field: 'image',
            //     label: 'image',
            //     validation: (image) => {
            //         if (!image) {
            //             return 'Image is required';
            //         }
            //     },
            // },
            {
                field: 'interestItem',
                label: 'country',
                validation: (value) =>
                    !item ||
                    !item?.sTitle?.trim() ||
                    selectedValue?.id === 'Select Country Name'
                        ? 'Title required'
                        : '',
            },
        ];

        let hasError = false;

        fieldsToValidate.forEach(({ field, validation }) => {
            const errorValue = field === 'image' ? image : '';

            const errorMessage = validation(errorValue);

            errorsObj[field] = errorMessage;

            if (errorMessage) hasError = true;
        });

        return hasError;
    }

    const [image, setImage] = useState();
    const [emoji, setEmoji] = useState();
    const allowedFileTypes = ['image/png'];
    const allowedPopUpFileTypes = ['image/png', 'image/jpeg'];

    const addSubmit = async (e) => {
        e.preventDefault();

        if (title === 'Add Interest Item') {
            let isError =
                title === 'Add Interest Item'
                    ? interestValidation()
                    : checkValidation();

            if (isError) return setErrorMessage(errorsObj);
            else setErrorMessage(errorsObj);

            const formData = new FormData();

            formData.append('uploadfile', image);
            formData.append('sTitle', item?.sTitle);

            mutateAddInterestItem(formData);
            setImage('');
            props?.close();
            setItemData('');
        } else {
            const formData = new FormData();
            let isError =
                title === 'Add Keyword' ||
                title === 'Add Country' ||
                title === 'Add Preference Category'
                    ? singleFieldValidation()
                    : title === 'Add FAQ'
                    ? faqValidation()
                    : title === 'Add City'
                    ? cityValidation()
                    : title === 'Add Pop Up'
                    ? popUpValidation()
                    : checkValidation();

            if (isError) {
                return setErrorMessage(errorsObj);
            } else {
                setErrorMessage(errorsObj);
            }

            formData.append('uploadfile', image);

            formData.append('iItems', selectedValue?.id);

            selectedValue
                ? mutate({ sTitle: value?.sTitle, id: selectedValue?.id })
                : selectedCity
                ? mutate({ sTitle: value?.sTitle, id: selectedCity?.id })
                : title === 'Add FAQ'
                ? mutate({ question, answer })
                : mutate(value);
        }
    };

    const addImage = async (e) => {
        const file = e.target.files[0];
        if (file) {
            if (title === 'Add Pop Up') {
                if (allowedPopUpFileTypes.includes(file.type)) {
                    setImage(file);
                } else {
                    notify(
                        'error',
                        'Invalid file type. Only PNG files are allowed.'
                    );
                }
            } else if (allowedFileTypes.includes(file.type)) {
                setImage(file);
            } else {
                notify(
                    'error',
                    'Invalid file type. Only PNG files are allowed.'
                );
            }
        }
    };
    const handleViewPreferenceClose = async (e) => {
        setErrorMessage(errorsObj);
        setImage('');
        setItemData('');
        props?.close();
    };

    return (
        <>
            <Modal className='fade' show={props.show}>
                <Modal.Header>
                    <Modal.Title>{item ? addTitle : title}</Modal.Title>
                    <Button
                        variant=''
                        className='btn-close'
                        onClick={
                            title === 'Add Interest Item'
                                ? handleViewPreferenceClose
                                : props?.handleClose
                        }
                    ></Button>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group
                            className='mb-3'
                            controlId='exampleForm.ControlInput1'
                        >
                            <>
                                {title === 'Add Interest Item' ? (
                                    <>
                                        <div>
                                            <Form.Label>
                                                <h4
                                                    style={{
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {'Add Icon Image'}
                                                </h4>
                                            </Form.Label>

                                            <div
                                                className='profile-dp'
                                                style={{ textAlign: 'center' }}
                                            >
                                                {image ? (
                                                    <img
                                                        src={URL.createObjectURL(
                                                            image
                                                        )}
                                                        alt=''
                                                    />
                                                ) : emoji ? (
                                                    <span
                                                        style={{
                                                            fontSize: '50px',
                                                        }}
                                                    >
                                                        {emoji}
                                                    </span>
                                                ) : (
                                                    <img src={logo} alt='' />
                                                )}
                                                <input
                                                    className='upload-input '
                                                    type='file'
                                                    onChange={(e) =>
                                                        addImage(e)
                                                    }
                                                    style={{
                                                        height: '50%',
                                                    }}
                                                />
                                                <input
                                                    className='emoji-input'
                                                    type='text'
                                                    placeholder='Enter an emoji'
                                                    value={emoji}
                                                    onChange={(e) =>
                                                        setEmoji(e.target.value)
                                                    }
                                                    style={{ height: '50%' }}
                                                />
                                            </div>
                                            {backendErrorMessage.image && (
                                                <div className='text-danger fs-12'>
                                                    {backendErrorMessage.image}
                                                </div>
                                            )}
                                        </div>
                                        <br />
                                    </>
                                ) : null}

                                <div>
                                    <>
                                        <Form.Label>
                                            <h4
                                                style={{
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {title === 'Add Interest Item'
                                                    ? addLabel
                                                    : label}
                                            </h4>
                                        </Form.Label>
                                        <Form.Control
                                            type='text'
                                            placeholder={
                                                title === 'Add Interest Item'
                                                    ? addPlaceholder
                                                    : placeholder
                                            }
                                            autoFocus
                                            onChange={
                                                title === 'Add Interest Item'
                                                    ? handleAddInput
                                                    : handleChange
                                            }
                                            autoComplete='off'
                                        />
                                        {title === 'Add FAQ'
                                            ? backendErrorMessage?.Question && (
                                                  <div className='text-danger fs-12'>
                                                      {
                                                          backendErrorMessage?.Question
                                                      }
                                                  </div>
                                              )
                                            : backendErrorMessage?.sUniversityName && (
                                                  <div className='text-danger fs-12'>
                                                      {
                                                          backendErrorMessage?.sUniversityName
                                                      }
                                                  </div>
                                              )}
                                        {backendErrorMessage?.sTitle && (
                                            <div className='text-danger fs-12'>
                                                {backendErrorMessage?.sTitle}
                                            </div>
                                        )}
                                        {backendErrorMessage?.sCity && (
                                            <div className='text-danger fs-12'>
                                                {backendErrorMessage?.sCity}
                                            </div>
                                        )}
                                        {backendErrorMessage?.sPopUpText && (
                                            <div className='text-danger fs-12'>
                                                {
                                                    backendErrorMessage?.sPopUpText
                                                }
                                            </div>
                                        )}
                                        {backendErrorMessage?.interestItem && (
                                            <div className='text-danger fs-12'>
                                                {
                                                    backendErrorMessage?.interestItem
                                                }
                                            </div>
                                        )}
                                    </>

                                    <br />
                                    {title === 'Add City' ||
                                    title === 'Add Pop Up' ? (
                                        <>
                                            <Form.Label>
                                                <h4
                                                    style={{
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {addDropdownlabel}
                                                </h4>
                                            </Form.Label>
                                            <Form.Select
                                                aria-label='Default select example'
                                                className='c-pointer'
                                                onChange={handleSelectChange}
                                            >
                                                <option>
                                                    {addDropdownPlaceholder}
                                                </option>
                                                {countrylist?.map((data) => (
                                                    <>
                                                        <option
                                                            value={data?._id}
                                                        >
                                                            {data?.sTitle}
                                                        </option>
                                                    </>
                                                ))}
                                            </Form.Select>
                                            {backendErrorMessage?.selectCountry && (
                                                <div className='text-danger fs-12'>
                                                    {
                                                        backendErrorMessage?.selectCountry
                                                    }
                                                </div>
                                            )}
                                            {backendErrorMessage?.selectedValue && (
                                                <div className='text-danger fs-12'>
                                                    {
                                                        backendErrorMessage?.selectedValue
                                                    }
                                                </div>
                                            )}
                                            {backendErrorMessage?.selectedCountry && (
                                                <div className='text-danger fs-12'>
                                                    {
                                                        backendErrorMessage?.selectedCountry
                                                    }
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        ''
                                    )}
                                    {title === 'Add University' ||
                                    title === 'Add Residence' ? (
                                        <>
                                            <Form.Label>
                                                <h4
                                                    style={{
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    Country Name
                                                </h4>
                                            </Form.Label>
                                            <Form.Select
                                                aria-label='Default select example'
                                                className='c-pointer'
                                                onChange={handleSelectChange}
                                            >
                                                <option>
                                                    Select Country Name
                                                </option>
                                                {countrylist &&
                                                    countrylist?.map((data) => (
                                                        <>
                                                            <option
                                                                value={
                                                                    data?._id
                                                                }
                                                            >
                                                                {data?.sTitle}
                                                            </option>
                                                        </>
                                                    ))}
                                            </Form.Select>
                                            {backendErrorMessage?.selectedCountry && (
                                                <div className='text-danger fs-12'>
                                                    {
                                                        backendErrorMessage?.selectedCountry
                                                    }
                                                </div>
                                            )}
                                            <br />
                                            <Form.Label>
                                                <h4
                                                    style={{
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    City Name
                                                </h4>
                                            </Form.Label>
                                            <Form.Select
                                                aria-label='Default select example'
                                                onChange={handleSelectCity}
                                                className='c-pointer'
                                                disabled={disable}
                                            >
                                                <option>
                                                    Select City Name
                                                </option>
                                                {cities?.map((data) => (
                                                    <>
                                                        <option
                                                            value={data?._id}
                                                        >
                                                            {data?.sTitle}
                                                        </option>
                                                    </>
                                                ))}
                                            </Form.Select>
                                            {backendErrorMessage?.selectedCity && (
                                                <div className='text-danger fs-12'>
                                                    {
                                                        backendErrorMessage?.selectedCity
                                                    }
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        ''
                                    )}
                                    {title === 'Add FAQ' ? (
                                        <>
                                            <Form.Label>
                                                <h4
                                                    style={{
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {props?.label2}
                                                </h4>
                                            </Form.Label>

                                            <InputGroup>
                                                <Form.Control
                                                    as='textarea'
                                                    aria-label='With textarea'
                                                    placeholder={
                                                        props?.placeholder2
                                                    }
                                                    style={{
                                                        width: '100%', // Adjust the width as needed
                                                        minHeight: '100px', // Adjust the minimum height as needed
                                                        resize: 'vertical', // Allow vertical resizing
                                                    }}
                                                    onChange={handleAnswerInput}
                                                    autoComplete='off'
                                                />
                                            </InputGroup>
                                            {backendErrorMessage.Answer && (
                                                <div className='text-danger fs-12'>
                                                    {backendErrorMessage.Answer}
                                                </div>
                                            )}
                                        </>
                                    ) : null}
                                </div>
                            </>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant='danger light'
                        onClick={
                            title === 'Add Interest Item'
                                ? handleViewPreferenceClose
                                : props?.handleClose
                        }
                    >
                        Close
                    </Button>
                    <Button
                        variant='primary'
                        onClick={addSubmit}
                        disabled={isMutating}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AddModal;
