import apiClient from '../client/api-client';

export const keywordList = async (data) => {
    const url = 'admin/chatkeyword/list';
    return apiClient({
        url,
        data,
        method: 'POST',
    });
};

export const addKeyword = async (data) => {
    const url = 'admin/chatkeyword/add';
    return apiClient({
        url,
        data,
        method: 'POST',
    });
};

export const deleteKeyword = async (id) => {
    const url = `admin/chatkeyword/${id}/delete`;

    return apiClient({
        url,
        method: 'DELETE',
    });
};

export const toggleKeywordStatus = async (id) => {
    const url = `admin/chatkeyword/${id}/status/update`;
    return apiClient({
        url,
        method: 'PATCH',
    });
};

export const editKeyword = async (data) => {
    const url = `admin/chatkeyword/${data?.id}/edit`;
    return apiClient({
        url,
        data: { sTitle: data?.sTitle },
        method: 'PATCH',
    });
};
