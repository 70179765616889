import React, { useEffect, useState } from 'react';
import DashboardCard from './DashboardCard';
import { useQuery } from '@tanstack/react-query';
import { dashboardCount } from '../../../api/adapters/dashboard';
import Loader from '../../pages/Loader/Loader';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { notify } from '../../../utils/helpers/notification.helpers';
import { setConnectedUser } from '../../../actions/connected-user';
import { clearCookies } from '../../../utils/helpers/cookies.helpers';

const Home = () => {
    const [dashboardError, setDashboardError] = useState(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {
        data: dashboard,
        isLoading,
        error: queryError,
    } = useQuery({
        queryKey: ['dashboard'],
        queryFn: () => dashboardCount().then((res) => res.data),
        retry: false,
    });

    useEffect(() => {
        setDashboardError(queryError);
    }, [queryError]);

    useEffect(() => {
        if (dashboardError) {
            notify('error', dashboardError.message);

            if (dashboardError.status === 401) {
                clearCookies();
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
                navigate('/sign-in');
            }
        }
        setDashboardError(null);
    }, [dashboardError]);
    return (
        <div className='row'>
            {isLoading ? (
                <div className='col-12'>
                    <div className='card'>
                        <div
                            className='card-body d-flex align-items-center justify-content-center'
                            style={{
                                position: 'fixed',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            }}
                        >
                            <Loader />
                        </div>
                    </div>
                </div>
            ) : (
                <div className='col-xl-12'>
                    <div className='row'>
                        <DashboardCard
                            title='Pending Requests'
                            count={dashboard?.pendingUsers?.length}
                            icon={
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    height='2em'
                                    viewBox='0 0 640 512'
                                >
                                    <path d='M224 0a128 128 0 1 1 0 256A128 128 0 1 1 224 0zM178.3 304h91.4c20.6 0 40.4 3.5 58.8 9.9C323 331 320 349.1 320 368c0 59.5 29.5 112.1 74.8 144H29.7C13.3 512 0 498.7 0 482.3C0 383.8 79.8 304 178.3 304zM352 368a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-80c-8.8 0-16 7.2-16 16v64c0 8.8 7.2 16 16 16h48c8.8 0 16-7.2 16-16s-7.2-16-16-16H512V304c0-8.8-7.2-16-16-16z' />
                                </svg>
                            }
                            chartData={dashboard?.pendingUsers}
                        />

                        <DashboardCard
                            title='Users'
                            count={dashboard?.totalUsers?.length}
                            icon={
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    height='2em'
                                    viewBox='0 0 640 512'
                                >
                                    <path d='M144 0a80 80 0 1 1 0 160A80 80 0 1 1 144 0zM512 0a80 80 0 1 1 0 160A80 80 0 1 1 512 0zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM224 224a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z' />
                                </svg>
                            }
                            chartData={dashboard?.totalUsers}
                        />
                        <DashboardCard title='Stores' count={'-'} />
                    </div>
                    <div className='row'>
                        <DashboardCard
                            title='Countries'
                            count={dashboard?.totalCountries?.length}
                            icon={
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    height='2em'
                                    viewBox='0 0 512 512'
                                >
                                    <path d='M352 256c0 22.2-1.2 43.6-3.3 64H163.3c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64H348.7c2.2 20.4 3.3 41.8 3.3 64zm28.8-64H503.9c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64H380.8c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32H376.7c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0H167.7c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 20.9 58.2 27 94.7zm-209 0H18.6C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192H131.2c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64H8.1C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6H344.3c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352H135.3zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6H493.4z' />
                                </svg>
                            }
                            chartData={dashboard?.totalCountries}
                        />
                        <DashboardCard
                            title='Cities'
                            count={dashboard?.totalCities?.length}
                            icon={
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    height='2em'
                                    viewBox='0 0 640 512'
                                >
                                    <path d='M480 48c0-26.5-21.5-48-48-48H336c-26.5 0-48 21.5-48 48V96H224V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V96H112V24c0-13.3-10.7-24-24-24S64 10.7 64 24V96H48C21.5 96 0 117.5 0 144v96V464c0 26.5 21.5 48 48 48H304h32 96H592c26.5 0 48-21.5 48-48V240c0-26.5-21.5-48-48-48H480V48zm96 320v32c0 8.8-7.2 16-16 16H528c-8.8 0-16-7.2-16-16V368c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16zM240 416H208c-8.8 0-16-7.2-16-16V368c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16zM128 400c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V368c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32zM560 256c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H528c-8.8 0-16-7.2-16-16V272c0-8.8 7.2-16 16-16h32zM256 176v32c0 8.8-7.2 16-16 16H208c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16zM112 160c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16h32zM256 304c0 8.8-7.2 16-16 16H208c-8.8 0-16-7.2-16-16V272c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32zM112 320H80c-8.8 0-16-7.2-16-16V272c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16zm304-48v32c0 8.8-7.2 16-16 16H368c-8.8 0-16-7.2-16-16V272c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16zM400 64c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H368c-8.8 0-16-7.2-16-16V80c0-8.8 7.2-16 16-16h32zm16 112v32c0 8.8-7.2 16-16 16H368c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16z' />
                                </svg>
                            }
                            chartData={dashboard?.totalCities}
                        />
                        <DashboardCard
                            title='Universities'
                            count={dashboard?.totalUniversities?.length}
                            icon={
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    height='2em'
                                    viewBox='0 0 512 512'
                                >
                                    <path d='M243.4 2.6l-224 96c-14 6-21.8 21-18.7 35.8S16.8 160 32 160v8c0 13.3 10.7 24 24 24H456c13.3 0 24-10.7 24-24v-8c15.2 0 28.3-10.7 31.3-25.6s-4.8-29.9-18.7-35.8l-224-96c-8-3.4-17.2-3.4-25.2 0zM128 224H64V420.3c-.6 .3-1.2 .7-1.8 1.1l-48 32c-11.7 7.8-17 22.4-12.9 35.9S17.9 512 32 512H480c14.1 0 26.5-9.2 30.6-22.7s-1.1-28.1-12.9-35.9l-48-32c-.6-.4-1.2-.7-1.8-1.1V224H384V416H344V224H280V416H232V224H168V416H128V224zM256 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64z' />
                                </svg>
                            }
                            chartData={dashboard?.totalUniversities}
                        />
                    </div>
                    <div className='row'>
                        <DashboardCard
                            title='Residencies'
                            count={dashboard?.totalResidencies?.length}
                            icon={
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    height='2em'
                                    viewBox='0 0 576 512'
                                >
                                    <path d='M543.8 287.6c17 0 32-14 32-32.1c1-9-3-17-11-24L512 185V64c0-17.7-14.3-32-32-32H448c-17.7 0-32 14.3-32 32v36.7L309.5 7c-6-5-14-7-21-7s-15 1-22 8L10 231.5c-7 7-10 15-10 24c0 18 14 32.1 32 32.1h32v69.7c-.1 .9-.1 1.8-.1 2.8V472c0 22.1 17.9 40 40 40h16c1.2 0 2.4-.1 3.6-.2c1.5 .1 3 .2 4.5 .2H160h24c22.1 0 40-17.9 40-40V448 384c0-17.7 14.3-32 32-32h64c17.7 0 32 14.3 32 32v64 24c0 22.1 17.9 40 40 40h24 32.5c1.4 0 2.8 0 4.2-.1c1.1 .1 2.2 .1 3.3 .1h16c22.1 0 40-17.9 40-40V455.8c.3-2.6 .5-5.3 .5-8.1l-.7-160.2h32z' />
                                </svg>
                            }
                            chartData={dashboard?.totalResidencies}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Home;
