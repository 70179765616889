import React, { useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import PageTitle from '../../layouts/PageTitle';
import ProfileEdit from './ProfileEdit';
import ChangePassword from '../Change Password/ChangePassword';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Profile = () => {
    const oConnectedUser = useSelector((state) => state.connectedUser);

    const navigate = useNavigate();
    const [activeKey, setActiveKey] = useState('My Profile');
    const handleNavItemClick = (eventKey) => {
        setActiveKey(eventKey);
    };
    return (
        <>
            <PageTitle activeMenu={`${activeKey}`} motherMenu='Profile' />

            <div className='tabs-wrapper'>
                <Tab.Container
                    defaultActiveKey='My Profile'
                    activeKey={activeKey}
                    onSelect={handleNavItemClick}
                >
                    <div className='card'>
                        <div className='card-header d-block d-sm-flex border-0 flex-wrap transactions-tab'>
                            <div className='card-tabs mt-3 mt-sm-0 mb-3 '>
                                <Nav as='ul' className='nav nav-tabs'>
                                    <Nav.Item as='li' className='nav-item'>
                                        <Nav.Link
                                            eventKey='My Profile'
                                            onClick={() => {
                                                navigate(
                                                    '/admin-profile/profile'
                                                );
                                            }}
                                        >
                                            My Profile
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item as='li' className='nav-item'>
                                        <Nav.Link
                                            eventKey='Change Password'
                                            onClick={() => {
                                                navigate(
                                                    '/admin-profile/change-password'
                                                );
                                            }}
                                        >
                                            Change Password
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                        </div>
                        <Tab.Content className='card-body p-0'>
                            <Tab.Pane className='tab-pane' eventKey={activeKey}>
                                {activeKey === 'My Profile' ? (
                                    <ProfileEdit profile={oConnectedUser} />
                                ) : (
                                    <ChangePassword />
                                )}
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                </Tab.Container>
            </div>
        </>
    );
};

export default Profile;
