import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DialogaeDatatable from './DialogaeDatatable';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
    addDialogaeData,
    deleteDialogaeData,
    editDialogaeData,
    dialogaeList,
} from '../../../api/adapters/dialogae';
import { notify } from '../../../utils/helpers/notification.helpers';
import { setConnectedUser } from '../../../actions/connected-user';
import AddDialogaeModal from './AddDialogaeModal';
import { handleApiError } from '../../../utils/helpers/common.helpers';
import EditDialogaeModal from './EditDialogaeModal';
import moment from 'moment';

const DialogaePage = () => {
    const [showDialogaeModal, setShowDialogaeModal] = useState(false);
    const [showEditDialogaeModal, setShowEditDialogaeModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState({});
    const [spinListError, setSpinListError] = useState(null);

    const [dialogaeData, setDialogaeData] = useState({
        dDate: '',
        dStartTime: '',
        dEndTime: '',
        id: '',
    });

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const pageSize = 5;
    const [queryData, setQueryData] = useState({
        start: 0,
        length: pageSize,
        order: [{ dir: 'des', column: 0 }], // Sort by the second column (status) in ascending order
        columns: [{ data: 'dDate' }],
        search: '',
    });

    const {
        data: viewSpinList,
        isLoading: isLoadingSpinList,
        refetch,
        error: queryError,
    } = useQuery({
        queryKey: ['view-spin-list', queryData],
        queryFn: () => dialogaeList(queryData).then((res) => res.data),
        retry: false,
    });

    useEffect(() => {
        setSpinListError(queryError);
    }, [queryError]);

    useEffect(() => {
        if (spinListError) {
            notify('error', spinListError.message);

            if (spinListError.status === 401) {
                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
        }
        setSpinListError(null);
    }, [spinListError]);

    const { mutate: mutateAddDialogae, isLoading: isMutating } = useMutation({
        mutationFn: (data) =>
            addDialogaeData(
                data,
                Intl.DateTimeFormat().resolvedOptions().timeZone
            ),
        onSuccess: (data) => {
            notify('success', data.message);
            refetch();
            setShowDialogaeModal(false);
            setErrorMessage('');
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const { mutate: mutateDeleteDialogae } = useMutation({
        mutationFn: (id) => deleteDialogaeData(id),
        onSuccess: (data) => {
            notify('success', data.message);
            refetch();
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const { mutate: mutateEditDialogae, isLoading } = useMutation({
        mutationFn: (data) =>
            editDialogaeData(
                data,
                Intl.DateTimeFormat().resolvedOptions().timeZone
            ),
        onSuccess: (data) => {
            notify('success', data.message);
            refetch();
            setShowEditDialogaeModal(false);
            setErrorMessage('');
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const handleEditDate = (e) => {
        let value = e.target.value;

        setDialogaeData((prevData) => ({ ...prevData, dDate: value }));
    };

    const handleStartDate = (e) => {
        let value = moment(e?.$d).format('HH:mm');
        setDialogaeData((prevData) => ({ ...prevData, dStartTime: value }));
    };

    const handleEndDate = (e) => {
        const value = moment(e?.$d).format('HH:mm');

        setDialogaeData((prevData) => ({ ...prevData, dEndTime: value }));
    };

    return (
        <>
            <div className='col-12'>
                <DialogaeDatatable
                    categoryData={viewSpinList?.data}
                    mutateDelete={mutateDeleteDialogae}
                    setQueryData={setQueryData}
                    queryData={queryData}
                    viewSpinList={viewSpinList}
                    pageSize={pageSize}
                    setSpinData={setDialogaeData}
                    show={showDialogaeModal}
                    close={() => setShowDialogaeModal(false)}
                    setShowSpinModal={setShowDialogaeModal}
                    setShowEditSpinModal={setShowEditDialogaeModal}
                    isLoading={isLoadingSpinList}
                />

                <EditDialogaeModal
                    showEdit={showEditDialogaeModal}
                    close={() => {
                        setErrorMessage('');
                        setShowEditDialogaeModal(false);
                    }}
                    value={dialogaeData}
                    handleEditDate={handleEditDate}
                    handleStartDate={handleStartDate}
                    handleEndDate={handleEndDate}
                    mutateEdit={mutateEditDialogae}
                    setErrorMessage={setErrorMessage}
                    errorMessage={errorMessage}
                    isLoading={isLoading}
                />
                <AddDialogaeModal
                    mutate={mutateAddDialogae}
                    show={showDialogaeModal}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    isMutating={isMutating}
                    setShowDialogaeModal={setShowDialogaeModal}
                />
            </div>
        </>
    );
};

export default DialogaePage;
