import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import logo from '../../../assets/images/logo-symbol.png';
import { notify } from '../../../utils/helpers/notification.helpers';

const RewardModal = (props) => {
    const {
        mutate,
        handleChange,
        handleDescription,
        reward,
        setAddReward,
        setErrorMessage,
        errorMessage,
        isLoading,
    } = props;

    const [image, setImage] = useState();

    const allowedPopUpFileTypes = ['image/png', 'image/jpeg'];
    let errorsObj = {};

    function rewardValidation() {
        const fieldsToValidate = [
            {
                field: 'image',
                label: 'image',
                validation: (image) => {
                    if (!image) {
                        return 'Image is required';
                    }
                },
            },
            {
                field: 'nMinutes',
                label: 'nMinutes',
                validation: (value) =>
                    value === undefined ||
                    (typeof value === 'string' && !value.trim())
                        ? 'Reward minutes required'
                        : '',
            },
            {
                field: 'sDescription',
                label: 'description',
                validation: (value) =>
                    !value || !value?.trim()
                        ? 'Reward description required'
                        : '',
            },
        ];

        let hasError = false;

        fieldsToValidate.forEach(({ field, validation }) => {
            const errorValue =
                field === 'image'
                    ? image
                    : field === 'nMinutes'
                    ? reward?.nMinutes
                    : field === 'sDescription'
                    ? reward?.sDescription
                    : '';

            // const errorMessage = validation(errorValue);
            // errorsObj[field] = errorMessage;

            // if (errorMessage) hasError = true;
            if (field === 'sTitle' && image) {
                errorsObj[field] = '';
            } else {
                const errorMessage = validation(errorValue);
                errorsObj[field] = errorMessage;
                if (errorMessage) hasError = true;
            }
        });

        return hasError;
    }

    const addSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();

        let isError = rewardValidation();

        if (isError) {
            return setErrorMessage(errorsObj);
        } else {
            setErrorMessage(errorsObj);
        }

        formData.append('uploadfile', image);
        formData.append('nMinutes', reward?.nMinutes);
        formData.append('sDescription', reward?.sDescription);

        mutate(formData);
        setImage('');
    };

    const addImage = async (e) => {
        const file = e.target.files[0];
        if (file) {
            if (allowedPopUpFileTypes.includes(file.type)) {
                setImage(file);
            } else {
                notify(
                    'error',
                    'Invalid file type. Only PNG files are allowed.'
                );
            }
        }
    };

    const handleClose = async (e) => {
        setErrorMessage(errorsObj);
        setImage('');
        setAddReward('');
        props?.close();
    };
    return (
        <>
            <Modal className='fade' show={props.show}>
                <Modal.Header>
                    <Modal.Title>Add Reward</Modal.Title>
                    <Button
                        variant=''
                        className='btn-close'
                        onClick={handleClose}
                    ></Button>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group
                            className='mb-3'
                            controlId='exampleForm.ControlInput1'
                        >
                            <>
                                <div>
                                    <Form.Label>
                                        <h4
                                            style={{
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Add Reward Image
                                        </h4>
                                    </Form.Label>

                                    <div
                                        className='profile-dp'
                                        style={{ textAlign: 'center' }}
                                    >
                                        {image ? (
                                            <img
                                                src={URL.createObjectURL(image)}
                                                alt=''
                                            />
                                        ) : (
                                            <div className='dp'>
                                                <img src={logo} alt='' />
                                            </div>
                                        )}
                                        <input
                                            className='upload-input '
                                            type='file'
                                            onChange={(e) => addImage(e)}
                                            style={{
                                                height: '50%',
                                            }}
                                        />
                                    </div>
                                    {errorMessage.image && (
                                        <div className='text-danger fs-12'>
                                            {errorMessage.image}
                                        </div>
                                    )}
                                </div>

                                <br />
                                <div>
                                    <>
                                        <Form.Label>
                                            <h4 style={{ fontWeight: 'bold' }}>
                                                Minutes
                                            </h4>
                                        </Form.Label>
                                        <Form.Control
                                            type='number'
                                            name='hours'
                                            value={reward.hours}
                                            placeholder={'Add Reward minutes'}
                                            min={0}
                                            onChange={handleChange}
                                            autoComplete='off'
                                        />
                                        {errorMessage.nMinutes && (
                                            <div className='text-danger fs-12'>
                                                {errorMessage.nMinutes}
                                            </div>
                                        )}
                                        <br />
                                        <Form.Label>
                                            <h4
                                                style={{
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Reward Description
                                            </h4>
                                        </Form.Label>
                                        <Form.Control
                                            type='text'
                                            placeholder={
                                                'Add Reward description'
                                            }
                                            onChange={handleDescription}
                                            autoComplete='off'
                                        />
                                        {errorMessage.sDescription && (
                                            <div className='text-danger fs-12'>
                                                {errorMessage.sDescription}
                                            </div>
                                        )}
                                    </>
                                </div>
                            </>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='danger light' onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        variant='primary'
                        onClick={addSubmit}
                        disabled={isLoading}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default RewardModal;
