import apiClient from '../client/api-client';

export const login = async (data) => {
    const url = 'admin/auth/login';
    return apiClient({
        url,
        data,
        method: 'POST',
    });
};

export const logout = async () => {
    const url = 'admin/auth/logout';
    return apiClient({
        url,
        method: 'POST',
    });
};

export const forgotPassword = async (data) => {
    const url = 'admin/auth/password/reset';
    return apiClient({
        url,
        data,
        method: 'POST',
    });
};

export const resetPassword = async (token, data) => {
    const url = `admin/auth/password/${token}/reset`;
    return apiClient({
        url,
        data,
        method: 'POST',
    });
};

export const verifyResetPasswordToken = async (token) => {
    const url = `admin/auth/password/${token}/reset`;

    return apiClient({
        url,
        method: 'GET',
    });
};
