import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { addFAQ, deleteFAQ, editFAQ, faqs } from '../../../api/adapters/faq';
import { notify } from '../../../utils/helpers/notification.helpers';
import { handleApiError } from '../../../utils/helpers/common.helpers';
import FaqDatatable from './FaqDatatable';
import AddModal from '../../pages/AddModal/AddModal';
import EditModal from '../../pages/AddModal/EditModal';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setConnectedUser } from '../../../actions/connected-user';

const FAQs = () => {
    const [showLogin, setShowLogin] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editFaq, setEditFaq] = useState({ Question: '', Answer: '' });

    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');

    const [errorMessage, setErrorMessage] = useState({});

    const [faqsError, setFaqsError] = useState(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const pageSize = 5;
    const [queryData, setQueryData] = useState({
        start: 0,
        length: pageSize,
        order: [{ dir: 'asc', column: 0 }],
        columns: [{ data: 'dCreatedAt' }],
        search: '',
    });

    const {
        data: viewFaqList,
        isLoading: isLoadingFaqList,
        refetch,
        error: queryError,
    } = useQuery({
        queryKey: ['viewFaqList', queryData],
        queryFn: () => faqs(queryData).then((res) => res.data),
        retry: false,
    });

    useEffect(() => {
        setFaqsError(queryError);
    }, [queryError]);

    useEffect(() => {
        if (faqsError) {
            notify('error', faqsError.message);

            if (faqsError.status === 401) {
                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
        }
        setFaqsError(null);
    }, [faqsError]);

    const handleInput = (e) => {
        let value = e.target.value;

        setQuestion(value);
    };

    const handleSearch = (e) => {
        let value = e.target.value;
        setQueryData((prevData) => ({
            ...prevData,
            search: value,
        }));
    };

    const handleAnswerInput = (e) => {
        let value = e.target.value;

        setAnswer(value);
    };

    const { mutate: mutateAddFaq, isMutating } = useMutation({
        mutationFn: (data) => addFAQ(data),
        onSuccess: (data) => {
            notify('success', data.message);
            refetch();
            setShowLogin(false);
            setAnswer('');
            setQuestion('');
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const { mutate: mutateDeleteFaq } = useMutation({
        mutationFn: (id) => deleteFAQ(id),
        onSuccess: (data) => {
            notify('success', data.message);
            refetch();
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const { mutate: mutateEditFaq, isLoading } = useMutation({
        mutationFn: (data) => editFAQ(data),
        onSuccess: (data) => {
            notify('success', data.message);

            refetch();
            setShowEditModal(false);
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const handleEditQuestionFaq = (e) => {
        let value = e.target.value;

        setEditFaq((prevData) => ({
            ...prevData,
            Question: value,
        }));
    };
    const handleEditAnswerFaq = (e) => {
        let value = e.target.value;

        setEditFaq((prevData) => ({
            ...prevData,
            Answer: value,
        }));
    };

    const handleClose = async (e) => {
        setErrorMessage({});
        setAnswer('');
        setQuestion('');
        setShowLogin(false);
    };
    return (
        <>
            <div className='col-12'>
                <FaqDatatable
                    categoryData={viewFaqList?.data}
                    handleInput={handleSearch}
                    handleChange={handleInput}
                    mutateDelete={mutateDeleteFaq}
                    setQueryData={setQueryData}
                    queryData={queryData}
                    viewFaqList={viewFaqList}
                    pageSize={pageSize}
                    setShowEditModal={setShowEditModal}
                    setEditFaq={setEditFaq}
                    show={showLogin}
                    close={() => setShowLogin(false)}
                    setShowLogin={setShowLogin}
                    isLoading={isLoadingFaqList}
                />

                <AddModal
                    title={'Add FAQ'}
                    label={'Add Question'}
                    placeholder='Enter question'
                    label2={'Add Answer'}
                    placeholder2={'Enter answer'}
                    mutate={mutateAddFaq}
                    show={showLogin}
                    handleChange={handleInput}
                    handleAnswerInput={handleAnswerInput}
                    question={question}
                    answer={answer}
                    setErrorMessage={setErrorMessage}
                    backendErrorMessage={errorMessage}
                    isMutating={isMutating}
                    setAnswer={setAnswer}
                    setQuestion={setQuestion}
                    handleClose={handleClose}
                />

                <EditModal
                    showEdit={showEditModal}
                    close={() => {
                        setShowEditModal(false);
                    }}
                    editFaq={editFaq}
                    title={'Edit FAQ'}
                    label='Question'
                    handleEditFaq={handleEditQuestionFaq}
                    handleEditAnswerFaq={handleEditAnswerFaq}
                    mutateEdit={mutateEditFaq}
                    label2={'Answer'}
                    setErrorMessage={setErrorMessage}
                    backendErrorMessage={errorMessage}
                    isLoading={isLoading}
                />
            </div>
        </>
    );
};

export default FAQs;
