import apiClient from '../client/api-client';

export const cityList = async (data) => {
    const url = 'admin/city/list';
    return apiClient({
        url,
        data,
        method: 'POST',
    });
};

export const addCity = async (data) => {
    const url = 'admin/city/add';
    return apiClient({
        url,
        data,
        method: 'POST',
    });
};

export const deleteCity = async (id) => {
    const url = `admin/city/${id}/delete`;

    return apiClient({
        url,
        method: 'DELETE',
    });
};

export const toggleCityStatus = async (id) => {
    const url = `admin/city/${id}/status/update`;
    return apiClient({
        url,
        method: 'PATCH',
    });
};

export const editCity = async (data) => {
    const url = `admin/city/${data?.id}/edit`;
    return apiClient({
        url,
        data: { sTitle: data?.sTitle },
        method: 'PATCH',
    });
};

export const getCountries = async () => {
    const url = 'user/country/list';

    return apiClient({
        url,
        method: 'GET',
    });
};
