import React, { useMemo, useState } from 'react';
import { POP_UP_COLUMN } from '../../../constants/datatable-column';
import { Button, Form, InputGroup, OverlayTrigger } from 'react-bootstrap';
import Loader from '../../pages/Loader/Loader';
import { renderTooltip } from '../../../utils/helpers/common.helpers';
import Pagination from '../../pages/Datatable/Pagination.jsx';
import { useSortBy, useTable } from 'react-table';

const PopUpDatatable = (props) => {
    const {
        categoryData,
        handleInput,
        mutateDelete,
        mutateToggle,
        setQueryData,
        queryData,
        viewPopUpList,
        pageSize,
        setShowEditModal,
        setCategoryName,
        setShowLogin,

        isLoading,
    } = props;

    const [currentPage, setCurrentPage] = useState(
        queryData?.start / pageSize + 1
    );

    const totalPages = Math.ceil(viewPopUpList?.recordsTotal / pageSize);

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages && !isLoading) {
            setCurrentPage(newPage);
            setQueryData((prev) => ({
                ...prev,
                start: (newPage - 1) * pageSize,
            }));
        } else if (newPage === totalPages + 1) {
            // If the new page is the next page after the last, set currentPage to totalPages
            setCurrentPage(totalPages);
        }
    };

    const addModal = () => {
        setShowLogin(true);
    };

    const handleToggle = (toggle) => {
        mutateToggle(toggle);
    };
    const handleDelete = (id) => {
        mutateDelete(id);
    };
    const editCategories = (id, sPopUpText) => {
        setShowEditModal(true);
        setCategoryName({ sTitle: sPopUpText, id: id });
    };

    const columns = useMemo(() => POP_UP_COLUMN);
    const data = useMemo(() => categoryData || [], [categoryData]);

    const tableInstance = useTable({ columns, data }, useSortBy);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        prepareRow,
    } = tableInstance;

    return (
        <div className='col-12'>
            <div className='card'>
                <div className='card-header'>
                    <h4 className='card-title'>
                        Video-call Boosters Management
                    </h4>
                    <div className='d-flex'>
                        <div className='mx-3'>
                            <InputGroup>
                                <>
                                    <InputGroup.Text>
                                        <i className='fa fa-search'></i>
                                    </InputGroup.Text>
                                    <Form.Control
                                        aria-label='With textarea'
                                        style={{
                                            position: 'relative',
                                            zIndex: 0,
                                        }}
                                        placeholder='Search by preference item'
                                        value={queryData?.search}
                                        onChange={(e) => handleInput(e)}
                                    />
                                </>
                            </InputGroup>
                        </div>
                        <Button variant='primary' onClick={addModal}>
                            Add Pop-Up
                        </Button>
                    </div>
                </div>
                <div className='card-body'>
                    <div className='table-responsive'>
                        <div
                            id='example_wrapper'
                            className='dataTables_wrapper'
                        >
                            {/* <table
                                id='example'
                                className='display w-100 dataTable'
                            >
                                <thead>
                                    <tr role='row'>
                                        {POP_UP_COLUMN.map((d, i) => (
                                            <th key={i}>{d}</th>
                                        ))}
                                    </tr>
                                </thead>
                                {isLoading ? (
                                    <div
                                        className='card-body d-flex align-items-center justify-content-center'
                                        style={{
                                            position: 'relative',
                                            top: '50%',
                                            left: '100%',
                                        }}
                                    >
                                        <Loader />
                                    </div>
                                ) : (
                                    <tbody>
                                        {categoryData?.map((item, i) => (
                                            <tr
                                                className='odd'
                                                role='row'
                                                key={i}
                                            >
                                                <td className='sorting_1'>
                                                    {
                                                        item?.iInterestItem
                                                            ?.sTitle
                                                    }
                                                </td>
                                                <td>{item?.sPopUpText}</td>

                                                <td>
                                                    <Form.Check
                                                        type='switch'
                                                        id='toggle-switch'
                                                        label=''
                                                        checked={
                                                            item.bIsActive ===
                                                            true
                                                        }
                                                        onClick={() =>
                                                            handleToggle(
                                                                item?._id
                                                            )
                                                        }
                                                    />
                                                </td>
                                                <td>
                                                    <div className='d-flex justify-content-center gap-3 col-4'>
                                                        <OverlayTrigger
                                                            placement='bottom'
                                                            delay={{
                                                                show: 250,
                                                                hide: 400,
                                                            }}
                                                            overlay={renderTooltip(
                                                                'Edit'
                                                            )}
                                                        >
                                                            <button
                                                                className='rounded btn btn-light text-primary btn-xs'
                                                                title='Edit'
                                                                onClick={() =>
                                                                    editCategories(
                                                                        item?._id,
                                                                        item?.sPopUpText
                                                                    )
                                                                }
                                                            >
                                                                <i
                                                                    className='fa fa-edit'
                                                                    aria-hidden='true'
                                                                />
                                                            </button>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                            placement='bottom'
                                                            delay={{
                                                                show: 250,
                                                                hide: 400,
                                                            }}
                                                            overlay={renderTooltip(
                                                                'Delete'
                                                            )}
                                                        >
                                                            <button
                                                                className='rounded btn btn-light text-danger btn-xs'
                                                                onClick={() =>
                                                                    handleDelete(
                                                                        item?._id
                                                                    )
                                                                }
                                                            >
                                                                <i
                                                                    className='fa fa-trash'
                                                                    aria-hidden='true'
                                                                />
                                                            </button>
                                                        </OverlayTrigger>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                )}
                            </table> */}

                            <table
                                {...getTableProps()}
                                className='table dataTable display'
                            >
                                <thead>
                                    {headerGroups.map((headerGroup) => (
                                        <tr
                                            {...headerGroup.getHeaderGroupProps()}
                                        >
                                            {headerGroup.headers.map(
                                                (column) => (
                                                    <th
                                                        {...column.getHeaderProps(
                                                            column.getSortByToggleProps()
                                                        )}
                                                    >
                                                        {column.render(
                                                            'Header'
                                                        )}
                                                        <span className='ml-1'>
                                                            {column.isSorted ? (
                                                                column.isSortedDesc ? (
                                                                    <i
                                                                        className='fa fa-arrow-down ms-2 fs-14'
                                                                        style={{
                                                                            opacity:
                                                                                '0.7',
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <i
                                                                        className='fa fa-arrow-up ms-2 fs-14'
                                                                        style={{
                                                                            opacity:
                                                                                '0.7',
                                                                        }}
                                                                    />
                                                                )
                                                            ) : (
                                                                <i
                                                                    className='fa fa-sort ms-2 fs-14'
                                                                    style={{
                                                                        opacity:
                                                                            '0.3',
                                                                    }}
                                                                />
                                                            )}
                                                        </span>
                                                    </th>
                                                )
                                            )}
                                        </tr>
                                    ))}
                                </thead>
                                {/* <tbody {...getTableBodyProps()}>
                                    {rows.map((row) => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map((cell) => {
                                                    return (
                                                        <td
                                                            {...cell.getCellProps()}
                                                        >
                                                            {' '}
                                                            {cell.render(
                                                                'Cell'
                                                            )}{' '}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody> */}
                                {isLoading ? (
                                    <div
                                        className='card-body d-flex align-items-center justify-content-center'
                                        style={{
                                            position: 'relative',
                                            top: '50%',
                                            left: '100%',
                                        }}
                                    >
                                        <Loader />
                                    </div>
                                ) : (
                                    <tbody {...getTableBodyProps()}>
                                        {rows.map((row) => {
                                            prepareRow(row);
                                            const item = row.original;
                                            return (
                                                <tr
                                                    {...row.getRowProps()}
                                                    className='odd'
                                                    role='row'
                                                >
                                                    {row.cells.map((cell) => (
                                                        <td
                                                            {...cell.getCellProps()}
                                                        >
                                                            {cell.column.id ===
                                                            'id' ? (
                                                                <>
                                                                    {
                                                                        item
                                                                            ?.iInterestItem
                                                                            ?.sTitle
                                                                    }
                                                                </>
                                                            ) : cell.column
                                                                  .id ===
                                                              'sPopUpText' ? (
                                                                <>
                                                                    {
                                                                        item?.sPopUpText
                                                                    }
                                                                </>
                                                            ) : cell.column
                                                                  .id ===
                                                              'bIsActive' ? (
                                                                <Form.Check
                                                                    type='switch'
                                                                    id='toggle-switch'
                                                                    label=''
                                                                    checked={
                                                                        item.bIsActive ===
                                                                        true
                                                                    }
                                                                    onClick={() =>
                                                                        handleToggle(
                                                                            item?._id
                                                                        )
                                                                    }
                                                                />
                                                            ) : cell.column
                                                                  .id ===
                                                              'Actions' ? (
                                                                <div className='d-flex justify-content-center gap-3 col-4'>
                                                                    <OverlayTrigger
                                                                        placement='bottom'
                                                                        delay={{
                                                                            show: 250,
                                                                            hide: 400,
                                                                        }}
                                                                        overlay={renderTooltip(
                                                                            'Edit'
                                                                        )}
                                                                    >
                                                                        <button
                                                                            className='rounded btn btn-light text-primary btn-xs'
                                                                            title='Edit'
                                                                            onClick={() =>
                                                                                editCategories(
                                                                                    item?._id,
                                                                                    item?.sPopUpText
                                                                                )
                                                                            }
                                                                        >
                                                                            <i
                                                                                className='fa fa-edit'
                                                                                aria-hidden='true'
                                                                            />
                                                                        </button>
                                                                    </OverlayTrigger>
                                                                    <OverlayTrigger
                                                                        placement='bottom'
                                                                        delay={{
                                                                            show: 250,
                                                                            hide: 400,
                                                                        }}
                                                                        overlay={renderTooltip(
                                                                            'Delete'
                                                                        )}
                                                                    >
                                                                        <button
                                                                            className='rounded btn btn-light text-danger btn-xs'
                                                                            onClick={() =>
                                                                                handleDelete(
                                                                                    item?._id
                                                                                )
                                                                            }
                                                                        >
                                                                            <i
                                                                                className='fa fa-trash'
                                                                                aria-hidden='true'
                                                                            />
                                                                        </button>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    {cell.render(
                                                                        'Cell'
                                                                    )}
                                                                </>
                                                            )}
                                                        </td>
                                                    ))}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                )}

                                {/* This is only for footer if u require */}
                            </table>
                            <hr />

                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                handlePageChange={handlePageChange}
                                viewGiftCodeslist={viewPopUpList}
                                queryData={queryData}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PopUpDatatable;
