import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    addGiftCode,
    deleteGiftCode,
    listGiftCode,
} from '../../../api/adapters/rewards';
import Loader from '../../pages/Loader/Loader';
import {
    CLAIMED_REWARD_COLUMN,
    UNCLAIMED_REWARD_COLUMN,
} from '../../../constants/datatable-column';
import {
    Button,
    Form,
    InputGroup,
    Nav,
    OverlayTrigger,
    Tab,
} from 'react-bootstrap';
import PageTitle from '../../layouts/PageTitle';
import moment from 'moment';
import {
    handleApiError,
    renderTooltip,
} from '../../../utils/helpers/common.helpers';
import Pagination from '../../pages/Datatable/Pagination';
import AddGiftCodeModal from './AddGiftCodeModal';
import { notify } from '../../../utils/helpers/notification.helpers';
import { setConnectedUser } from '../../../actions/connected-user';
import { useDispatch } from 'react-redux';

const GiftCodeDatatable = () => {
    const { id } = useParams();

    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [giftCodeError, setGiftCodeError] = useState(null);

    const [data, setData] = useState({
        num: '',
        len: '',
        dExpiryDate: '',
        sPrefix: '',
    });

    const [activeKey, setActiveKey] = useState('Claimed'); // default to 'notClaimed'

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const pageSize = 5;
    const [queryData, setQueryData] = useState({
        start: 0,
        length: pageSize,
        order: [{ dir: 'asc', column: 0 }],
        columns: [{ data: 'dExpiryDate' }],
        search: '',
        isClaimed: true,
    });

    const [currentPage, setCurrentPage] = useState(
        queryData?.start / pageSize + 1
    );

    const {
        data: viewGiftCodeslist,
        isLoading: isLoadingGiftCodesList,
        error: queryError,
        refetch,
    } = useQuery({
        queryKey: ['viewGiftCodeslist', queryData],
        queryFn: () => listGiftCode(id, queryData).then((res) => res.data),
        retry: false,
    });

    useEffect(() => {
        setGiftCodeError(queryError);
    }, [queryError]);

    useEffect(() => {
        if (giftCodeError) {
            notify('error', giftCodeError.message);

            if (giftCodeError.status === 401) {
                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
        }
        setGiftCodeError(null);
    }, [giftCodeError]);

    const { mutate: mutateAddGiftCode, isLoading: addGiftCodeLoader } =
        useMutation({
            mutationFn: (data) => addGiftCode(id, data),
            onSuccess: (data) => {
                notify('success', data.message);
                refetch();
                setShowModal(false);
                setErrorMessage('');
                setData({
                    num: '',
                    len: '',
                    dExpiryDate: '',
                    sPrefix: '',
                });
            },
            onError: (error) => {
                if (error?.status === 401) {
                    notify('error', error.message);

                    navigate('/sign-in');
                    dispatch(
                        setConnectedUser({
                            sEmail: '',
                            sProfilePicUrl: '',
                            sUserName: '',
                        })
                    );
                }
                handleApiError(error, setErrorMessage);
            },
        });

    const { mutate: mutateDeleteGiftCode } = useMutation({
        mutationFn: (id) => deleteGiftCode(id),
        onSuccess: (data) => {
            notify('success', data.message);
            refetch();
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const handleNavItemClick = (eventKey) => {
        setActiveKey(eventKey);
        setQueryData((prev) => ({
            ...prev,
            start: 0,
            isClaimed: eventKey === 'Claimed' ? true : false,
        }));
    };

    const totalPages = Math.ceil(viewGiftCodeslist?.recordsTotal / pageSize);

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages && !isLoadingGiftCodesList) {
            setCurrentPage(newPage);
            setQueryData((prev) => ({
                ...prev,
                start: (newPage - 1) * pageSize,
            }));
        } else if (newPage === totalPages + 1) {
            // If the new page is the next page after the last, set currentPage to totalPages
            setCurrentPage(totalPages);
        }
    };

    const handleSearch = (e) => {
        setQueryData((prev) => ({
            ...prev,
            start: 0,
            search: e.target.value,
        }));
    };

    const handleAddModal = () => {
        setShowModal(true);
    };

    const handleDelete = (id) => {
        mutateDeleteGiftCode(id);
    };

    const handleClose = () => {
        setShowModal(false);
        setErrorMessage('');
        setData({
            num: '',
            len: '',
            dExpiryDate: '',
            sPrefix: '',
        });
    };
    const handleInput = (e) => {
        const { value, name } = e.target;

        setData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    return (
        <div className='col-12'>
            <PageTitle
                activeMenu='Rewards'
                motherMenu='Category'
                pageContent='Gift Codes'
            />
            <div className='card'>
                <div className='card-header'>
                    <Tab.Container
                        defaultActiveKey='Claimed'
                        activeKey={activeKey}
                        onSelect={handleNavItemClick}
                    >
                        <div className='card'>
                            <div className='card-header d-block d-sm-flex border-0 flex-wrap transactions-tab'>
                                <div className='card-tabs mt-3 mt-sm-0 mb-3 '>
                                    <Nav as='ul' className='nav nav-tabs'>
                                        <Nav.Item as='li' className='nav-item'>
                                            <Nav.Link eventKey='Claimed'>
                                                Claimed
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as='li' className='nav-item'>
                                            <Nav.Link eventKey='Not-Claimed'>
                                                Not Claimed
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                            </div>
                        </div>
                    </Tab.Container>

                    {viewGiftCodeslist?.length === 0 ? null : (
                        <div className='d-flex'>
                            <div className='mx-3'>
                                <InputGroup>
                                    <>
                                        <InputGroup.Text>
                                            <i className='fa fa-search'></i>
                                        </InputGroup.Text>
                                        <Form.Control
                                            aria-label='With textarea'
                                            style={{
                                                position: 'relative',
                                                zIndex: 0,
                                            }}
                                            placeholder='Search by reward description'
                                            value={queryData?.search}
                                            onChange={(e) => handleSearch(e)}
                                        />
                                    </>
                                </InputGroup>
                            </div>
                            {activeKey === 'Claimed' ? null : (
                                <Button
                                    variant='primary'
                                    onClick={handleAddModal}
                                >
                                    Generate GiftCode
                                </Button>
                            )}
                        </div>
                    )}
                </div>

                <div className='card-body'>
                    <div className='table-responsive'>
                        <div
                            id='example_wrapper'
                            className='dataTables_wrapper'
                        >
                            {viewGiftCodeslist?.recordsTotal === 0 ? (
                                <div>
                                    {' '}
                                    <h1 className='text-center'>
                                        {' '}
                                        No data Found
                                    </h1>
                                </div>
                            ) : (
                                <>
                                    {' '}
                                    <table
                                        id='example'
                                        className='display w-100 dataTable'
                                    >
                                        <thead>
                                            <tr role='row'>
                                                {activeKey === 'Claimed'
                                                    ? CLAIMED_REWARD_COLUMN.map(
                                                          (d, i) => (
                                                              <th key={i}>
                                                                  {d}
                                                              </th>
                                                          )
                                                      )
                                                    : UNCLAIMED_REWARD_COLUMN.map(
                                                          (d, i) => (
                                                              <th key={i}>
                                                                  {d}
                                                              </th>
                                                          )
                                                      )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoadingGiftCodesList ? (
                                                <div
                                                    className='card-body d-flex align-items-center justify-content-center'
                                                    style={{
                                                        position: 'relative',
                                                        top: '50%',
                                                        left: '100%',
                                                    }}
                                                >
                                                    <Loader />
                                                </div>
                                            ) : (
                                                viewGiftCodeslist?.data?.map(
                                                    (item, i) => (
                                                        <tr
                                                            className='odd'
                                                            role='row'
                                                            key={i}
                                                        >
                                                            {activeKey ===
                                                            'Claimed' ? (
                                                                <td className='sorting_1'>
                                                                    {item?.iUser
                                                                        ?.oName
                                                                        ? (item
                                                                              ?.iUser
                                                                              ?.oName
                                                                              ?.sFirstName ||
                                                                              '') +
                                                                          (item
                                                                              ?.iUser
                                                                              ?.oName
                                                                              ?.sMiddleName
                                                                              ? ' ' +
                                                                                item
                                                                                    .iUser
                                                                                    .oName
                                                                                    .sMiddleName
                                                                              : '') +
                                                                          (item
                                                                              ?.iUser
                                                                              ?.oName
                                                                              ?.sLastName
                                                                              ? ' ' +
                                                                                item
                                                                                    .iUser
                                                                                    .oName
                                                                                    .sLastName
                                                                              : '')
                                                                        : ''}
                                                                </td>
                                                            ) : null}

                                                            {activeKey ===
                                                            'Claimed' ? (
                                                                <td className='sorting_1'>
                                                                    {
                                                                        item?.sDescription
                                                                    }
                                                                </td>
                                                            ) : null}

                                                            {activeKey ===
                                                            'Claimed' ? (
                                                                <td>
                                                                    <img
                                                                        style={{
                                                                            width: '70px',
                                                                            height: '90px',
                                                                            objectFit:
                                                                                'cover',
                                                                            marginRight:
                                                                                '10px', // Add margin for spacing
                                                                        }}
                                                                        src={
                                                                            item?.sRewardPic
                                                                        }
                                                                        alt=''
                                                                    />
                                                                </td>
                                                            ) : null}

                                                            <td>
                                                                {item?.sCode}
                                                            </td>
                                                            {activeKey ===
                                                            'Claimed' ? (
                                                                <td>
                                                                    {moment(
                                                                        item?.dRedeemedAt
                                                                    ).format(
                                                                        'DD-MM-YYYY'
                                                                    )}
                                                                </td>
                                                            ) : null}
                                                            <td>
                                                                {moment(
                                                                    item?.dExpiryDate
                                                                ).format(
                                                                    'DD-MM-YYYY'
                                                                )}
                                                            </td>
                                                            {activeKey ===
                                                            'Not-Claimed' ? (
                                                                <td>
                                                                    <OverlayTrigger
                                                                        placement='bottom'
                                                                        delay={{
                                                                            show: 250,
                                                                            hide: 400,
                                                                        }}
                                                                        overlay={renderTooltip(
                                                                            'Delete'
                                                                        )}
                                                                    >
                                                                        <button
                                                                            className='rounded btn btn-light text-danger btn-xs'
                                                                            onClick={() =>
                                                                                handleDelete(
                                                                                    item?._id
                                                                                )
                                                                            }
                                                                        >
                                                                            <i
                                                                                className='fa fa-trash'
                                                                                aria-hidden='true'
                                                                            />
                                                                        </button>
                                                                    </OverlayTrigger>
                                                                </td>
                                                            ) : null}
                                                        </tr>
                                                    )
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                    <hr />
                                    <Pagination
                                        currentPage={currentPage}
                                        totalPages={totalPages}
                                        handlePageChange={handlePageChange}
                                        viewGiftCodeslist={viewGiftCodeslist}
                                        queryData={queryData}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <AddGiftCodeModal
                show={showModal}
                mutate={mutateAddGiftCode}
                isLoading={addGiftCodeLoader}
                errorMessage={errorMessage}
                data={data}
                handleInput={handleInput}
                handleClose={handleClose}
                addGiftCodeLoader={addGiftCodeLoader}
            />
        </div>
    );
};

export default GiftCodeDatatable;
