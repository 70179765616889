import apiClient from '../client/api-client';

export const profileUpdate = async (data) => {
    const url = 'admin/profile/update';
    return apiClient({
        url,
        data,
        method: 'PATCH',
        headers: {
            'Content-Type': 'multipart/form-data',
            Accept: '*/*',
        },
    });
};

export const viewProfile = async () => {
    const url = 'admin/profile';
    return apiClient({
        url,
        method: 'GET',
    });
};

export const changePassword = async (data) => {
    const url = 'admin/password/update';
    return apiClient({
        url,
        data,
        method: 'PATCH',
    });
};
