import { toast } from 'react-toastify';

export const notify = (type, message) => {
    toast.clearWaitingQueue();
    toast[type](message, {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: true,
        toastId: message,
    });
};
