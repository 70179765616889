import apiClient from '../client/api-client';

export const getFeedbackList = async (data) => {
    const url = '/admin/feedback/list';
    return apiClient({
        url,
        data,
        method: 'POST',
    });
};

export const updateFeedback = async (data) => {
    const url = `admin/feedback/${data.id}/status/update`;
    return apiClient({
        url,
        data,
        method: 'PATCH',
    });
};
