import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { viewProfile } from '../../../api/adapters/profile';
import { useDispatch } from 'react-redux';
import { setConnectedUser } from '../../../actions/connected-user';
import { notify } from '../../../utils/helpers/notification.helpers';
import { useNavigate } from 'react-router-dom';
import { clearCookies } from '../../../utils/helpers/cookies.helpers';

const Headers = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { data: profile, error: userProfileError } = useQuery({
        queryKey: ['profileData'],
        queryFn: () => viewProfile().then((res) => res.data),
    });

    useEffect(() => {
        dispatch(setConnectedUser(profile));
    }, [profile]);

    useEffect(() => {
        if (userProfileError) {
            if (userProfileError.status === 401) {
                notify('error', userProfileError.message);
                clearCookies();
                navigate('/sign-in');
            }
        }
    }, [userProfileError]);

    let path = window.location.pathname.split('/');

    let name = path[path.length - 1].split('-');
    let name1 = path[path.length - 3]?.split('-');

    function isValidObjectId(str) {
        return /^[0-9a-fA-F]{24}$/.test(str);
    }

    var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;

    var filterPage =
        name1?.length >= 3 ? name1?.filter((n, i) => i > 0) : name1;

    var finalName;

    if (filterPage?.includes('cms') || filterName.includes('cms')) {
        finalName = ['CMS Management'];
    } else if (filterName.includes('app')) {
        finalName = filterName.filter((f) => f !== 'app');
    } else if (
        filterName.includes('pending') ||
        filterName.includes('accepted') ||
        filterName.includes('rejected')
    ) {
        finalName = ['User Management'];
    } else if (filterName.includes('id') || filterName.includes('view')) {
        finalName = ['User Details'];
    } else if (filterName.includes('id') || filterName.includes('edit')) {
        finalName = ['Edit User Details'];
    } else if (path.includes('preference') && isValidObjectId(filterName[0])) {
        finalName = ['Item Management'];
    } else {
        finalName = filterName;
    }

    useEffect(() => {}, [finalName]);

    return (
        <div>
            <div className='header border-bottom'>
                <div className='header-content'>
                    <nav className='navbar navbar-expand'>
                        <div className='collapse navbar-collapse justify-content-between'>
                            <div className='header-left'>
                                <div
                                    className='dashboard_bar'
                                    style={{ textTransform: 'capitalize' }}
                                >
                                    {finalName.join(' ').length === 0
                                        ? 'Dashboard'
                                        : finalName.join(' ') ===
                                          'dashboard dark'
                                        ? 'Dashboard'
                                        : finalName.join(' ')}
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    );
};

export default Headers;
