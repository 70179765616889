import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../Loader/Loader';
import { isValidEmail } from '../../../utils/validators/common.validators';
import { notify } from '../../../utils/helpers/notification.helpers';
import { useMutation } from '@tanstack/react-query';
import { forgotPassword } from '../../../api/adapters/auth';
import logo from '../../../assets/images/logo-symbol.png';

import logoWhite from '../../../assets/images/name-logo.png';

const ForgotPassword = () => {
    const navigate = useNavigate();

    const [Email, setUserEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleInput = (e) => {
        setUserEmail(e.target.value);
    };

    async function forgotPass(type, e) {
        e.preventDefault();
        if (!Email) return setErrorMessage('Please enter email');
        else if (!isValidEmail(Email))
            return setErrorMessage('Please enter a valid email');
        else setErrorMessage('');

        mutateForgotPassword({ sEmail: Email });
    }

    const { mutate: mutateForgotPassword, isLoading: isMutating } = useMutation(
        {
            mutationFn: (data) => forgotPassword(data),
            onSuccess: (data) => {
                notify('success', data?.message);
                setTimeout(() => {
                    navigate('/sign-in', { replace: true });
                }, 2000);
            },

            onError: (error) => {
                if (error.status === 422) {
                    setErrorMessage(
                        error.message?.response?.data?.data?.errors?.[0]?.msg
                    );
                } else {
                    notify('error', error.message || 'Something went wrong');
                }
            },
        }
    );

    return (
        <div className=' login-main-page'>
            <div className=' login-wrapper'>
                <div className='login-aside-left'>
                    <span>
                        <img src={logo} alt='' width='100px' />
                        <img src={logoWhite} alt='' className='ms-3' />
                    </span>
                </div>
                <div className='row m-0 justify-content-center h-100 align-items-center login-aside-right'>
                    <div className='authincation-content'>
                        <div className='row no-gutters'>
                            <div className='col-xl-12'>
                                <div className='auth-form'>
                                    <div className='mb-4'>
                                        <h3 className='dz-title mb-1'>
                                            Forgot Password
                                        </h3>
                                    </div>
                                    <form>
                                        <div className='form-group'>
                                            <label className='mb-2'>
                                                <strong>Email</strong>
                                            </label>
                                            <input
                                                type='email'
                                                className='form-control'
                                                onChange={handleInput}
                                                placeholder='Enter Email'
                                                value={Email}
                                            />
                                            {errorMessage && (
                                                <label
                                                    style={{
                                                        color: 'red',
                                                    }}
                                                >
                                                    {errorMessage}
                                                </label>
                                            )}
                                        </div>
                                        <br />
                                        <div className='text-center'>
                                            <button
                                                type='submit'
                                                className='btn btn-primary btn-block'
                                                onClick={(e) =>
                                                    forgotPass('submit', e)
                                                }
                                                disabled={isMutating}
                                            >
                                                {isMutating ? (
                                                    <Loader />
                                                ) : (
                                                    'Submit'
                                                )}
                                            </button>
                                        </div>
                                    </form>
                                    <div className='new-account mt-2'>
                                        <p className=''>
                                            Back to{' '}
                                            <Link
                                                className='text-primary'
                                                to='/'
                                            >
                                                Sign In
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
