import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
    addPopUp,
    deletePopUp,
    editPopUp,
    getItemList,
    popupList,
    togglePopUpStatus,
} from '../../../api/adapters/popUp';
import { notify } from '../../../utils/helpers/notification.helpers';
import { handleApiError } from '../../../utils/helpers/common.helpers';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setConnectedUser } from '../../../actions/connected-user';
import PopUpDatatable from './PopUpDatatable';
import AddModal from '../../pages/AddModal/AddModal';
import EditModal from '../../pages/AddModal/EditModal';

const PopUp = () => {
    const [showLogin, setShowLogin] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState({});
    const [selectedValue, setSelectedValue] = useState('');
    const [popUpText, setPopUpText] = useState('');
    const [categoryName, setCategoryName] = useState({ sTitle: '', id: '' });

    const [popUpError, setPopUpError] = useState(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const pageSize = 5;
    const [queryData, setQueryData] = useState({
        start: 0,
        length: pageSize,
        order: [{ dir: 'asc', column: 0 }],
        columns: [{ data: 'sTitle' }],
        search: '',
    });

    const {
        data: viewPopUpList,
        isLoading: isLoadingPopUpList,
        refetch,
        error: queryError,
    } = useQuery({
        queryKey: ['viewPopUpList', queryData],
        queryFn: () => popupList(queryData).then((res) => res.data),
        retry: false,
    });

    useEffect(() => {
        setPopUpError(queryError);
    }, [queryError]);

    useEffect(() => {
        if (popUpError) {
            notify('error', popUpError.message);

            if (popUpError.status === 401) {
                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
        }
        setPopUpError(null);
    }, [popUpError]);

    const { data: itemList } = useQuery({
        queryKey: ['itemList'],
        queryFn: () => getItemList().then((res) => res.data),
    });

    const handleSelectChange = (e) => {
        let value = e.target.value;
        setSelectedValue((preInquiryData) => ({
            ...preInquiryData,
            id: value,
        }));
    };

    const handleSearch = (e) => {
        let value = e.target.value;

        setQueryData((prevData) => ({
            ...prevData,
            search: value,
        }));
    };

    const handleInput = (e) => {
        let value = e.target.value;

        setPopUpText((prevData) => ({ ...prevData, sTitle: value }));
    };

    const { mutate: mutateAddPopUp, isLoading: isMutating } = useMutation({
        mutationFn: (data) => addPopUp(data),
        onSuccess: (data) => {
            notify('success', data.message);
            refetch();
            setShowLogin(false);
            setSelectedValue('');
            setPopUpText('');
        },

        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const { mutate: mutateDeletePopUp } = useMutation({
        mutationFn: (id) => deletePopUp(id),
        onSuccess: (data) => {
            notify('success', data.message);
            refetch();
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const { mutate: mutateToggleButton } = useMutation({
        mutationFn: (id) => togglePopUpStatus(id),
        onSuccess: (data) => {
            notify('success', data?.message);
            refetch();
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const handleInputCategory = (e) => {
        let value = e.target.value;

        setCategoryName((prevData) => ({
            ...prevData,
            sTitle: value,
        }));
    };

    const { mutate: mutateEditPopUp, isLoading } = useMutation({
        mutationFn: (data) => editPopUp(data),
        onSuccess: (data) => {
            notify('success', data.message);

            refetch();
            setShowEditModal(false);
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const handleClose = () => {
        setErrorMessage({});
        setShowLogin(false);
        setSelectedValue('');
        setPopUpText('');
    };

    return (
        <div className='col-12'>
            <PopUpDatatable
                categoryData={viewPopUpList?.data}
                handleInput={handleSearch}
                mutateDelete={mutateDeletePopUp}
                mutateToggle={mutateToggleButton}
                setQueryData={setQueryData}
                queryData={queryData}
                viewPopUpList={viewPopUpList}
                pageSize={pageSize}
                setShowEditModal={setShowEditModal}
                show={showLogin}
                close={() => setShowLogin(false)}
                setShowLogin={setShowLogin}
                isLoading={isLoadingPopUpList}
                setCategoryName={setCategoryName}
            />

            <AddModal
                title={'Add Pop Up'}
                label={'PopUp Text'}
                mutate={mutateAddPopUp}
                value={popUpText}
                placeholder='Enter pop-up text'
                show={showLogin}
                handleChange={handleInput}
                countrylist={itemList}
                handleSelectChange={handleSelectChange}
                selectedValue={selectedValue}
                addDropdownlabel='Interest Item'
                addDropdownPlaceholder='Select Interest Item'
                setErrorMessage={setErrorMessage}
                backendErrorMessage={errorMessage}
                isMutating={isMutating}
                handleClose={handleClose}
            />

            <EditModal
                showEdit={showEditModal}
                close={() => {
                    setShowEditModal(false);
                }}
                title='Edit Pop Up'
                label='Enter Pop Up Text'
                categoryName={categoryName}
                handleInputCategory={handleInputCategory}
                mutateEdit={mutateEditPopUp}
                setErrorMessage={setErrorMessage}
                backendErrorMessage={errorMessage}
                isLoading={isLoading}
            />
        </div>
    );
};

export default PopUp;
