import apiClient from '../client/api-client';

export const userList = async (data) => {
    const url = 'admin/user/list';
    return apiClient({
        url,
        data,
        method: 'POST',
    });
};

export const userDetails = async (id) => {
    const url = `admin/user/${id}/view`;
    return apiClient({
        url,
        method: 'GET',
    });
};

export const userStatus = async (data) => {
    const url = `admin/user/${data?.id}/status`;
    return apiClient({
        url,
        data: { eProfileStatus: data?.value, sRejectedReason: data?.reason },
        method: 'PATCH',
    });
};

export const deleteUser = async (id) => {
    const url = `admin/user/${id}/delete`;
    return apiClient({
        url,
        method: 'DELETE',
    });
};

export const getUniversities = async (id) => {
    const url = `user/city/${id}/university/list`;

    return apiClient({
        url,
        method: 'GET',
    });
};

export const getResidencies = async (id) => {
    const url = `/user/city/${id}/residency/list`;

    return apiClient({
        url,
        method: 'GET',
    });
};

export const toggleUserStatus = async (data) => {
    const url = `admin/user/${data.id}/status/update`;
    return apiClient({
        url,
        data: { sDisabledReason: data.reason },
        method: 'PATCH',
    });
};

export const getAllInterest = async () => {
    const url = 'user/interest-category/list';

    return apiClient({
        url,
        method: 'GET',
    });
};

export const updateUserDetails = async (data) => {
    const url = `admin/user/${data.id}/profile/update`;

    return apiClient({
        url,
        data: data.data,
        method: 'PATCH',
    });
};
