import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { userDetails } from '../../../api/adapters/userManagement';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import profile from '../../../assets/images/default-profile.png';
import { useDispatch } from 'react-redux';
import { notify } from '../../../utils/helpers/notification.helpers';
import { setConnectedUser } from '../../../actions/connected-user';
import PageTitle from '../../layouts/PageTitle';

const UserDetails = () => {
    const params = useParams();

    const navigate = useNavigate();
    const [checkedIds, setCheckedIds] = useState([]);
    const [titlesArray, setTitlesArray] = useState();

    const [userDetailsError, setUserDetailsError] = useState(null);

    const dispatch = useDispatch();

    const { data: userdetails, error: queryError } = useQuery({
        queryKey: ['user-details', params?.id],
        queryFn: () => userDetails(params?.id).then((res) => res.data),
        retry: false,
    });

    useEffect(() => {
        setUserDetailsError(queryError);
    }, [queryError]);

    useEffect(() => {
        if (userDetailsError) {
            notify('error', userDetailsError.message);

            if (userDetailsError.status === 401) {
                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
        }
        setUserDetailsError(null);
    }, [userDetailsError]);

    useEffect(() => {
        if (userdetails) {
            const newCheckIds = userdetails?.aInterests
                .map((item) => {
                    const { iInterestCategory } = item;
                    return iInterestCategory ? item._id : null;
                })
                .filter((id) => id !== null);

            setCheckedIds(newCheckIds);
            setTitlesArray(
                userdetails?.aInterests
                    ?.map((item) => {
                        return item?.iInterestCategory[0]?.sTitle;
                    })
                    .filter(
                        (sTitle, index, array) =>
                            array.indexOf(sTitle) === index
                    )
            );
        }
    }, [userdetails]);

    const handleCancelClick = () => {
        navigate(-1);
    };

    return (
        <>
            <PageTitle
                activeMenu={`${
                    params?.status === 'pending'
                        ? 'Pending'
                        : params?.status === 'accepted'
                        ? 'Accepted'
                        : 'Rejected'
                } Request`}
                motherMenu='User Management'
                pageContent={`${params?.id}`}
            />
            <div className='col-xl-12'>
                <div className='card'>
                    <div className='card-body pb-3'>
                        <div className='d-flex float-end'>
                            <button
                                type='button'
                                className='btn btn-primary mx-2'
                                style={{ width: '100px' }}
                                onClick={handleCancelClick}
                            >
                                Back
                            </button>
                        </div>
                    </div>
                    <div className='card-body pb-3 transaction-details'>
                        <div className='row'>
                            <div className='col-lg-2'>
                                <div className='row'>
                                    {userdetails?.sProfilePicUrl ? (
                                        <img
                                            src={userdetails?.sProfilePicUrl}
                                            className='rounded img-fluid'
                                            alt=''
                                            width='200px'
                                        />
                                    ) : (
                                        <img
                                            src={profile}
                                            className='rounded'
                                            alt=''
                                            width='200px'
                                        />
                                    )}
                                </div>
                            </div>

                            <div className='col-lg-10'>
                                <div className='row'>
                                    <div className='col d-flex flex-wrap justify-content-between align-items-start'>
                                        <div className='me-3 mb-3 col-lg-3 col-md-4 col-sm-6'>
                                            <h5>First Name</h5>
                                            <h4 className='font-w400'>
                                                {userdetails?.oName?.sFirstName}
                                            </h4>
                                        </div>

                                        <div className='me-3 mb-3 col-lg-3 col-md-4 col-sm-6'>
                                            <h5>Surname 1</h5>
                                            <h4 className='font-w400'>
                                                {userdetails?.oName
                                                    ?.sMiddleName || '-'}
                                            </h4>
                                        </div>

                                        <div className='me-3 mb-3 col-lg-4 col-md-4 col-sm-12'>
                                            <h5>Surname 2</h5>
                                            <h4 className='font-w400'>
                                                {userdetails?.oName
                                                    ?.sLastName || '-'}
                                            </h4>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col d-flex flex-wrap justify-content-between align-items-center'>
                                        <div className='me-3 mb-3 col-lg-3 col-md-4 col-sm-6'>
                                            <h5>Type</h5>
                                            <h4 className='font-w400'>
                                                {userdetails?.eAccountType ===
                                                'S'
                                                    ? 'Student'
                                                    : 'Residence'}
                                            </h4>
                                        </div>
                                        <div className='me-3 mb-3 col-lg-3 col-md-4 col-sm-6'>
                                            <h5>Date of Birth</h5>
                                            <h4 className='font-w400'>
                                                {moment(
                                                    userdetails?.dDob
                                                ).format('L')}
                                            </h4>
                                        </div>

                                        <div className='me-3 mb-3 col-lg-4 col-md-4 col-sm-12'>
                                            <h5>Gender</h5>
                                            <h4 className='font-w400'>
                                                {userdetails?.eGender === 'M'
                                                    ? 'Male'
                                                    : userdetails?.eGender ===
                                                      'F'
                                                    ? 'Female'
                                                    : 'others'}
                                            </h4>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col d-flex flex-wrap justify-content-between align-items-center'>
                                        <div className='me-3 mb-3 col-lg-3 col-md-4 col-sm-6'>
                                            <h5>
                                                {userdetails?.eAccountType ===
                                                'S'
                                                    ? 'University Name'
                                                    : 'Residence Name'}
                                            </h5>
                                            <h4 className='font-w400'>
                                                {userdetails?.eAccountType ===
                                                'S'
                                                    ? userdetails?.iUniversity
                                                          ?.sTitle
                                                    : userdetails?.iResidency
                                                          ?.sTitle}
                                            </h4>
                                        </div>

                                        <div className='me-3 mb-3 col-lg-3 col-md-4 col-sm-6'>
                                            <h5>Country</h5>
                                            <h4 className='font-w400'>
                                                {userdetails?.iCountry?.sTitle}
                                            </h4>
                                        </div>

                                        <div className='me-3 mb-3 col-lg-4 col-md-4 col-sm-12'>
                                            <h5>City</h5>
                                            <h4 className='font-w400'>
                                                {userdetails?.iCity?.sTitle}
                                            </h4>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col d-flex flex-wrap justify-content-between align-items-center'>
                                        <div className='me-3 mb-3 col-lg-3 col-md-4 col-sm-6'>
                                            <h5>Email Id</h5>
                                            <h4 className='font-w400'>
                                                {userdetails?.sEmail}
                                            </h4>
                                        </div>
                                        <div className='me-3 mb-3 col-lg-3 col-md-4 col-sm-6'>
                                            <h5>Bio</h5>
                                            <h4 className='font-w400'>
                                                {userdetails?.sBio || '-'}
                                            </h4>
                                        </div>

                                        <div className='me-3 mb-3 col-lg-4 col-md-4 col-sm-12'>
                                            <h5>Average Rating</h5>
                                            <h4 className='font-w400'>
                                                {userdetails?.averageRating?.toFixed(
                                                    2
                                                ) || '-'}
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='me-3 mb-3 col-lg-12'>
                                <h5>Interest</h5>
                                <h4 className='font-w400'>
                                    <div className='categories-container'>
                                        {titlesArray?.map(
                                            (interestCategory) => (
                                                <div
                                                    className={`categories`}
                                                    key={interestCategory._id}
                                                >
                                                    <p className='categories-name'>
                                                        {interestCategory}
                                                    </p>

                                                    <div className='wrapper-categories'>
                                                        {userdetails?.aInterests
                                                            .filter((item) => {
                                                                return (
                                                                    item
                                                                        ?.iInterestCategory[0]
                                                                        ?.sTitle ===
                                                                    interestCategory
                                                                );
                                                            })
                                                            ?.map((item) => {
                                                                return (
                                                                    <div
                                                                        className='cat-item'
                                                                        key={
                                                                            item._id
                                                                        }
                                                                    >
                                                                        <input
                                                                            type='checkbox'
                                                                            className='c-pointer'
                                                                            name={
                                                                                item._id
                                                                            }
                                                                            checked={checkedIds.includes(
                                                                                item._id
                                                                            )}
                                                                        />

                                                                        <div className='item'>
                                                                            <img
                                                                                src={
                                                                                    item.sIconUrl ||
                                                                                    ''
                                                                                }
                                                                                alt=''
                                                                            />
                                                                            <span className='text-black'>
                                                                                {item?.sTitle ||
                                                                                    ''}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserDetails;
