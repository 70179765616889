import apiClient from '../client/api-client';

export const dialogaeList = async (data) => {
    const url = 'admin/dialogae/list';
    return apiClient({
        url,
        data,
        method: 'POST',
    });
};

export const addDialogaeData = async (data, timezone) => {
    const url = 'admin/dialogae/add';
    return apiClient({
        url,
        data,
        method: 'POST',
        headers: { timezone },
    });
};

export const deleteDialogaeData = async (id) => {
    const url = `admin/dialogae/${id}/delete`;
    return apiClient({
        url,
        method: 'DELETE',
    });
};

export const editDialogaeData = async (data, timezone) => {
    const url = `admin/dialogae/${data?.id}/edit`;

    return apiClient({
        url,
        data: data?.data,
        method: 'PATCH',
        headers: { timezone },
    });
};
