import React, { useEffect, useState } from 'react';
import RewardDatatable from './RewardDatatable';
import {
    addReward,
    deleteReward,
    editReward,
    rewardList,
    toggleReward,
} from '../../../api/adapters/rewards';
import { useMutation, useQuery } from '@tanstack/react-query';
import { notify } from '../../../utils/helpers/notification.helpers';
import RewardModal from './RewardModal';
import { handleApiError } from '../../../utils/helpers/common.helpers';
import EditRewardModal from './EditRewardModal';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setConnectedUser } from '../../../actions/connected-user';

const Reward = () => {
    const [showRewardModal, setShowRewardModal] = useState(false);
    const [showEditRewardModal, setShowEditRewardModal] = useState(false);

    const [reward, setAddReward] = useState('');
    const [errorMessage, setErrorMessage] = useState({});
    const [categoryName, setCategoryName] = useState({
        nMinutes: '',
        sDescription: '',
        sRewardPic: '',
        id: '',
    });

    const [rewardsError, setRewardsError] = useState(null);
    const [image, setImage] = useState(undefined);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const pageSize = 5;
    const [queryData, setQueryData] = useState({
        start: 0,
        length: pageSize,
        order: [{ dir: 'asc', column: 0 }],
        columns: [{ data: 'nMinutes' }],
        search: '',
    });

    const {
        data: viewRewardList,
        isLoading: isLoadingRewardList,
        refetch,
        error: queryError,
    } = useQuery({
        queryKey: ['viewRewardList', queryData],
        queryFn: () => rewardList(queryData).then((res) => res.data),
        retry: false,
    });

    useEffect(() => {
        setRewardsError(queryError);
    }, [queryError]);

    useEffect(() => {
        if (rewardsError) {
            notify('error', rewardsError.message);

            if (rewardsError.status === 401) {
                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
        }
        setRewardsError(null);
    }, [rewardsError]);

    const handleInput = (e) => {
        let value = e.target.value;

        setAddReward((preInquiryData) => ({
            ...preInquiryData,
            nMinutes: value,
        }));
    };

    const handleDescription = (e) => {
        let value = e.target.value;

        setAddReward((preInquiryData) => ({
            ...preInquiryData,
            sDescription: value,
        }));
    };

    const handleSearch = (e) => {
        let value = e.target.value;

        setQueryData((prevData) => ({
            ...prevData,
            start: 0,
            search: value,
        }));
    };

    const handleEditTitle = (e) => {
        let value = e.target.value;

        setCategoryName((preInquiryData) => ({
            ...preInquiryData,
            nMinutes: value,
        }));
    };

    const handleEditDescription = (e) => {
        let value = e.target.value;

        setCategoryName((preInquiryData) => ({
            ...preInquiryData,
            sDescription: value,
        }));
    };

    const { mutate: mutateAddReward, isLoading: isAddRewardLoad } = useMutation(
        {
            mutationFn: (data) => addReward(data),
            onSuccess: (data) => {
                notify('success', data.message);
                refetch();
                setShowRewardModal(false);
                setAddReward('');
            },

            onError: (error) => {
                if (error?.status === 401) {
                    notify('error', error.message);

                    navigate('/sign-in');
                    dispatch(
                        setConnectedUser({
                            sEmail: '',
                            sProfilePicUrl: '',
                            sUserName: '',
                        })
                    );
                }
                handleApiError(error, setErrorMessage);
            },
        }
    );

    const { mutate: mutateDeleteReward } = useMutation({
        mutationFn: (id) => deleteReward(id),
        onSuccess: (data) => {
            notify('success', data.message);
            refetch();
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const { mutate: mutateToggleButton } = useMutation({
        mutationFn: (id) => toggleReward(id),
        onSuccess: (data) => {
            notify('success', data?.message);
            refetch();
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const { mutate: mutateEditReward, isLoading: isLoadingReward } =
        useMutation({
            mutationFn: (data) => editReward(data),
            onSuccess: (data) => {
                notify('success', data.message);

                refetch();
                setShowEditRewardModal(false);
                setCategoryName('');
                setImage(undefined);
            },

            onError: (error) => {
                if (error?.status === 401) {
                    notify('error', error.message);

                    navigate('/sign-in');
                    dispatch(
                        setConnectedUser({
                            sEmail: '',
                            sProfilePicUrl: '',
                            sUserName: '',
                        })
                    );
                }
                handleApiError(error, setErrorMessage);
            },
        });

    return (
        <>
            <RewardDatatable
                rewardData={viewRewardList?.data}
                handleSearch={handleSearch}
                handleChange={handleInput}
                mutateDelete={mutateDeleteReward}
                mutateToggle={mutateToggleButton}
                setQueryData={setQueryData}
                queryData={queryData}
                viewRewardList={viewRewardList}
                pageSize={pageSize}
                setShowEditRewardModal={setShowEditRewardModal}
                setCategoryName={setCategoryName}
                show={showRewardModal}
                close={() => setShowRewardModal(false)}
                setShowRewardModal={setShowRewardModal}
                isLoading={isLoadingRewardList}
            />

            <RewardModal
                mutate={mutateAddReward}
                handleChange={handleInput}
                handleDescription={handleDescription}
                show={showRewardModal}
                close={() => setShowRewardModal(false)}
                reward={reward}
                setAddReward={setAddReward}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                isLoading={isAddRewardLoad}
            />

            <EditRewardModal
                show={showEditRewardModal}
                close={() => {
                    setShowEditRewardModal(false);
                }}
                setShowEditRewardModal={setShowEditRewardModal}
                handleEditTitle={handleEditTitle}
                handleEditDescription={handleEditDescription}
                itemData={categoryName}
                mutateEdit={mutateEditReward}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                isLoading={isLoadingReward}
                setImage={setImage}
                image={image}
            />
        </>
    );
};

export default Reward;
