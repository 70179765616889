import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import {
    getAllInterest,
    getResidencies,
    getUniversities,
    updateUserDetails,
    userDetails,
} from '../../../api/adapters/userManagement';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { Form } from 'react-bootstrap';
import { getCities } from '../../../api/adapters/universityManagement';
import { getCountries } from '../../../api/adapters/cityManagement';
import { notify } from '../../../utils/helpers/notification.helpers';
import Loader from '../../pages/Loader/Loader';
import profile from '../../../assets/images/default-profile.png';
import { useDispatch } from 'react-redux';
import { setConnectedUser } from '../../../actions/connected-user';
import { handleApiError } from '../../../utils/helpers/common.helpers';
import PageTitle from '../../layouts/PageTitle';
import {
    isValidName,
    isValidPhone,
} from '../../../utils/validators/common.validators';

const EditUserDetails = () => {
    let { id, status } = useParams();

    const {
        data: viewUserDetails,
        isLoading,
        refetch,
        error: queryError,
    } = useQuery({
        queryKey: ['viewUserDetails', id],
        queryFn: () => userDetails(id).then((res) => res.data),
        retry: false,
    });

    const [userEditError, setUserEditError] = useState(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [checkedIds, setCheckedIds] = useState([]);
    const [checkedIdsArray, setCheckedIdsArray] = useState([]);

    const { data: viewCountryList } = useQuery({
        queryKey: ['viewCountryList'],
        queryFn: () => getCountries().then((res) => res.data),
    });

    const [selectedValue, setSelectedValue] = useState('');

    useEffect(() => {
        setUserEditError(queryError);
    }, [queryError]);

    useEffect(() => {
        if (userEditError) {
            notify('error', userEditError.message);

            if (userEditError.status === 401) {
                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
        }
        setUserEditError(null);
    }, [userEditError]);

    const [selectedCity, setSelectedCity] = useState('');
    const [selectedUniversity, setSelectedUniversity] = useState('');

    const [selectedUniversityTitle, setSelectedUniversityTitle] = useState('');
    const [selectedResidenceTitle, setSelectedResidenceTitle] = useState('');
    const [selectedResidence, setSelectedResidence] = useState('');
    const [accountType, setAccountType] = useState('');

    const [disable, setdisable] = useState(false);
    const [disableUniversity, setdisableUniversity] = useState(false);
    const [cityTitle, setCityTitle] = useState('');
    const [selectedItems, setSelectedItems] = useState([]);
    const isSelectable = true;
    const [data, setData] = useState({
        sFirstName: '',
        sMiddleName: '',
        sLastName: '',
        sEmail: '',
        eAccountType: '',
        iUniversity: '',
        iResidency: '',
        iCity: '',
        iCountry: '',
        eGender: '',
        sBio: '-',
        aInterests: [],
        dDob: '',
        nRating: '',
    });
    let errorsObj = {
        accountType: '',
        country: '',
        city: '',
        firstName: '',
        middleName: '',
        lastname: '',
        gender: '',
        dob: '',
        residency: '',
        university: '',
        bio: '',
        interestItems: [],
    };
    const [errorMessage, setErrorMessage] = useState({});

    const [errors, setErrors] = useState(errorsObj);

    const { data: cities, error: cityError } = useQuery({
        queryKey: [
            'cities',
            selectedValue?.id || viewUserDetails?.iCountry?._id,
        ],
        queryFn: () =>
            getCities(selectedValue?.id || viewUserDetails?.iCountry?._id).then(
                (res) => res.data
            ),
        enabled: !!selectedValue?.id || !!viewUserDetails?.iCountry?._id,

        retry: false, // Disable retries on errors
        retryOnMount: false,
    });

    const { data: universities, error: errorUniversity } = useQuery({
        queryKey: [
            'universities',
            selectedCity?.id ||
                viewUserDetails?.iCity?._id ||
                data?.eAccountType ||
                accountType,
        ],
        queryFn: () =>
            getUniversities(
                selectedCity?.id || viewUserDetails?.iCity?._id
            ).then((res) => res.data),
        enabled:
            (!!selectedCity?.id || !!viewUserDetails?.iCity?._id) &&
            (data?.eAccountType === 'S' || accountType === 'S'),

        retry: false, // Disable retries on errors
        retryOnMount: false,
    });

    const { data: residencies, error: errorResidencies } = useQuery({
        queryKey: [
            'residencies',
            selectedCity ||
                viewUserDetails?.iCity?._id ||
                data?.eAccountType ||
                accountType,
        ],
        queryFn: () =>
            getResidencies(selectedCity || viewUserDetails?.iCity?._id).then(
                (res) => res.data
            ),
        enabled:
            !!selectedCity &&
            (data?.eAccountType === 'R' || accountType === 'R'),

        retry: false, // Disable retries on errors
        retryOnMount: false,
    });

    const { data: interestCategories } = useQuery({
        queryKey: ['interest-categories'],
        queryFn: () => getAllInterest().then((res) => res.data),
        placeholderData: [],
    });

    const { mutate: mutateEditUserProfile } = useMutation({
        mutationFn: (data) => updateUserDetails({ data, id }),
        onSuccess: (data) => {
            notify('success', data.message);
            setSelectedCity('');
            setSelectedUniversity('');
            setSelectedResidence('');
            setCityTitle('');
            setSelectedUniversityTitle('');
            setSelectedResidenceTitle('');

            refetch();
            navigate(-1);
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    useEffect(() => {
        setData((prevData) => ({
            ...prevData,
            iCity: '',
            iResidency: '',
            iUniversity: '',
        }));
        setCityTitle(
            selectedValue ? 'Select City' : viewUserDetails?.iCity?.sTitle
        );
        setSelectedCity(selectedValue ? '' : viewUserDetails?.iCity?._id);

        setSelectedUniversityTitle(
            selectedValue && data?.eAccountType === 'S'
                ? 'Select University'
                : viewUserDetails?.iUniversity?.sTitle
        );
        setSelectedResidenceTitle(
            selectedValue && data?.eAccountType === 'R'
                ? 'Select Residence'
                : viewUserDetails?.iResidency?.sTitle
        );
        setSelectedUniversity(
            selectedValue && data?.eAccountType === 'S'
                ? ''
                : viewUserDetails?.iUniversity?._id
        );
        setSelectedResidence(
            selectedValue && data?.eAccountType === 'R'
                ? ''
                : viewUserDetails?.iResidency?._id
        );
    }, [selectedValue]);

    useEffect(() => {
        setData((prevData) => ({
            ...prevData,
            iResidency: '',
            iUniversity: '',
        }));

        setSelectedUniversityTitle(
            selectedCity && data?.eAccountType === 'S'
                ? 'Select University'
                : viewUserDetails?.iUniversity?.sTitle
        );
        setSelectedResidenceTitle(
            selectedCity && data?.eAccountType === 'R'
                ? 'Select Residence'
                : viewUserDetails?.iResidency?.sTitle
        );

        setSelectedUniversity(
            selectedValue && data?.eAccountType === 'S'
                ? ''
                : viewUserDetails?.iUniversity?._id
        );
        setSelectedResidence(
            selectedValue && data?.eAccountType === 'R'
                ? ''
                : viewUserDetails?.iResidency?._id
        );
    }, [selectedCity]);

    useEffect(() => {
        setData((prevData) => ({
            ...prevData,
            iResidency: '',
            iUniversity: '',
        }));

        setSelectedUniversityTitle(
            accountType && data?.eAccountType === 'S'
                ? 'Select University'
                : viewUserDetails?.iUniversity?.sTitle
        );
        setSelectedResidenceTitle(
            accountType && data?.eAccountType === 'R'
                ? 'Select Residence'
                : viewUserDetails?.iResidency?.sTitle
        );

        setSelectedUniversity(
            accountType && data?.eAccountType === 'S'
                ? ''
                : viewUserDetails?.iUniversity?._id
        );
        setSelectedResidence(
            accountType && data?.eAccountType === 'R'
                ? ''
                : viewUserDetails?.iResidency?._id
        );
    }, [accountType]);

    useEffect(() => {
        if (cityError) {
            setSelectedValue('');
            setdisable(true);
            setdisableUniversity(true);
            notify('error', cityError?.message || 'Something went wrong');
        }
    }, [cityError]);

    useEffect(() => {
        if (data?.eAccountType === 'S')
            if (errorUniversity) {
                setSelectedCity('');
                setAccountType('');
                setSelectedUniversity('');
                setSelectedUniversityTitle('Select University');

                setdisableUniversity(true);
                notify(
                    'error',
                    errorUniversity?.message || 'Something went wrong'
                );
            }
        if (data?.eAccountType === 'R')
            if (errorResidencies) {
                setSelectedCity('');
                setAccountType('');
                setSelectedResidence('');
                setSelectedResidenceTitle('Select Residence');
                setdisableUniversity(true);
                notify(
                    'error',
                    errorResidencies?.message || 'Something went wrong'
                );
            }
    }, [errorUniversity, errorResidencies]);

    useEffect(() => {
        if (viewUserDetails) {
            const newCheckIds = viewUserDetails?.aInterests
                .map((item) => {
                    const { iInterestCategory } = item;
                    return iInterestCategory ? item._id : null;
                })
                .filter((id) => id !== null);
            setCheckedIds(newCheckIds);

            setCheckedIdsArray(Object.values(newCheckIds).map(String));
            setData({
                sFirstName: viewUserDetails?.oName?.sFirstName,
                sMiddleName: viewUserDetails?.oName?.sMiddleName || '-',
                sLastName: viewUserDetails?.oName?.sLastName,
                sEmail: viewUserDetails?.sEmail,
                eAccountType: viewUserDetails?.eAccountType,
                iUniversity: viewUserDetails?.iUniversity?._id,
                iResidency: viewUserDetails?.iResidency?._id,
                iCity: viewUserDetails?.iCity?._id,
                iCountry: viewUserDetails?.iCountry?._id,
                eGender: viewUserDetails?.eGender,
                sBio: viewUserDetails?.sBio,
                aInterests: Object.values(newCheckIds).map(String),
                dDob: moment(viewUserDetails?.dDob).format('YYYY-MM-DD'),
                nRating: viewUserDetails?.averageRating?.toFixed(2) || '-',
            });
            setSelectedResidence(viewUserDetails?.iResidency?._id || '');
            setSelectedUniversity(viewUserDetails?.iUniversity?._id || '');
            setSelectedUniversityTitle(
                viewUserDetails?.iUniversity?.sTitle || ''
            );
            setSelectedResidenceTitle(
                viewUserDetails?.iResidency?.sTitle || ''
            );
            setCityTitle(viewUserDetails?.iCity?.sTitle || '');
            setSelectedCity(viewUserDetails?.iCity?._id || '');
        }
    }, [viewUserDetails]);

    useEffect(() => {
        setData((prevData) => ({ ...prevData, aInterests: checkedIdsArray }));
    }, [checkedIdsArray]);

    useEffect(() => {
        if (residencies) {
            const selectResidence = residencies.find((res) => {
                return res._id === selectedResidence;
            });

            setSelectedResidenceTitle(
                selectResidence?.sTitle || residencies?.[0]?.sTitle || ''
            );
            setSelectedResidence(
                selectResidence?._id || residencies?.[0]?._id || ''
            );

            setData((prevData) => ({
                ...prevData,
                iResidency: selectResidence?._id || residencies?.[0]?._id || '',
            }));
        }
    }, [residencies]);

    useEffect(() => {
        if (universities) {
            const selectUniversity = universities.find((uni) => {
                return uni._id === selectedUniversity;
            });

            setSelectedUniversityTitle(
                selectUniversity?.sTitle || universities?.[0]?.sTitle || ''
            );
            setSelectedUniversity(
                selectUniversity?._id || universities?.[0]?._id || ''
            );

            setData((prevData) => ({
                ...prevData,
                iUniversity:
                    selectUniversity?._id || universities?.[0]?._id || '',
            }));
        }
    }, [universities]);

    // useEffect(() => {
    //     if (cities) {
    //         const selectCity = cities.find((city) => {
    //             return city._id === selectedCity;
    //         });

    //         setCityTitle(selectCity?.sTitle || cities?.[0]?.sTitle || '');
    //         setSelectedCity(selectCity?._id || cities?.[0]?._id || '');

    //         setData((prevData) => ({
    //             ...prevData,
    //             iCity: selectCity?._id || cities?.[0]?._id || '',
    //         }));
    //     }
    // }, [cities]);

    const handleSelectChange = (e) => {
        let value = e.target.value;

        setSelectedValue((preInquiryData) => ({
            ...preInquiryData,
            id: value,
        }));
        setData((prevData) => ({ ...prevData, iCountry: value }));
        setSelectedCity('');

        if (accountType === 'R' || data?.eAccountType === 'R') {
            setSelectedUniversity('');
            setSelectedResidence('Select Residence');
        }
        if (accountType === 'S' || data?.eAccountType === 'S') {
            setSelectedResidence('');
            setSelectedUniversity('Select University');
        }
        setdisable(false);
        setdisableUniversity(false);
    };

    const handleInput = (e) => {
        const { name, value } = e.target;
        let formattedValue = value;

        if (name === 'dDob') {
            // Format the date to 'YYYY-MM-DD'
            formattedValue = moment(value).format('YYYY-MM-DD');

            setData((preUserData) => ({
                ...preUserData,
                [name]: formattedValue,
            }));
        } else
            setData((preUserData) => ({
                ...preUserData,
                [e.target.name]: e.target.value,
            }));
    };

    const handleSelectUniversity = (e) => {
        let value = e.target.value;

        setSelectedUniversity(value);
        setData((prevData) => ({
            ...prevData,
            iUniversity: value,
            iResidency: '',
        }));
    };

    const handleSelectResidence = (e) => {
        let value = e.target.value;
        setSelectedResidence(value);

        setData((prevData) => ({
            ...prevData,
            iResidency: value,
            iUniversity: '',
        }));
    };

    const handleType = (e) => {
        let value = e.target.value;

        setAccountType(value);
        setData((prevData) => ({ ...prevData, eAccountType: value }));

        // if (value === 'R') {
        //     setSelectedUniversity('');
        //     setSelectedUniversityTitle('Select University');
        // }

        // if (value === 'S') {
        //     setSelectedResidence('');
        //     setSelectedResidenceTitle('Select Residence');
        // }

        setdisableUniversity(false);

        if (data?.eAccountType === 'S') {
            const selectedUniversity = universities?.find(
                (uni) => uni.iCity === selectedCity
            );

            setSelectedUniversity(selectedUniversity?._id || '');
            setData((prevData) => ({
                ...prevData,
                iUniversity: selectedUniversity?._id || '',
            }));
        } else if (data?.eAccountType === 'R') {
            const selectedResidence = residencies?.find((res) => {
                return res.iCity === selectedCity;
            });

            setSelectedResidence(selectedResidence?._id || '');
            setData((prevData) => ({
                ...prevData,
                iResidency: selectedResidence?._id || '',
            }));
        }
    };

    const uniqueOptionValues = new Set();

    // Add the default option value to the set
    if (viewUserDetails?.iCountry?.sTitle) {
        uniqueOptionValues.add(viewUserDetails.iCountry._id);
    }

    // Add the values from the countrylist to the set
    viewCountryList?.forEach((data) => {
        uniqueOptionValues.add(data._id);
    });

    const handleInterestChange = (itemId, checked) => {
        // Create a copy of the selected items array
        const updatedSelectedItems = [...checkedIdsArray];

        if (checked) {
            // Add the item to the selected items array
            updatedSelectedItems.push(itemId);
            setCheckedIdsArray(updatedSelectedItems);
        } else {
            // Remove the item from the selected items array
            const index = updatedSelectedItems.indexOf(itemId);
            if (index !== -1) {
                updatedSelectedItems.splice(index, 1);
            }
            setCheckedIdsArray(updatedSelectedItems);
        }

        setSelectedItems(updatedSelectedItems);
    };

    const handleSaveClick = async (e) => {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };

        if (data?.sFirstName === '') {
            errorObj.firstName = 'First Name is Required';
            error = true;
        } else if (!isValidName(data?.sFirstName)) {
            errorObj.firstName = 'Invalid First Name';
            error = true;
        }
        if (data?.sMiddleName === '') {
            errorObj.middleName = 'Surname 1 is Required';
            error = true;
        } else if (!isValidName(data?.sMiddleName)) {
            errorObj.middleName = 'Invalid Surname 1';
            error = true;
        }
        if (data?.sLastName && !isValidName(data?.sLastName)) {
            errorObj.lastname = 'Invalid Surname 2';
            error = true;
        }

        if (data?.eGender === '') {
            errorObj.gender = 'Gender is Required';
            error = true;
        } else if (
            data?.eGender !== 'M' &&
            data?.eGender !== 'F' &&
            data?.eGender !== 'O'
        ) {
            errorObj.gender = 'Invalid gender';
            error = true;
        }

        const today = moment();

        if (!moment(data?.dDob, 'YYYY-MM-DD', true).isValid()) {
            errorObj.dob = 'Invalid DOB format';
            error = true;
        } else if (moment(data?.dDob, 'YYYY-MM-DD').isAfter(today)) {
            errorObj.dob = 'DOB cannot be in the future';
            error = true;
        } else if (today.diff(data?.dDob, 'years') < 17) {
            errorObj.dob = 'You must be at least 17 years old';
            error = true;
        }

        if (data?.eAccountType === '') {
            errorObj.accountType = 'Account type is Required';
            error = true;
        } else if (data?.eAccountType !== 'R' && data?.eAccountType !== 'S') {
            errorObj.accountType = 'Invalid account type';
            error = true;
        }

        if (data?.eAccountType === 'R') {
            if (data?.iResidency === '') {
                errorObj.residency = 'Please select Residence';
                error = true;
            }
        } else {
            if (data?.iUniversity === '') {
                errorObj.university = 'Please select University';
                error = true;
            }
        }

        if (data?.iCountry === '') {
            errorObj.country = 'Please select country';
            error = true;
        }
        if (data?.iCity === '') {
            errorObj.city = 'Please select city';
            error = true;
        }

        if (data?.aInterests.length < 5) {
            errorObj.interestItems = 'Please select minimum 5 interests';
            error = true;
        } else if (data?.aInterests.length > 12) {
            errorObj.interestItems = 'Please select maximum 12 interests';
            error = true;
        }

        if (data?.sBio === '' || data?.sBio === '-') {
            data.sBio = '-';
        }

        setErrors(errorObj);
        if (error) {
            return;
        }
        mutateEditUserProfile(data);
    };

    const handleCancelClick = () => {
        navigate(-1);
    };

    const handleSelectCity = (e) => {
        let value = e.target.value;

        // setSelectedCity((preInquiryData) => ({
        //     ...preInquiryData,
        //      value,
        // }));
        setSelectedCity(value);

        setData((prevData) => ({ ...prevData, iCity: value }));
        setdisableUniversity(false);

        if (data?.eAccountType === 'S') {
            const selectedUniversity = universities?.find(
                (uni) => uni.iCity === value
            );

            setSelectedUniversity(selectedUniversity?._id || '');
            setData((prevData) => ({
                ...prevData,
                iUniversity: selectedUniversity?._id || '',
            }));
        } else if (data?.eAccountType === 'R') {
            const selectedResidence = residencies?.find((res) => {
                return res.iCity === value;
            });

            setSelectedResidence(selectedResidence?._id || '');
            setData((prevData) => ({
                ...prevData,
                iResidency: selectedResidence?._id || '',
            }));
        }
    };

    return (
        <>
            <PageTitle
                activeMenu={`${
                    status === 'pending'
                        ? 'Pending'
                        : status === 'accepted'
                        ? 'Accepted'
                        : 'Rejected'
                } Request`}
                motherMenu='User Management'
                pageContent={`${id}`}
            />
            {isLoading && !viewUserDetails ? (
                <div className='col-12'>
                    <div className='card'>
                        <div
                            className='card-body d-flex align-items-center justify-content-center'
                            style={{
                                position: 'fixed',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            }}
                        >
                            {' '}
                            <Loader />
                        </div>{' '}
                    </div>{' '}
                </div>
            ) : (
                <div className='col-xl-12'>
                    <div className='card'>
                        <div className='card-body pb-3'>
                            <div className='d-flex float-end'>
                                <button
                                    type='button'
                                    className='btn btn-primary mx-2'
                                    style={{ width: '100px' }}
                                    onClick={handleSaveClick}
                                >
                                    Save
                                </button>

                                <button
                                    type='button'
                                    className='btn btn-primary mx-2'
                                    style={{ width: '100px' }}
                                    onClick={handleCancelClick}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                        <div className='card-body pb-3 transaction-details'>
                            <div className='row'>
                                <div className='col-lg-2'>
                                    <div className='row'>
                                        {viewUserDetails?.sProfilePicUrl ? (
                                            <img
                                                src={
                                                    viewUserDetails?.sProfilePicUrl
                                                }
                                                className='rounded'
                                                alt=''
                                            />
                                        ) : (
                                            <img
                                                src={profile}
                                                className='rounded'
                                                alt=''
                                            />
                                        )}
                                    </div>
                                </div>

                                <div className='col-lg-10'>
                                    <div className='row'>
                                        <div className='col d-flex flex-wrap justify-content-between align-items-start'>
                                            <div className='me-3 mb-3 col-lg-3 col-md-4 col-sm-6'>
                                                <h5>First Name</h5>
                                                <h4 className='font-w400'>
                                                    <Form>
                                                        <Form.Group>
                                                            <Form.Control
                                                                type='text'
                                                                value={
                                                                    data?.sFirstName
                                                                }
                                                                name='sFirstName'
                                                                onChange={
                                                                    handleInput
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Form>
                                                </h4>
                                                {errors.firstName && (
                                                    <div className='text-danger fs-12'>
                                                        {errors.firstName}
                                                    </div>
                                                )}
                                            </div>

                                            <div className='me-3 mb-3 col-lg-3 col-md-4 col-sm-6'>
                                                <h5>Surname 1</h5>
                                                <h4 className='font-w400'>
                                                    <Form>
                                                        <Form.Group>
                                                            <Form.Control
                                                                type='text'
                                                                name='sMiddleName'
                                                                value={
                                                                    data?.sMiddleName
                                                                }
                                                                onChange={
                                                                    handleInput
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Form>
                                                </h4>
                                                {errors.middleName && (
                                                    <div className='text-danger fs-12'>
                                                        {errors.middleName}
                                                    </div>
                                                )}
                                            </div>

                                            <div className='me-3 mb-3 col-lg-3 col-md-4 col-sm-6'>
                                                <h5>Surname 2</h5>
                                                <h4 className='font-w400'>
                                                    <Form>
                                                        <Form.Group>
                                                            <Form.Control
                                                                type='text'
                                                                name='sLastName'
                                                                value={
                                                                    data?.sLastName
                                                                }
                                                                onChange={
                                                                    handleInput
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Form>
                                                </h4>
                                                {errors.lastname && (
                                                    <div className='text-danger fs-12'>
                                                        {errors.lastname}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col d-flex flex-wrap justify-content-between align-items-center'>
                                            <div className='me-3 mb-3 col-lg-3 col-md-4 col-sm-6'>
                                                <h5>Type</h5>
                                                <h4 className='font-w400'>
                                                    <Form.Select
                                                        aria-label='Default select example'
                                                        name='eAccountType'
                                                        className='c-pointer'
                                                        value={
                                                            data.eAccountType
                                                        }
                                                        onChange={(e) =>
                                                            handleType(e)
                                                        }
                                                    >
                                                        <>
                                                            <option value='S'>
                                                                Student
                                                            </option>
                                                            <option value='R'>
                                                                Residence
                                                            </option>
                                                        </>
                                                    </Form.Select>
                                                </h4>
                                                {errors.accountType && (
                                                    <div className='text-danger fs-12'>
                                                        {errors.accountType}
                                                    </div>
                                                )}
                                            </div>
                                            <div className='me-3 mb-3 col-lg-3 col-md-4 col-sm-6'>
                                                <h5>
                                                    {data?.eAccountType === 'S'
                                                        ? 'University Name'
                                                        : 'Residence Name'}
                                                </h5>
                                                <h4 className='font-w400'>
                                                    <Form.Select
                                                        aria-label='Default select example'
                                                        className='c-pointer'
                                                        onChange={(e) =>
                                                            data?.eAccountType ===
                                                            'S'
                                                                ? handleSelectUniversity(
                                                                      e,
                                                                      data?.sTitle
                                                                  )
                                                                : handleSelectResidence(
                                                                      e,
                                                                      data?.sTitle
                                                                  )
                                                        }
                                                        disabled={
                                                            disableUniversity
                                                        }
                                                        value={
                                                            data?.eAccountType ===
                                                            'S'
                                                                ? selectedUniversity
                                                                : selectedResidence
                                                        }
                                                    >
                                                        {data?.eAccountType ===
                                                        'S'
                                                            ? universities?.map(
                                                                  (data) => (
                                                                      <option
                                                                          key={
                                                                              data?._id
                                                                          }
                                                                          value={
                                                                              data?._id
                                                                          }
                                                                      >
                                                                          {
                                                                              data?.sTitle
                                                                          }
                                                                      </option>
                                                                  )
                                                              )
                                                            : residencies?.map(
                                                                  (data) => (
                                                                      <option
                                                                          key={
                                                                              data?._id
                                                                          }
                                                                          value={
                                                                              //   selectedResidence ||
                                                                              data?._id
                                                                          }
                                                                      >
                                                                          {
                                                                              data?.sTitle
                                                                          }
                                                                      </option>
                                                                  )
                                                              )}
                                                    </Form.Select>
                                                </h4>
                                                {errors.university && (
                                                    <div className='text-danger fs-12'>
                                                        {errors.university}
                                                    </div>
                                                )}
                                                {errors.residency && (
                                                    <div className='text-danger fs-12'>
                                                        {errors.residency}
                                                    </div>
                                                )}
                                            </div>
                                            <div className='me-3 mb-3 col-lg-3 col-md-4 col-sm-6'>
                                                <h5>Gender</h5>
                                                <h4 className='font-w400'>
                                                    <Form.Select
                                                        aria-label='Default select example'
                                                        name='eGender'
                                                        className='c-pointer'
                                                        value={data.eGender}
                                                        onChange={handleInput}
                                                    >
                                                        <option value='M'>
                                                            Male
                                                        </option>
                                                        <option value='F'>
                                                            Female
                                                        </option>
                                                        <option value='O'>
                                                            Others
                                                        </option>
                                                    </Form.Select>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col d-flex flex-wrap justify-content-between align-items-center'>
                                            <div className='me-3 mb-3 col-lg-3 col-md-4 col-sm-6'>
                                                <h5>Country</h5>
                                                <h4 className='font-w400'>
                                                    <Form.Select
                                                        aria-label='Default select example'
                                                        className='c-pointer'
                                                        onChange={
                                                            handleSelectChange
                                                        }
                                                    >
                                                        {[
                                                            ...uniqueOptionValues,
                                                        ].map((value) => (
                                                            <option
                                                                key={value}
                                                                value={value}
                                                            >
                                                                {value ===
                                                                viewUserDetails
                                                                    ?.iCountry
                                                                    ?.sTitle
                                                                    ? viewUserDetails
                                                                          ?.iCountry
                                                                          ?.sTitle
                                                                    : viewCountryList?.find(
                                                                          (
                                                                              data
                                                                          ) =>
                                                                              data._id ===
                                                                              value
                                                                      )?.sTitle}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                </h4>
                                            </div>
                                            <div className='me-3 mb-3 col-lg-3 col-md-4 col-sm-6'>
                                                <h5>City</h5>
                                                <h4 className='font-w400'>
                                                    <Form.Select
                                                        aria-label='Default select example'
                                                        className='c-pointer'
                                                        onChange={
                                                            handleSelectCity
                                                        }
                                                        disabled={disable}
                                                        // value={cityTitle}
                                                        // defaultValue={cityTitle}
                                                        defaultChecked={
                                                            cityTitle
                                                        }
                                                    >
                                                        <option
                                                            // key={selectedCity}
                                                            value={selectedCity}
                                                        >
                                                            {cityTitle}
                                                        </option>
                                                        {cities
                                                            ?.filter(
                                                                (city) =>
                                                                    city.sTitle !==
                                                                    cityTitle
                                                            )
                                                            ?.map((data) => (
                                                                <>
                                                                    <option
                                                                        key={
                                                                            data?._id
                                                                        }
                                                                        value={
                                                                            data?._id
                                                                        }
                                                                    >
                                                                        {
                                                                            data?.sTitle
                                                                        }
                                                                    </option>
                                                                </>
                                                            ))}
                                                    </Form.Select>
                                                </h4>
                                                {errors.city && (
                                                    <div className='text-danger fs-12'>
                                                        {errors.city}
                                                    </div>
                                                )}
                                            </div>
                                            <div className='me-3 mb-3 col-lg-3 col-md-4 col-sm-6'>
                                                <h5>Date of Birth</h5>
                                                <h4 className='font-w400'>
                                                    <input
                                                        type='date'
                                                        name='dDob'
                                                        className='form-control'
                                                        value={
                                                            data?.dDob
                                                                ? moment(
                                                                      data.dDob
                                                                  ).format(
                                                                      'YYYY-MM-DD'
                                                                  )
                                                                : ''
                                                        }
                                                        onChange={handleInput}
                                                    />
                                                </h4>
                                                {errors.dob && (
                                                    <div className='text-danger fs-12'>
                                                        {errors.dob}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='d-flex flex-wrap justify-content-between align-items-center'>
                                            <div className='me-3 mb-3 col-lg-3 col-md-4 col-sm-6'>
                                                <h5>Email Id</h5>
                                                <h4 className='font-w400'>
                                                    <Form>
                                                        <Form.Group>
                                                            <Form.Control
                                                                type='text'
                                                                value={
                                                                    data?.sEmail
                                                                }
                                                                name='sEmail'
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </Form>
                                                </h4>
                                            </div>
                                            <div className='me-3 mb-3 col-lg-3 col-md-4 col-sm-6'>
                                                <h5>Bio</h5>
                                                <h4 className='font-w400'>
                                                    <Form.Group className='mb-3'>
                                                        <Form.Control
                                                            as='textarea'
                                                            value={
                                                                data?.sBio ||
                                                                '-'
                                                            }
                                                            rows={5}
                                                            name='sBio'
                                                            onChange={
                                                                handleInput
                                                            }
                                                        />
                                                    </Form.Group>
                                                </h4>
                                            </div>
                                            <div className='me-3 mb-3 col-lg-3 col-md-4 col-sm-6'>
                                                <h5>Average Rating</h5>
                                                <h4 className='font-w400'>
                                                    <Form>
                                                        <Form.Group>
                                                            <Form.Control
                                                                type='text'
                                                                value={
                                                                    data?.nRating
                                                                }
                                                                name='nRating'
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </Form>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='me-3 mb-3 col-lg-12'>
                                    <h5>Interest</h5>
                                    <h4 className='font-w400'>
                                        <div className='categories-container'>
                                            {interestCategories?.map(
                                                (interestCategory) => (
                                                    <div
                                                        className={`categories${
                                                            !isSelectable
                                                                ? 'not-selectable'
                                                                : ''
                                                        }`}
                                                        key={
                                                            interestCategory.sTitle
                                                        }
                                                    >
                                                        <p className='categories-name'>
                                                            {
                                                                interestCategory.sTitle
                                                            }
                                                        </p>

                                                        <div className='wrapper-categories'>
                                                            {interestCategory.aInterestItems?.map(
                                                                (item) => {
                                                                    return (
                                                                        <div
                                                                            className='cat-item'
                                                                            key={
                                                                                item._id
                                                                            }
                                                                        >
                                                                            {isSelectable && (
                                                                                <input
                                                                                    type='checkbox'
                                                                                    className='c-pointer'
                                                                                    name={
                                                                                        item._id
                                                                                    }
                                                                                    onChange={(
                                                                                        e
                                                                                    ) =>
                                                                                        handleInterestChange(
                                                                                            item._id,
                                                                                            e
                                                                                                .target
                                                                                                .checked
                                                                                        )
                                                                                    }
                                                                                    checked={checkedIdsArray.includes(
                                                                                        item._id
                                                                                    )}
                                                                                />
                                                                            )}

                                                                            <div className='item'>
                                                                                <img
                                                                                    src={
                                                                                        item.sIconUrl ||
                                                                                        ''
                                                                                    }
                                                                                    alt=''
                                                                                />
                                                                                <span className='text-black'>
                                                                                    {item.sTitle ||
                                                                                        ''}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </h4>
                                    {errors.interestItems && (
                                        <div className='text-danger fs-12'>
                                            {errors.interestItems}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default EditUserDetails;
