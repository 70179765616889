import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useMutation } from '@tanstack/react-query';
import { logout } from '../../../api/adapters/auth';
import { notify } from '../../../utils/helpers/notification.helpers';
import { clearCookies } from '../../../utils/helpers/cookies.helpers';
import { useDispatch } from 'react-redux';
import { setConnectedUser } from '../../../actions/connected-user';
import { handleApiError } from '../../../utils/helpers/common.helpers';

function LogoutPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [errorMessage, setErrorMessage] = useState({});

    const { mutate: mutateLogout } = useMutation({
        mutationFn: () => logout(),
        onSuccess: (data) => {
            notify('success', data.message);
            clearCookies();
        },
        onError: (error) => handleApiError(error, setErrorMessage),

        onSettled: () => {
            dispatch(
                setConnectedUser({
                    sEmail: '',
                    sProfilePicUrl: '',
                    sUserName: '',
                })
            );
            navigate('/sign-in');
        },
    });

    async function logoutHandler(e) {
        e.preventDefault();
        mutateLogout();
    }

    return (
        <>
            <Link className='dropdown-item ai-icon' onClick={logoutHandler}>
                <svg
                    id='icon-logout'
                    xmlns='http://www.w3.org/2000/svg'
                    className='text-danger me-1'
                    width={18}
                    height={18}
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth={2}
                    strokeLinecap='round'
                    strokeLinejoin='round'
                >
                    <path d='M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4' />
                    <polyline points='16 17 21 12 16 7' />
                    <line x1={21} y1={12} x2={9} y2={12} />
                </svg>
                <span className='ms-2'>Logout </span>
            </Link>
        </>
    );
}

export default LogoutPage;
