/// Style
import './assets/vendor/bootstrap-select/dist/css/bootstrap-select.min.css';
import './assets/css/style.css';
import RoutesFile from './Routes/RoutesFile';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
// import { UserProvider } from './context/UserContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

function App() {
    return (
        <>
            {/* <UserProvider> */}
            <QueryClientProvider client={queryClient}>
                <RoutesFile />
                <ToastContainer autoClose={2000} />
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
            {/* </UserProvider> */}
        </>
    );
}
export default App;
