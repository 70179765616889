import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
    addInterestCategory,
    deleteInterestCategory,
    editInterestCategory,
    interestCategory,
    toogleCategory,
} from '../../../api/adapters/interestCategory';
import { notify } from '../../../utils/helpers/notification.helpers';
import { handleApiError } from '../../../utils/helpers/common.helpers';
import PrefereneDatatable from './PreferenceDatatable';
import EditModal from '../../pages/AddModal/EditModal';
import AddModal from '../../pages/AddModal/AddModal';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setConnectedUser } from '../../../actions/connected-user';
import PageTitle from '../../layouts/PageTitle';

const Preference = () => {
    const pageSize = 5;

    const [showLogin, setShowLogin] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [addCategory, setAddCategory] = useState('');
    const [categoryName, setCategoryName] = useState({ sTitle: '', id: '' });
    const [errorMessage, setErrorMessage] = useState({});

    const [preferenceError, setPreferenceError] = useState(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [queryData, setQueryData] = useState({
        start: 0,
        length: pageSize,
        order: [{ dir: 'asc', column: 0 }],
        columns: [{ data: 'sTitle' }],
    });

    const {
        data: viewInterestCategory,
        isLoading: isLoadingInterestCategory,
        refetch,
        error: queryError,
    } = useQuery({
        queryKey: ['viewInterestCategory', queryData],
        queryFn: () => interestCategory(queryData).then((res) => res.data),
        retry: false,
    });

    useEffect(() => {
        setPreferenceError(queryError);
    }, [queryError]);

    useEffect(() => {
        if (preferenceError) {
            notify('error', preferenceError.message);

            if (preferenceError.status === 401) {
                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
        }
        setPreferenceError(null);
    }, [preferenceError]);

    const handleInput = (e) => {
        let value = e.target.value;

        setAddCategory((preInquiryData) => ({
            ...preInquiryData,
            sTitle: value,
        }));
    };

    const handleSearch = (e) => {
        let value = e.target.value;

        setQueryData((prevData) => ({
            ...prevData,
            start: 0,
            search: value,
        }));
    };

    const { mutate: mutateAddInterestCategory, isLoading: isMutating } =
        useMutation({
            mutationFn: (data) => addInterestCategory(data),
            onSuccess: (data) => {
                notify('success', data.message);
                refetch();
                setShowLogin(false);
                setAddCategory('');
            },

            onError: (error) => {
                if (error?.status === 401) {
                    notify('error', error.message);

                    navigate('/sign-in');
                    dispatch(
                        setConnectedUser({
                            sEmail: '',
                            sProfilePicUrl: '',
                            sUserName: '',
                        })
                    );
                }
                handleApiError(error, setErrorMessage);
            },
        });

    const { mutate: mutateDeleteInterest } = useMutation({
        mutationFn: (id) => deleteInterestCategory(id),
        onSuccess: (data) => {
            notify('success', data.message);
            refetch();
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const { mutate: mutateToggleButton } = useMutation({
        mutationFn: (id) => toogleCategory(id),
        onSuccess: (data) => {
            notify('success', data?.message);
            refetch();
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const { mutate: mutateEditInterestCategory, isLoading } = useMutation({
        mutationFn: (id, data) => editInterestCategory(id, data),
        onSuccess: (data) => {
            notify('success', data.message);

            refetch();
            setShowEditModal(false);
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const handleInputCategory = (e) => {
        let value = e.target.value;

        setCategoryName((prevData) => ({
            ...prevData,
            sTitle: value,
        }));
    };

    const handleClose = async (e) => {
        setErrorMessage('');
        setAddCategory('');
        setShowLogin(false);
    };

    return (
        <>
            <PageTitle activeMenu='Category' motherMenu='Preference' />
            <div className='row'>
                <div className='col-lg-12'>
                    <div className='px-3 pt-2 pb-0'>
                        <PrefereneDatatable
                            categoryData={viewInterestCategory?.data}
                            handleInput={handleSearch}
                            handleChange={handleInput}
                            mutateDelete={mutateDeleteInterest}
                            mutateToggle={mutateToggleButton}
                            setQueryData={setQueryData}
                            queryData={queryData}
                            viewInterestCategory={viewInterestCategory}
                            pageSize={pageSize}
                            setShowEditModal={setShowEditModal}
                            setCategoryName={setCategoryName}
                            show={showLogin}
                            close={() => setShowLogin(false)}
                            setShowLogin={setShowLogin}
                            isLoading={isLoadingInterestCategory}
                        />

                        <EditModal
                            showEdit={showEditModal}
                            close={() => {
                                setShowEditModal(false);
                            }}
                            value={categoryName}
                            title={'Edit Preference Category'}
                            label='Enter preference category'
                            categoryName={categoryName}
                            handleInputCategory={handleInputCategory}
                            mutateEdit={mutateEditInterestCategory}
                            setErrorMessage={setErrorMessage}
                            backendErrorMessage={errorMessage}
                            isLoading={isLoading}
                        />
                        <AddModal
                            title={'Add Preference Category'}
                            label={'Preference Category'}
                            mutate={mutateAddInterestCategory}
                            value={addCategory}
                            placeholder='Enter preference category'
                            show={showLogin}
                            handleChange={handleInput}
                            setErrorMessage={setErrorMessage}
                            backendErrorMessage={errorMessage}
                            isMutating={isMutating}
                            handleClose={handleClose}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Preference;
