import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
    addCity,
    cityList,
    deleteCity,
    editCity,
    getCountries,
    toggleCityStatus,
} from '../../../api/adapters/cityManagement';
import { notify } from '../../../utils/helpers/notification.helpers';
import { handleApiError } from '../../../utils/helpers/common.helpers';
import EditModal from '../../pages/AddModal/EditModal';
import AddModal from '../../pages/AddModal/AddModal';
import CityDatatable from './CityDatatable';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setConnectedUser } from '../../../actions/connected-user';

const City = () => {
    const [showLogin, setShowLogin] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [addCityName, setAddCity] = useState('');
    const [categoryName, setCategoryName] = useState({ sTitle: '', id: '' });
    const [selectedValue, setSelectedValue] = useState('');

    const [citiesError, setCitiesError] = useState(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const pageSize = 5;
    const [queryData, setQueryData] = useState({
        start: 0,
        length: pageSize,
        order: [{ dir: 'asc', column: 0 }],
        columns: [{ data: 'sTitle' }],
        search: '',
    });

    const [errorMessage, setErrorMessage] = useState({});

    const {
        data: viewCityList,
        isLoading: isLoadingCityList,
        refetch,
        error: queryError,
    } = useQuery({
        queryKey: ['viewCityList', JSON.stringify(queryData)],
        queryFn: () => cityList(queryData).then((res) => res.data),
        retry: false,
    });

    useEffect(() => {
        setCitiesError(queryError);
    }, [queryError]);

    useEffect(() => {
        if (citiesError) {
            notify('error', citiesError.message);

            if (citiesError.status === 401) {
                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
        }
        setCitiesError(null);
    }, [citiesError]);

    const { data: viewCountryList } = useQuery({
        queryKey: ['viewCountryList'],
        queryFn: () => getCountries().then((res) => res.data),
    });

    const handleSelectChange = (e) => {
        let value = e.target.value;
        setSelectedValue((preInquiryData) => ({
            ...preInquiryData,
            id: value,
        }));
    };

    const handleInput = (e) => {
        let value = e.target.value;

        setAddCity((preInquiryData) => ({
            ...preInquiryData,
            sTitle: value,
        }));
    };

    const handleSearch = (e) => {
        let value = e.target.value;

        setQueryData((prevData) => ({
            ...prevData,
            start: 0,
            search: value,
        }));
    };

    const { mutate: mutateAddCity, isLoading: isMutating } = useMutation({
        mutationFn: (data) => addCity(data),
        onSuccess: (data) => {
            notify('success', data.message);
            refetch();
            setShowLogin(false);
            setAddCity('');
            setSelectedValue('');
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const { mutate: mutateDeleteCity } = useMutation({
        mutationFn: (id) => deleteCity(id),
        onSuccess: (data) => {
            notify('success', data.message);
            refetch();
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const { mutate: mutateToggleButton } = useMutation({
        mutationFn: (id) => toggleCityStatus(id),
        onSuccess: (data) => {
            notify('success', data?.message);
            refetch();
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const { mutate: mutateEditCity, isLoading } = useMutation({
        mutationFn: (data) => editCity(data),
        onSuccess: (data) => {
            notify('success', data.message);

            refetch();
            setShowEditModal(false);
        },
        onError: (error) => {
            if (error?.status === 401) {
                notify('error', error.message);

                navigate('/sign-in');
                dispatch(
                    setConnectedUser({
                        sEmail: '',
                        sProfilePicUrl: '',
                        sUserName: '',
                    })
                );
            }
            handleApiError(error, setErrorMessage);
        },
    });

    const handleInputCategory = (e) => {
        let value = e.target.value;

        setCategoryName((prevData) => ({
            ...prevData,
            sTitle: value,
        }));
    };

    const handleClose = async (e) => {
        setErrorMessage('');
        setAddCity('');
        setSelectedValue('');
        setShowLogin(false);
    };

    return (
        <div className='col-12'>
            <CityDatatable
                categoryData={viewCityList?.data}
                handleInput={handleSearch}
                handleChange={handleInput}
                mutateDelete={mutateDeleteCity}
                mutateToggle={mutateToggleButton}
                setQueryData={setQueryData}
                queryData={queryData}
                viewCityList={viewCityList}
                pageSize={pageSize}
                setShowEditModal={setShowEditModal}
                setCategoryName={setCategoryName}
                show={showLogin}
                close={() => setShowLogin(false)}
                setShowLogin={setShowLogin}
                isLoading={isLoadingCityList}
            />

            <EditModal
                showEdit={showEditModal}
                close={() => {
                    setShowEditModal(false);
                }}
                title='Edit City'
                label='Enter City name'
                categoryName={categoryName}
                handleInputCategory={handleInputCategory}
                mutateEdit={mutateEditCity}
                setErrorMessage={setErrorMessage}
                backendErrorMessage={errorMessage}
                isLoading={isLoading}
            />
            <AddModal
                title={'Add City'}
                label={'City Name'}
                mutate={mutateAddCity}
                value={addCityName}
                placeholder='Enter City'
                show={showLogin}
                handleChange={handleInput}
                countrylist={viewCountryList}
                handleSelectChange={handleSelectChange}
                selectedCountry={selectedValue}
                addDropdownlabel='Country Name'
                addDropdownPlaceholder='Select Country Name'
                selectedValue={selectedValue}
                setErrorMessage={setErrorMessage}
                backendErrorMessage={errorMessage}
                isMutating={isMutating}
                handleClose={handleClose}
            />
        </div>
    );
};

export default City;
